import React from 'react'
import { Link } from "react-router-dom";
import icon1 from '../../assets/pushbutton_recommendations.png'
function HowToImproveButton(props) {
  return (
    <div className="flex flex-col items-center justify-center my-auto mx-auto p-4">
        <div className='flex p-4'>
            <Link to={props.pagelink} className="items-center mx-auto transition duration-200 transform hover:scale-110">
              <img src={icon1} className="h-9 w-9 mx-auto" />
              <div className="text-sm font-medium mt-2 underline">How to Improve</div>
            </Link>
        </div>
    </div>
    // <div className="flex flex-col items-center my-auto mx-auto">
    //   <Link to={props.pagelink} className="bg-white rounded-full w-10 h-10 flex items-center justify-center hover:bg-slate-100 transition duration-200 transform hover:scale-110">
    //     <span className="text-green-500 text-2xl font-bold">!</span>
    //   </Link>
    //   <p className="mt-2">How to improve</p>
    // </div>
  )
}

export default HowToImproveButton