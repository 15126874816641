import React from "react";
import Icon1 from "../../../assets/Skin health/Skinhealthmenurolldown1.png";
import Icon2 from "../../../assets/Skin health/Skinhealthmenurolldown2.png";
import "react-circular-progressbar/dist/styles.css";
import ScorePage from "../../common/ScorePage";
import vitalIco from '../../../assets/ICON_AGING.png'

const AntiAging = () => {
  return (
    <>
      <ScorePage
        toolTipText="The signs of skin aging include wrinkles, loss of elasticity, laxity, and rough texture. To combat these signs, anti-aging products contain ingredients that aid in skin regeneration, resulting in a refreshed and healthier-looking layer. These products work by locking in moisture, counteracting free radicals, speeding up cell turnover, increasing collagen production, and smoothing out fine lines, among other benefits."
        feature={49}
        reconID="ANTI_AGING"
        icon={vitalIco}
        vital="AGING"
        textColor="#9b8ce9"
        bgColor="#d4c5df"
        Icon2={Icon1}
        Icon3={Icon2}
        Icon4={Icon2}
        prevpagelink="/myskinhealth"
        prevpagename="Back to My Skin Health"
      />
    </>
  );
};

export default AntiAging;
