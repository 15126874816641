import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import bg from "../../../assets/01.jpg";
import Logo from "../../../assets/logo2.png";
import { fetchUserInfo, logOutWithFirebase,checkPlanOptionListner } from '../../firebase/auth';
import UserPlan from "./userPlan";
import DiscountPlans from "./DiscountPlans";

const Subscription = () => {
  const [email, setEmail] = useState("");
  const [name,setName] = useState("");
  const [showDiscPlan,setShowDiscPlan]=useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const setUserInfo = async()=>{
    let respObj = await fetchUserInfo();
    if(respObj.status){
      setName(`${respObj.firstName} ${respObj.lastName}`);
      setEmail(respObj.email)
    }else{
      setName("");
      setEmail("");
    }
  }
  const checkForDiscPlanListner = async()=>{
    let respObj = await checkPlanOptionListner();
    if (respObj.status){
      if (respObj.approvedAffiliate){
        setShowDiscPlan(true)
      }
      if(respObj.validRefer){
        setShowDiscPlan(true)
      }
    }else{
      setShowDiscPlan(false)
    }
  }
  useEffect(()=>{
    setUserInfo();
    // setDataForNav();
  },[])
  useEffect(()=>{
    checkForDiscPlanListner();
  },[])
  const handleLogOut= async()=>{
    // do something here
    logOutWithFirebase();
    sessionStorage.clear();
    // navigate("/");
    window.location.href = "/login";
  }
  return (
    <div
      className="py-8 px-4 mx-auto max-w-screen lg:py-16 lg:px-6 bg-navColor"
      // style={{ backgroundImage: `url(${bg})`, backgroundSize: "cover" }}
    >
      {showDiscPlan ? (
        <DiscountPlans/>
      ):(
        <UserPlan/>
      )}
    </div>
  );
};

export default Subscription;
