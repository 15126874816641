import { POSTAFF_PRO_CRED, auth,db } from "./main";
import {
    getAuth,
    sendPasswordResetEmail,
    signOut,
    updatePassword,
} from "firebase/auth";
import {
    createUserWithEmailAndPassword,
    sendEmailVerification,
    signInWithEmailAndPassword,
    onAuthStateChanged,
} from "firebase/auth";
import { doc, setDoc, getDoc, updateDoc, addDoc, collection } from "firebase/firestore";
import {Buffer} from "buffer";
import moment from "moment-timezone";
import seedrandom from "seedrandom";
import { appURL,PAYPAL_LIVE_CREDS as PAYPAL_CONFIG } from "./main";


function calculateAge(birthYear) {
    let currentYear = new Date().getFullYear();
    return currentYear - birthYear;
}
function calculateBirthYear(age) {
  if(age !== null ){
    const currentYear = new Date().getFullYear();
    const birthYear = currentYear - age;
    return birthYear;
  }else{
    return null;
  }
}

function createUniqueString(input,length){
  let result = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let rng = seedrandom(input);
  for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(rng() * characters.length));
  }
  return result;
}

const listPropertyAppend=(list,value)=>{
  list.push(value)
  return list
}

const checkValidReferralCodeAndUpdate = async(referralCode,userEmail)=>{
  try {
    const docRef = doc(db, "referral_codes", referralCode);
    const docSnap = await getDoc(docRef);
    let joinedUsers = listPropertyAppend(docSnap.data().joinedUsers,userEmail)
    // console.log('Joined User Appended List : ',joinedUsers)
    if(docSnap.exists()){
      await updateDoc(docRef,{
        joinedUsers:joinedUsers
      })
      return true
    }else{
      return false
    }
  } catch (e) {
    // console.log('Error Captured When checking valid refer : ',e)
    return false
  }
  
}

export const registerWithFirebase = async (
  first_name,
  last_name,
  email,
  password,
  affliateSelected,
  capturedReferralCode
) => {
  let registeredValue = await createUserWithEmailAndPassword(
    auth,
    email,
    password
  )
    .then(async (userCred) => {
      // let actionCodeSettings = {
      //   url: `${appURL}/login`,
      //   handleCodeInApp: true,
      // };
      // await sendEmailVerification(auth.currentUser, actionCodeSettings)
      //   .then(() => {
      //     // console.log("Email Verification has been sent !");
      //   })
      //   .catch((err) => {
      //     // console.log("Something happend in email verification :",err);
      //   });
      let d = new Date();
      const dateDay = d.getDay();
      const expDate = new Date(d);
      expDate.setDate(expDate.getDate()); // 
      const expYear = expDate.getFullYear();
      const expMonth = (expDate.getMonth() + 1).toString().padStart(2, '0');
      const expDay = expDate.getDate().toString().padStart(2, '0');
      const dateString = `${expYear}-${expMonth}-${expDay}`;
      // Check for valid affiliate referral code 
      // let validRefer = await checkValidReferralCodeAndUpdate(capturedReferralCode,email)
      let validRefer = await checkReferralId(capturedReferralCode);
      if (!validRefer){
        capturedReferralCode = ''
      }
      if (affliateSelected){
        let ownReferralCode = createUniqueString(email,6);
        let referralDoc = await setDoc(doc(db,'referral_codes',ownReferralCode),{
          referralId:ownReferralCode,
          joinedUsers:[],
          payedUsers:[]
        })
        let docValue = await setDoc(doc(db, "users", auth.currentUser.uid), {
          firstName: first_name,
          lastName: last_name,
          email: email,
          subscription: false,
          total_scans: 0,
          subscriptionID:'',
          goal:false, 
          trial:true,
          resultDoc:"",
          prevDoc:"",
          scanDate:"",
          scanTime:"",
          trialExpiry:dateString,
          age:null,
          gender:null,
          height:null,
          weight:null,
          weeklyDoc:'',
          validRefer:validRefer,// if this is true -> discount packs will be shown
          referredBy:capturedReferralCode,
          joinAffiliate:affliateSelected,
          approvedAffiliate:true,// if this is true -> discount packs will be shown
          ownReferralCode:ownReferralCode,
        })
          .then(() => {
            sessionStorage.setItem("uid", auth.currentUser.uid);
            getTZ()
            return [true, `Welcome ${first_name} ${last_name} !`];
          })
          .catch((er) => {
            console.log("Something went wrong when adding to firestore : ", er);
            return [false, "User already exists !"];
          });
        return [docValue[0], docValue[1]];
      }else{
        let docValue = await setDoc(doc(db, "users", auth.currentUser.uid), {
          firstName: first_name,
          lastName: last_name,
          email: email,
          subscription: false,
          total_scans: 0,
          subscriptionID:'',
          goal:false, 
          trial:true,
          resultDoc:"",
          prevDoc:"",
          scanDate:"",
          scanTime:"",
          trialExpiry:dateString,
          age:null,
          gender:null,
          height:null,
          weight:null,
          weeklyDoc:'',
          validRefer:validRefer,// if this is true -> discount packs will be shown
          referredBy:capturedReferralCode,
          joinAffiliate:affliateSelected,
          approvedAffiliate:false,
          ownReferralCode:'',
        })
          .then(() => {
            sessionStorage.setItem("uid", auth.currentUser.uid);
            getTZ()
            return [true, `Welcome ${first_name} ${last_name} !`];
          })
          .catch((er) => {
            console.log("Something went wrong when adding to firestore : ", er);
            return [false, "User already exists !"];
          });
        return [docValue[0], docValue[1]];
      }
    })
    .catch((er) => {
      console.log("Error Code : ", er);
      // console.log("Error Message : ", er.message);
      return [false, "User already exists !"];
    });
  return [registeredValue[0], registeredValue[1]];
};

export const signInWithFirebase = async (email, password) => {
  let signedValue = await signInWithEmailAndPassword(auth, email, password)
      .then(async (userCred) => {
      // console.log("User Success in Logging In :", userCred.user);
      sessionStorage.setItem("uid", userCred.user.uid);
      const docRef = doc(db, "users", auth.currentUser.uid);
      const docSnap = await getDoc(docRef);
      // if (auth.currentUser.emailVerified) {
          // console.log(
          //   "Email verified by user : ",
          //   auth.currentUser.emailVerified
          // );
          if (docSnap.exists()) {
          // console.log("Document Data : ", docSnap.data());
          // console.log(
          //   true,
          //   `Welcome ${docSnap.data().firstName} ${docSnap.data().lastName} !`,
          //   docSnap.data().subscription
          // );
          getTZ()
          return [
              true,
              `Welcome ${docSnap.data().firstName} ${docSnap.data().lastName} !`,
              docSnap.data().subscription,
              docSnap.data().approvedAffiliate,
          ];
          } else {
          // console.log("User Does not Exists !");
          return [false, "User does not exist !", false,false];
          }
      // } else {
      //     if (docSnap.exists()) {
      //     // console.log("Document Data : ", docSnap.data());
      //     return [
      //         false,
      //         "Please verify your email to continue using our app !",
      //         false,
      //         false,
      //     ];
      //     } else {
      //     // console.log("User Does not Exists !");
      //     return [false, "User does not exist !", false,false];
      //     }
      // }
      })
      .catch((er) => {
      // console.log("Error Code : ", er.code);
      // console.log("Error Message : ", er.messsage);
      return [false, "User account doesn’t exist or the credential is not correct!", false,false];
      });
  // console.log("in firebase : ", signedValue[0], signedValue[1], signedValue[2]);
  return [signedValue[0], signedValue[1], signedValue[2],signedValue[3]];
};

export const getAffiliateInformation = async()=>{
  let respObjec = {
    status:false
  }
  let fetchAffliateInfo = await new Promise((resolve,reject)=>{
    onAuthStateChanged(auth,async(user)=>{
      if(user){
        const docRef = doc(db,'users',user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()){
          if (docSnap.data().approvedAffiliate){
            let ownReferralCode = docSnap.data().ownReferralCode;
            const referDocRef = doc(db,'referral_codes',ownReferralCode)
            const referSnap = await getDoc(referDocRef);
            if (referSnap.exists()){
              // console.log('Leng : ',(referSnap.data().payedUsers).length)
              let countJoinedUsers = (referSnap.data().joinedUsers).length
              let countPayedUsers = (referSnap.data().payedUsers).length
              let revenueEstimate = 5 * countPayedUsers;
              let scanStatus = false;
              if (docSnap.data().weeklyDoc != ''){
                scanStatus=true;
              }
              respObjec = {
                status:true,
                countJoinedUsers:countJoinedUsers,
                countPayedUsers:countPayedUsers,
                revenueEstimate:revenueEstimate,
                ownReferralCode:ownReferralCode,
                scanStatus:scanStatus
              }
              // console.log('Affliate info : ',respObjec)
            }
          }
        }
      }
      resolve(respObjec)
    })
  })
  respObjec=fetchAffliateInfo;
  return respObjec
}

const getTZ = ()=>{
  let tz = moment.tz.guess()
  sessionStorage.setItem('tz',tz)
}

export const resetPasswordWithFirebase = async (email) => {
  let actionCodeSettings = {
      url: `${appURL}login`,
      handleCodeInApp: true,
  };
  let resetValue = await sendPasswordResetEmail(auth, email, actionCodeSettings)
      .then(() => {
      console.log("Email has been sent");
      return [
          true,
          "A mail has been sent to your registered email-address to reset your password.",
      ];
      })
      .catch((err) => {
      console.log("Error in reset password : ", err.code, err.message);
      return [
          false,
          "An Error Occured ! Your email-address is not registered with our app.",
      ];
      });
  return resetValue;
};

export const changePasswordWithFirebase = async (newPwd) => {
  let updateStatus = false;
  let changeStatus = await new Promise((resolve,reject)=>{
    onAuthStateChanged(auth,async(user)=>{
      if(user){
        await updatePassword(user.uid, newPwd).then(() => {
          // console.log("Password Updated");
          updateStatus = true;
          })
          .catch((e) => {
          // console.log("Error : ", e);
          updateStatus = false;
          });
      }else{
        updateStatus=false;
      }
      resolve(updateStatus)
    })
  })
  updateStatus = changeStatus;
  return updateStatus
};

export const logOutWithFirebase = async () => {
  await signOut(auth).then(()=>{
    return true;
  }).catch(()=>{
    return false;
  })
};

export const fetchUserInfo = async()=>{
  let respObj = {
    status:false
  }
  let fetchUserStatus = await new Promise((resolve,reject)=>{
    onAuthStateChanged(auth,async(user)=>{
      if(user){
          const docRef = doc(db,"users",user.uid);
          const docSnap = await getDoc(docRef);
          if(docSnap.exists()){
            let birthYear = null
            if (docSnap.data().age !== null){
              birthYear = calculateBirthYear(docSnap.data().age)
            }
            respObj = {
              status:true,
              firstName: docSnap.data().firstName,
              lastName:docSnap.data().lastName,
              email:docSnap.data().email,
              subscription:docSnap.data().subscription,
              subscriptionID:docSnap.data().subscriptionID,
              scanDate:docSnap.data().scanDate,
              trialExpiry:docSnap.data().trialExpiry,
              trial:docSnap.data().trial,
              verified:user.emailVerified,
              height:docSnap.data().height,
              weight:docSnap.data().weight,
              gender:docSnap.data().gender,
              birthYear:birthYear,
              goal:docSnap.data().goal,
              approvedAffiliate:docSnap.data().approvedAffiliate,
            }
          }else{
            respObj = {
              status:false
            }
          }
        }
      resolve(respObj)
    })
  })
  respObj=fetchUserStatus
  return respObj
}

export const updateUserInfo = async(
  changeName,
  setGoal,
  setPassword,
  firstName,
  lastName,password,birthYear,height,weight,gender)=>{
  let respObj = {
    status:false
  }
  let respStatus = false;
  let userResp = await new Promise((resolve,reject)=>{
    onAuthStateChanged(auth, async (user)=>{
      const docRef = doc(db,"users",user.uid);
      const docSnap = await getDoc(docRef);
      if(docSnap.exists()){
        // console.log('User Record Found : ',docSnap.data());
        if (changeName){
          lastName = docSnap.data().lastName
          firstName = docSnap.data().firstName
          await updateDoc(doc(db, "users", user.uid), {
            firstName: firstName,
            lastName:lastName,
          }).then(()=>{
            // console.log('Name Updated')
            respStatus= true;
          }).catch(()=>{
            // console.log('Name Error')
            respStatus=false;
          })
        }
        if (setPassword){
          let passwordStatus = await changePasswordWithFirebase(password);
          if (passwordStatus){
            respStatus= true;
            /// do something
          }else{
            // do something
            respStatus = false;
          }
        }
        let userAge = calculateAge(birthYear);
        await updateDoc(doc(db, "users", user.uid), {
          height:height,
          weight:weight,
          gender:gender,
          age:userAge,
          goal:setGoal
        }).then(()=>{
          // console.log('Goal Updated')
          respStatus= true;
        }).catch(()=>{
          // console.log('Goal Error')
          respStatus=false;
        })
      }else{
        respStatus=false;
      }
      resolve(respStatus)
    })
  }) 
  // console.log('userResp');
  respStatus = userResp;
  return respStatus
}

export const updateTrialStatus = async()=>{
  let fetch_status = false;
  let resp = await new Promise((resolve,reject)=>{
      onAuthStateChanged(auth,async(user)=>{
          if (user){
              const userRef = doc(db,'users',user.uid);
              try {
                  const snapShot = await getDoc(userRef);
                  if(snapShot.exists()){
                      if (snapShot.data().subscription){
                          fetch_status= true
                      }
                      else{
                          if(snapShot.data().trial){
                              if((snapShot.data().prevDoc).length == 0){
                                  // console.log('Prev Doc Lenght : ',(snapShot.data().prevDoc).length)
                                  fetch_status= true
                              }else{
                                  await updateDoc(userRef,{
                                      trial:false,
                                  })
                                  fetch_status=false
                              }
                          }else{
                              await updateDoc(userRef,{
                                  trial:false,
                              })
                              fetch_status= false
                          }
                      }
                  }else{
                      // console.log('No Record Found')
                      fetch_status= false
                  }
              } catch (e) {
                  // console.log('Error : ',e);
                  fetch_status= false
              }
          }else{
              fetch_status=false
          }
          resolve(fetch_status)
      }) 
  })
  fetch_status = resp;
  return fetch_status
}


export const updateSubscriptionInfo = async(subscriptionID)=>{
  let subState = false;
  let resp = await new Promise((resolve,reject)=>{
    onAuthStateChanged(auth,async(user)=>{
      if(user){
        await updateDoc(doc(db,'users',user.uid),{
          subscriptionID:subscriptionID,
          subscription:true,
        })
        subState = true;
      }else{
        subState = false;
      }
      resolve(subState)
    })
  })
  subState = resp;
  return subState
}

export const paypalAuth = async ()=>{
  // to get access token from paypal to get information about user subscription
  let details = {
    'grant_type':'client_credentials'
  }
  let formBody=[];
  for ( var ppty in details){
    var encodedKey = encodeURIComponent(ppty);
    var encodedValue = encodeURIComponent(details[ppty]);
    formBody.push(encodedKey+"="+encodedValue);
  }
  formBody = formBody.join("&");
  let payPalTokenInfo=await fetch(`${PAYPAL_CONFIG.apiURL}/v1/oauth2/token`,{ 
    method:'POST',
    headers:{
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic '+ Buffer.from(PAYPAL_CONFIG.clientID + ':' + PAYPAL_CONFIG.clientSecret).toString('base64'),
  },
    body:formBody,
  }).then(async (paypalCred)=>{
    let data  = await paypalCred.json()
    // console.log('Paypal Cred ',data);
    return [true,data['access_token']]
  }).catch((e)=>{
    // console.log('Something happened in paypal auth : ',e);
    return [false,'']
  })
  return payPalTokenInfo
}

export const fetchPayPalSubscriptionStatus = async()=>{
  // use accesstoken of paypal get subscription status from paypal
  let scanInfo = await fetchUserInfo();
  if (scanInfo.status){
    if(scanInfo.subscription){
      let payPalAuthState = await paypalAuth();
      if (payPalAuthState[0]){
        let fetchInfo = await fetch(`${PAYPAL_CONFIG.apiURL}/v1/billing/subscriptions/${scanInfo.subscriptionID}`,{
          headers:{
            'Content-Type':'application/json',
            'Authorization':`Bearer ${payPalAuthState[1]}`
          }
        }).then(async (subInfo)=>{
          // console.log('subInfo : ',subInfo)
          if (subInfo['ok']){
            let data = await subInfo.json();
            // console.log('paypal data : ',data)

            // check plan here if annual plan return true with active after checking start date 

            return [true,data['status']]
          }else{
            return [false,'']
          }
        }).catch((e)=>{
          // console.log('Something happend in fetch paypal : ',e);
          return [false,'']
        })
        return fetchInfo
      }else{
        return [false,'']
      }
    }else{
      return [false,'']
    }
    
  }else{
    return [false,'']
  }
}

export const updateFirebaseSubscriptionStatusUsingPayPal = async ()=>{
  // update the subscription to false if subsription status from paypal is not active. 
  // let authState = authStateListener();\
  let subState = false;
  let resp = await new Promise((resolve,reject)=>{
    onAuthStateChanged(auth,async(user)=>{
      if(user){
        let paypalStatusInfo = await fetchPayPalSubscriptionStatus();
        // console.log('Info : ',paypalStatusInfo)
        if (paypalStatusInfo[0]){
          if (paypalStatusInfo[1] != 'ACTIVE'){
            const docRef = doc(db, "users", user.uid);
            let updateStatus = await updateDoc(docRef, {
              subscription: false,
              trial:false,
            }).then(()=>{
              // console.log('Updated Subscription Status')
            }).catch((e)=>{
              // console.log('Some error occured : ',e)
            })
            subState = false;
          }else{
            const docRef = doc(db, "users", user.uid);
            let updateStatus = await updateDoc(docRef, {
              subscription: true,
            }).then(()=>{
              // console.log('Updated Subscription Status')
            }).catch((e)=>{
              // console.log('Some error occured : ',e)
            })
            subState = true;
          }
        }else{
          subState=false;
        }
      }else{
        subState=false;
      }
      resolve(subState)
    })
  })
  subState = resp;
  return subState
}

export const subStateListner = async()=>{
  let scanInfo = await fetchUserInfo();
  if (scanInfo.status){
    if(scanInfo.subscription){
      let resp = await updateFirebaseSubscriptionStatusUsingPayPal();
      // console.log('Resp : ',resp)
      return resp
    }else{
      if(scanInfo.trial){
        return true
      }else{
        return false
      }
    }
  }else{
    return false
  }
}


export const checkPlanOptionListner = async()=>{
  let respObj = {
    status:false
  }
  let optionResp = await new Promise((resolve,reject)=>{
    onAuthStateChanged(auth,async(user)=>{
      if(user){
        const docRef = doc(db,'users',user.uid);
        const docSnap = await getDoc(docRef);
        if(docSnap.exists()){
          respObj={
            status:true,
            approvedAffiliate:docSnap.data().approvedAffiliate,
            validRefer:docSnap.data().validRefer
          }
        }
      }
      resolve(respObj)
    })
  })
  respObj=optionResp
  return respObj
}

export const authStateListener = async()=>{
  let authState = false;
  let resp = await new Promise((resolve,reject)=>{
    onAuthStateChanged(auth,async(user)=>{
      if(user){
        authState=true;
      }else{
        authState=false;
      }
      resolve(authState)
    })
  })
  authState=resp
  return authState
}

export const scanRecordsListner = async()=>{
  let scanStatus =false;
  let respStatus = await new Promise((resolve,reject)=>{
    onAuthStateChanged(auth,async(user)=>{
      if(user){
          const docRef = doc(db,"users",user.uid);
          const docSnap = await getDoc(docRef);
          if(docSnap.exists()){
            if(docSnap.data().weeklyDoc != ''){
              scanStatus = true
            }else{
              scanStatus = false
            }
          }
        }
      resolve(scanStatus)
    })
  })
  scanStatus=respStatus
  return scanStatus
}


const checkReferralId = async(capturedReferralCode)=>{
  try {
    const docRef = doc(db,"postAffReferral","referralids");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()){
      let referralIDs = docSnap.data().ids
      // console.log('Refferal ID from DB : ',referralIDs)
      let isValidRefferal = referralIDs.includes(capturedReferralCode);
      // console.log(' Valid Refferal : ',isValidRefferal);
      return isValidRefferal
    }else{
      return false
    }
  } catch (er) {
    // console.log('Error in Aff Check : ',er)
    return false
  }
}