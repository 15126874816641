import React from "react";
import Icon2 from "../../../assets/Healthiness/01.png";
import Icon3 from '../../../assets/Healthiness/fitness.png'
import Icon4 from '../../../assets/Healthiness/supplement.png'
import "react-circular-progressbar/dist/styles.css";
import ScorePage from "../../common/ScorePage";
import MetaOx from '../../../assets/Healthiness/mymajorhealth/ICON_OXYGEN_METABOLISM.png'

const O2Meta = () => {
  return (
    <>
      <ScorePage
        toolTipText="Oxygen Conversion is A chemical process in which oxygen makes energy from carbohydrates (sugars)—also called aerobic metabolism, aerobic respiration, and cell respiration. The common signs of metabolic acidosis are: accelerated heartbeat (tachycardia), confusion, or dizziness, feeling very tired (fatigue), loss of appetite, headache, rapid breathing or long, deep breathing, nausea and vomiting, and feeling weak."
        feature={19}
        reconID="OXYGEN_METABOLISM"
        icon={MetaOx}
        vital="OXYGEN CONVERSION"
        textColor="#569dc5"
        bgColor="#c2d9e8"
        Icon2={Icon2}
        Icon3={Icon3}
        Icon4={Icon4}
        prevpagelink="/mymajorhealth"
        prevpagename="Back to My Major Health"
      />
    </>
  );
};

export default O2Meta;
