import React from "react";
import Icon1 from "../../../assets/Skin health/Skinhealthmenurolldown1.png";
import Icon2 from "../../../assets/Skin health/Skinhealthmenurolldown2.png";
import "react-circular-progressbar/dist/styles.css";
import ScorePage from "../../common/ScorePage";
import vitalIco from '../../../assets/ICON_EXFOLIATION.png'

const Exfoliants = () => {
    
    return (
      <>
      <ScorePage
        toolTipText="Regular exfoliation is essential to maintain soft and smooth skin by eliminating dead skin cells. Failure to exfoliate adult skin can lead to acne breakouts and faster visible aging. Dull and congested skin with a tendency to accumulate dirt, excess oil, and dead skin cells is a common result of infrequent exfoliation. Additionally, blackheads are more likely to develop in such cases."
        feature={48}
        reconID="EXFOLIATORS"
        icon={vitalIco}
        vital="EXFOLIATION"
        textColor="#b9afed"
        bgColor="#d4c5df"
        Icon2={Icon1}
        Icon3={Icon2}
        Icon4={Icon2}
        prevpagelink="/myskinhealth"
        prevpagename="Back to My Skin Health"
      />
    </>
    );
}
 
export default Exfoliants;