import React from "react";
import Icon2 from "../../../assets/Healthiness/01.png";
import Icon3 from '../../../assets/Healthiness/fitness.png'
import Icon4 from '../../../assets/Healthiness/supplement.png'
import "react-circular-progressbar/dist/styles.css";
import ScorePage from "../../common/ScorePage";
import EIco from '../../../assets/Healthiness/mynutrients/ICON_VITAMIN_E.png'

const VitaminE = () => {
  return (
    <>
      <ScorePage
        toolTipText="Vitamin E is an antioxidant that helps protect the body's cells from damage caused by free radicals. A deficiency in Vitamin E can result in nerve and muscle damage, a weakened immune system, vision problems, and an increased risk of infections. "
        feature={32}
        reconID="VITAMIN_E"
        icon={EIco}
        vital="VITAMIN E"
        textColor="#1f83d7"
        bgColor="#cbddf1"
        Icon2={Icon2}
        Icon3={Icon3}
        Icon4={Icon4}
        prevpagelink="/mynutrients"
        prevpagename="Back to My Nutrients"
      />
    </>
  );
};

export default VitaminE;
