import React from "react";
import Icon2 from "../../../assets/Healthiness/01.png";
import Icon3 from '../../../assets/Healthiness/fitness.png'
import Icon4 from '../../../assets/Healthiness/supplement.png'
import "react-circular-progressbar/dist/styles.css";
import ScorePage from "../../common/ScorePage";
import MetaChol from '../../../assets/Healthiness/mymajorhealth/ICON_CHOLESTERON_META.png'

const CholMeta = () => {
  return (
    <>
      <ScorePage
        toolTipText="The process of cholesterol conversion involves a series of biochemical reactions that take place when cholesterol is produced and broken down in the body. The liver plays a crucial role in regulating cholesterol conversion. Maintaining a healthy balance of cholesterol levels is essential for the normal functioning and survival of the organism. If the cholesterol metabolism is abnormal, it may lead to hyperlipidemia, heart attacks, strokes, and other health issues that can disrupt normal physiological functions. Therefore, people may become more susceptible to certain diseases."
        feature={18}
        reconID="CHOL_METABOLISM"
        icon={MetaChol}
        vital="CHOLESTEROL CONVERSION"
        textColor="#4b82a6"
        bgColor="#c2d9e8"
        Icon2={Icon2}
        Icon3={Icon3}
        Icon4={Icon4}
        prevpagelink="/mymajorhealth"
        prevpagename="Back to My Major Health"
      />
    </>
  );
};

export default CholMeta;
