import React from "react";
import Icon2 from "../../../assets/Healthiness/01.png";
import Icon3 from '../../../assets/Healthiness/fitness.png'
import Icon4 from '../../../assets/Healthiness/supplement.png'
import "react-circular-progressbar/dist/styles.css";
import ScorePage from "../../common/ScorePage";
import LThIco from '../../../assets/Healthiness/mynutrients/ICON_L_THEANINE.png'


const NutriLTh = () => {
    return (
      <>
      <ScorePage
        toolTipText="L-theanine is an amino acid commonly found in tea leaves and can have several health benefits. However, low levels of theanine in the body can result in anxiety, depression, and sleep disturbances. L-theanine has a calming effect on the body and has been shown to reduce stress and anxiety by increasing alpha brain waves and dopamine levels. Additionally, theanine has antioxidant properties that can help protect the body from cellular damage caused by oxidative stress."
        feature={40}
        reconID="L_THEANINE"
        icon={LThIco}
        vital="L-THEANINE"
        textColor="#5f89d7"
        bgColor="#cbddf1"
        Icon2={Icon2}
        Icon3={Icon3}
        Icon4={Icon4}
        prevpagelink="/mynutrients"
        prevpagename="Back to My Nutrients"
      />
    </>
    );
}
 
export default NutriLTh;