import React from "react";
import Icon2 from "../../../assets/Healthiness/01.png";
import Icon3 from '../../../assets/Healthiness/fitness.png'
import Icon4 from '../../../assets/Healthiness/supplement.png'
import "react-circular-progressbar/dist/styles.css";
import ScorePage from "../../common/ScorePage";
import Spo2Icon from "../../../assets/Healthiness/myvital/ICONS/ICON_OXYGEN LEVEL.png"

const Spo2 = () => {
  
  return (
    <>
      <ScorePage
        toolTipText="Blood oxygen level is the amount of oxygen circulating in the blood. Many heart and lung conditions put you at risk for hypoxemia. High Oxygen Levels can include the next symptoms: pleuritic chest pain, substernal heaviness, coughing, and dyspnea secondary to tracheobronchitis and absorptive atelectasis, which can lead to pulmonary edema. Low OXYGEN LEVEL causes symptoms like headache, difficulty breathing, rapid heart rate, and bluish skin. It can also happen at high altitudes. "
        feature={3}
        reconID="SPO2"
        icon={Spo2Icon}
        vital="OXYGEN LEVEL"
        textColor="#96bae4"
        bgColor="#d2e6f8"
        Icon2={Icon2}
        Icon3={Icon3}
        Icon4={Icon4}
        prevpagelink="/myvitals"
        prevpagename="Back to My Vitals"
      />
    </>
  );
};

export default Spo2;
