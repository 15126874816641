import React from "react";
import Icon1 from "../../../assets/Skin health/Skinhealthmenurolldown1.png";
import Icon2 from "../../../assets/Skin health/Skinhealthmenurolldown2.png";
import "react-circular-progressbar/dist/styles.css";
import ScorePage from "../../common/ScorePage";
import vitalIco from '../../../assets/ICON_HYDRATION.png'

const Moisturizers = () => {
  return (
    <>
      <ScorePage
        toolTipText="Keeping yourself well-hydrated can have a positive effect on the appearance of your skin, making it look smoother and softer. If your skin is dehydrated, you may experience various symptoms such as itchiness, dullness, under-eye circles, sunken eyes, and more noticeable fine lines. In severe cases of dry skin, you may also experience symptoms such as dizziness, dry mouth, lightheadedness, and weakness."
        feature={46}
        reconID="MOISTURISERS"
        icon={vitalIco}
        vital="HYDRATION"
        textColor="#9140e0"
        bgColor="#d4c5df"
        Icon2={Icon1}
        Icon3={Icon2}
        Icon4={Icon2}
        prevpagelink="/myskinhealth"
        prevpagename="Back to My Skin Health"
      />
    </>
  );
};

export default Moisturizers;
