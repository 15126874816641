import React from "react";
import Icon2 from "../../../assets/Healthiness/01.png";
import Icon3 from '../../../assets/Healthiness/fitness.png'
import Icon4 from '../../../assets/Healthiness/supplement.png'
import "react-circular-progressbar/dist/styles.css";
import ScorePage from "../../common/ScorePage";
import ProbioticsIco from '../../../assets/Healthiness/mynutrients/ICON_PROBIOTICS.png'

const NutriProbio = () => {
    return (
      <>
      <ScorePage
        toolTipText="Probiotics play an essential role in maintaining the balance of microorganisms in the gut and can also affect the immune system. Low levels of probiotics, or beneficial gut bacteria, in the body, can lead to several health problems. A lack of probiotics can lead to digestive issues such as constipation, diarrhea, and Irritable Bowel Syndrome (IBS). It may also lead to symptoms of depression, anxiety, and several chronic diseases such as obesity, diabetes, and heart disease."
        feature={39}
        reconID="PROBIOTICS"
        icon={ProbioticsIco}
        vital="PROBIOTICS"
        textColor="#5f89d7"
        bgColor="#cbddf1"
        Icon2={Icon2}
        Icon3={Icon3}
        Icon4={Icon4}
        prevpagelink="/mynutrients"
        prevpagename="Back to My Nutrients"
      />
    </>
    );
}
 
export default NutriProbio;