import React from "react";
import Icon2 from "../../../assets/Healthiness/01.png";
import Icon3 from '../../../assets/Healthiness/fitness.png'
import Icon4 from '../../../assets/Healthiness/supplement.png'
import "react-circular-progressbar/dist/styles.css";
import ScorePage from "../../common/ScorePage";
import PhIco from '../../../assets/Healthiness/mynutrients/ICON_PHOSPHORUS.png'

const MineralPh = () => {
    
    return (
      <>
      <ScorePage
        toolTipText="Phosphorus plays a crucial role in various bodily functions, such as bone formation, cell growth, metabolism, and kidney function. Insufficient phosphorus intake can cause several health issues, including weakened bones, loss of appetite, nerve damage, and anemia. In children, it can lead to rickets, while adults may experience osteomalacia, which causes softening and weakening of the bones. Additionally, low levels of phosphorus can result in muscle weakness, fatigue, and breathing difficulties."
        feature={37}
        reconID="P"
        icon={PhIco}
        vital="PHOSPHOROUS"
        textColor="#1c66bb"
        bgColor="#cbddf1"
        Icon2={Icon2}
        Icon3={Icon3}
        Icon4={Icon4}
        prevpagelink="/mynutrients"
        prevpagename="Back to My Nutrients"
      />
    </>
    );
}
 
export default MineralPh;