import React from "react";
import Icon2 from "../../../assets/Healthiness/01.png";
import Icon3 from '../../../assets/Healthiness/fitness.png'
import Icon4 from '../../../assets/Healthiness/supplement.png'
import "react-circular-progressbar/dist/styles.css";
import ScorePage from "../../common/ScorePage";
import BCIcon from "../../../assets/Healthiness/myvital/ICONS/ICON_CHOLESTEROL.png"

const Cholesterol = () => {
  
  return (
    <>
      <ScorePage
        toolTipText="Cholesterol is a waxy, fat-like substance found in all your body's cells. Your body needs cholesterol to make hormones, vitamin D, and substances that help you digest foods. Symptoms of low cholesterol, hopelessness, nervousness, confusion, agitation, difficulty making a decision. Changes in your mood, sleep, or eating patterns. Usually, High cholesterol has no symptoms. This is wellness score, not actual reading."
        feature={8}
        reconID="CHOL"
        icon={BCIcon}
        vital="TOTAL CHOLESTEROL"
        textColor="#569dc5"
        bgColor="#c2d9e8"
        Icon2={Icon2}
        Icon3={Icon3}
        Icon4={Icon4}
        prevpagelink="/mymajorhealth"
        prevpagename="Back to My Major Health"
      />
    </>
  );
};

export default Cholesterol;
