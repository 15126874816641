import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import bg from "../../assets/01.jpg";
import Logo from "../../assets/logo2.png";
import { fetchUserInfo, signInWithFirebase } from "../firebase/auth";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [spin, setSpin] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSpin(true);
    let loginResp = await signInWithFirebase(email, password);
    if (loginResp[0]) {
      // set spinning false and navigate to dashboard page
      sessionStorage.setItem("status", "true");
      // navigate('/dashboard')
      // if (loginResp[3]) {
      //   window.location.href = "/refer";
      // } else {
      window.location.href = "/dashboard";
      // }
    } else {
      sessionStorage.setItem("status", "false");
    }
    setError(loginResp[1]);
    // setEmail("");setPassword("");
    setPassword("");
    setSpin(false);
  };
  return (
    <div
      className="h-screen flex flex-col my-auto bg-navColor"
      // style={{ backgroundImage: `url(${bg})`, backgroundSize: "cover" }}
    >
      <div className="mx-auto md:w-1/2 w-full my-auto">
        <div className=" mx-auto m-5 p-4">
          <img src={Logo} className="md:h-32 h-24 mx-auto" />
        </div>
        {error ? (
          <>
            <div
              className="flex p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50  "
              role="alert"
            >
              <svg
                aria-hidden="true"
                className="flex-shrink-0 inline w-5 h-5 mr-3"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Info</span>
              <div>{error}!</div>
            </div>
          </>
        ) : null}

        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="relative z-0 w-full mb-6 group">
            <input
              type="email"
              name="repeat_password"
              id="floating_repeat_password"
              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-white border-0 border-b-2 border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-blue-600 peer rounded-md"
              placeholder="Email Address "
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label
              htmlFor="floating_repeat_password"
              className="peer-focus:font-medium absolute text-sm  duration-300 transform -translate-y-6 scale-75 top-1 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-white  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 text-white"
            >
              Email
            </label>
          </div>
          <div className="relative z-0 w-full mb-6 group">
            <input
              type="password"
              name="repeat_password"
              id="floating_repeat_password"
              className="block py-2.5 px-0 w-full text-sm  bg-white rounded-md border-0 border-b-2 border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder="Password "
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <label
              htmlFor="floating_repeat_password"
              className="peer-focus:font-medium absolute text-sm text-white  duration-300 transform -translate-y-6 scale-75 top-1 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-white  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Password
            </label>
          </div>
          <div className="relative z-0 w-full mb-6 group flex flex-row  justify-between">
            <div>
              <Link
                to={"/reset-password"}
                className="text-white font-bold text-sm uppercase"
              >
                Forgot Password
              </Link>
            </div>
            {/* <div>
              <Link
                to={"/signup"}
                className="text-white font-bold text-sm uppercase underline"
              >
                Create an account
              </Link>
            </div> */}
          </div>

          <div className="error__value text-red-500 py-2">
            {spin ? (
              <>
                <div role="status">
                  <svg
                    aria-hidden="true"
                    className="w-8 h-8 mr-2 text-gray-200 animate-spin  fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              </>
            ) : null}{" "}
          </div>
          <center>
            <div className="mx-auto w-full">
              <div className=" w-full mx-auto">
                <button
                  type="submit"
                  className="text-blue-800 bg-white hover:bg-blue-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-10 py-2.5 text-center "
                >
                  Sign In
                </button>
              </div>
            </div>
          </center>
        </form>
      </div>
    </div>
  );
};

export default SignIn;
