import bg from "../../../assets/Healthiness/MY_HEALTH_BACKGROUND.png";
import icon1 from "../../../assets/Healthiness/ICONS/ICON_MY_MAJOR_HEALTH.png";
import icon2 from "../../../assets/Healthiness/ICONS/ICON_MY_VITALS.png";
import icon3 from "../../../assets/Healthiness/ICONS/ICON_MY_NUTRIENTS.png";
import { Link } from "react-router-dom";
import BackToMenu from "../../common/BackToMenu";
import { useEffect } from "react";
import backIcon from "../../../assets/Healthiness/pushbutton_come back - one page.png";


const MyHealthDash = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <div
      className="flex flex-col pl-3  px-10 min-h-screen"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSized: "cover",
        backgroundRepeat: "repeat",
      }}
    >
      <div className="flex font-semibold uppercase mx-auto pt-8 text-xl">
        My Health
      </div>
      <div className="my-5  mx-auto md:h-auto">
        <div className="flex flex-col md:flex-row md:space-x-5 md:space-y-0 space-y-2 mt-5">
          <Link to="/mymajorhealth" className=" shadow-2xl  transition duration-200 transform hover:scale-110">
            <div className="bg-white flex flex-col md:h-64 md:w-64  p-3 rounded-lg">
              <img src={icon1} className="shadow-2xl rounded-full  mx-auto my-auto md:h-40 md:w-40 h-24 w-24" />
              <div className=" uppercase font-bold mx-auto  p-4">
                My Major Health
              </div>
            </div>
          </Link>
          <Link to="/myvitals" className="shadow-2xl  transition duration-200 transform hover:scale-110">
            <div className="bg-white flex flex-col md:h-64 md:w-64 p-3 rounded-lg">
              <img src={icon2} className="shadow-2xl rounded-full  mx-auto my-auto md:h-40 md:w-40 h-24 w-24" />
              <div className=" uppercase font-bold mx-auto p-4">
                My Vitals
              </div>
            </div>
          </Link>
          <Link to="/mynutrients" className="shadow-2xl  transition duration-200 transform hover:scale-110">
            <div className="bg-white flex flex-col md:h-64 md:w-64 p-3 rounded-lg">
              <img src={icon3} className="shadow-2xl rounded-full  mx-auto my-auto md:h-40 md:w-40 h-24 w-24" />
              <div className=" uppercase font-bold mx-auto p-4">
                My Nutrients
              </div>
            </div>
          </Link>
        </div>
        <div className="mx-auto  flex flex-col md:mt-16">
          <div className="flex flex-col items-center justify-center my-auto mx-auto">
                <div className='flex p-4'>
                    <Link to={"/dashboard"} className="flex items-center mx-auto transition duration-200 transform hover:scale-110">
                      <img src={backIcon} className="h-9 w-9 mx-auto" />
                      <div className="mx-auto ml-3 text-sm">{"Back to My Total Report"}</div>
                    </Link>
                </div>
            </div>
          {/* <BackToMenu pagelink="/dashboard" menuPage="Back to My Total Report"/> */}
        </div>
      </div>
    </div>
  );
};

export default MyHealthDash;
