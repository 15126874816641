import React from "react";
import Icon2 from "../../../assets/Healthiness/01.png";
import Icon3 from '../../../assets/Healthiness/fitness.png'
import Icon4 from '../../../assets/Healthiness/supplement.png'
import "react-circular-progressbar/dist/styles.css";
import ScorePage from "../../common/ScorePage";
import YyIco from '../../../assets/wellness/ICON_ORGANS_ENERGY.png'


const YinYang = () => {
  
  return (
    <>
      <ScorePage
        toolTipText="Traditional Chinese Medicine (TCM) relies on the concept of Yin and Yang to comprehend health and to diagnose and treat illnesses. Each organ in the body is associated with specific elements, and its functioning depends on the balance of these elements and the overall inner balance. When organs become imbalanced, individuals may experience physical and emotional symptoms, including coughing and other illnesses."
        feature={21}
        reconID="YING_YANG"
        icon={YyIco}
        vital="ORGANS ENERGY"
        textColor="#4da3ab"
        bgColor="#bcd7d3"
        Icon2={Icon2}
        Icon3={Icon3}
        Icon4={Icon4}
        prevpagelink="/myenergy"
        prevpagename="Back to My Energy"
      />
    </>
  );
};

export default YinYang;
