import React from "react";
import Icon1 from "../../../assets/Skin health/Skinhealthmenurolldown1.png";
import Icon2 from "../../../assets/Skin health/Skinhealthmenurolldown2.png";
import "react-circular-progressbar/dist/styles.css";
import ScorePage from "../../common/ScorePage";
import vitalIco from '../../../assets/ICON_BRIGHTNESS.png'

const Brightening = () => {
    return (
      <>
      <ScorePage
        toolTipText="When people talk about having 'bright skin', they usually mean having a healthy, glowing appearance. This comes from having new, healthy skin cells that make your complexion look radiant. Skin-lightening products, also called bleaching creams, whiteners, skin brighteners, or fading creams, work by reducing the amount of melanin in the skin."
        feature={50}
        reconID="BRIGHTENING"
        icon={vitalIco}
        vital="BRIGHTNESS"
        textColor="#685ab3"
        bgColor="#d4c5df"
        Icon2={Icon1}
        Icon3={Icon2}
        Icon4={Icon2}
        prevpagelink="/myskinhealth"
        prevpagename="Back to My Skin Health"
      />
    </>
    );
}
 
export default Brightening;