import React, { useEffect } from "react";
import MyChart from "../dashboard/mychart2";
import HealthnessCard from "../../common/card";
import HowToImproveButton from "../../common/HowToImproveButton";
import BackToMenu from "../../common/BackToMenu";
import { Link } from "react-router-dom";
import ImmuneIcon from "../../../assets/Healthiness/mymajorhealth/ICON_IMMUNE.png";
import BoneIcon from "../../../assets/Healthiness/mymajorhealth/ICON_BONE_HEALTH.png";
import CircIcon from "../../../assets/Healthiness/mymajorhealth/ICON_CIRCULATION.png";
import MetaOx from "../../../assets/Healthiness/mymajorhealth/ICON_OXYGEN_METABOLISM.png";
import MetaSug from "../../../assets/Healthiness/mymajorhealth/ICON_SUGAR_METABOLISM.png";
import MetaChol from "../../../assets/Healthiness/mymajorhealth/ICON_CHOLESTERON_META.png";
import BCIcon from "../../../assets/Healthiness/myvital/ICONS/ICON_CHOLESTEROL.png"
import BackToPreviousPage from "../../common/BackToPreviousPage";
import Tooltip from "../../common/Tooltip";
function MyMajorHealth() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      className=" w-full flex flex-col md:flex-row pl-3 "
      style={{ backgroundColor: "#c2d9e8" }}
    >
      <div className="grow mt-5">
        <div className="dash1">
          <div>
            <Tooltip
              text="The immune system is a complex network of organs, cells, and proteins that defends the body against infection while protecting the body's cells. Primary immunodeficiency Signs and symptoms can include Frequent and recurrent pneumonia, bronchitis, sinus infections, ear infections, meningitis, or skin infections. Inflammation and infection of internal organs. Blood disorders, such as low platelet count or anemia."
            />
          </div>
          <div className="pl-5 pr-8">
            <Link to={"/mymajorhealth/immunity"} className="">
              <HealthnessCard
                feature={15}
                reconID="IMMUNE_FUNCTION"
                icon={ImmuneIcon}
                vital="IMMUNE"
                textColor="#70bfe5"
              />
            </Link>
          </div>
          <div className="dash2">
            <div className="dash4">
              <MyChart feature={15} ChartLabel="IMMUNE HEALTH" />
            </div>
            <div className="dash3">
              <HowToImproveButton pagelink="/mymajorhealth/immunity" />
            </div>
          </div>
        </div>
        <div className="border-b border-blue-900 my-4"></div>
        <div className="dash1">
          <div>
            <Tooltip
              text="Bone health is essential as your bones support your body, help you to move around, and protect sensitive organs like your heart and lungs. Bone health is intrinsically linked to calcium (and phosphorus) metabolism. There typically are no symptoms in the early stages of bone loss. But once your bones have been weakened by osteoporosis, you might have signs and symptoms: back pain caused by a fractured or collapsed vertebra, loss of height over time, a stooped posture, and a bone that breaks much more quickly than expected. "
            />
          </div>
          <div className="pl-5 pr-8">
            <Link to={"/mymajorhealth/bonehealth"} className="">
              <HealthnessCard
                feature={14}
                reconID="BONE_HEALTH"
                icon={BoneIcon}
                vital="BONE HEALTH"
                textColor="#92c8e7"
              />
            </Link>
          </div>
          <div className="dash2">
            <div className="dash4">
              <MyChart feature={14} ChartLabel="BONE HEALTH" />
            </div>

            <div className="dash3">
              <HowToImproveButton pagelink="/mymajorhealth/bonehealth" />
            </div>
          </div>
        </div>
        <div className="border-b border-blue-900 my-4"></div>
        <div className="dash1">
          <div>
            <Tooltip
              text="Circulation is blood flow through the heart, blood vessels, and lymph through the lymph vessels. Symptoms of poor circulation are numbness and tingling in extremities. One of the most common symptoms of poor circulation is numbness and tingling in the hands and feet, cold hands and feet, swelling in the lower extremities, cognitive dysfunction, digestive problems, fatigue, joint pain, and muscle cramping, skin color changes."
            />
          </div>
          <div className="pl-5 pr-8">
            <Link to={"/mymajorhealth/circulation"} className="">
              <HealthnessCard
                feature={23}
                reconID="CIRCULATION"
                icon={CircIcon}
                vital="CIRCULATION"
                textColor="#a6e0fc"
              />
            </Link>
          </div>
          <div className="dash2">
            <div className="dash4">
              <MyChart feature={23} ChartLabel="CIRCULATION" />
            </div>
            <div className="dash3">
              <HowToImproveButton pagelink="/mymajorhealth/circulation" />
            </div>
          </div>
        </div>
        <div className="border-b border-blue-900 my-4"></div>
        {/* <div className="dash1">
          <div>
              <Tooltip
              text="Cholesterol is a waxy, fat-like substance found in all your body's cells. Your body needs cholesterol to make hormones, vitamin D, and substances that help you digest foods. Symptoms of low cholesterol, hopelessness, nervousness, confusion, agitation, difficulty making a decision. Changes in your mood, sleep, or eating patterns. Usually, High cholesterol has no symptoms. This is wellness score, not actual reading."
              />
          </div>
          <div className="pl-5 pr-8">
            <Link to={"/mymajorhealth/cholesterol"} className="">
              <HealthnessCard
                feature={8}
                reconID="CHOL"
                icon={BCIcon}
                vital="TOTAL CHOLESTEROL"
                textColor="#569dc5"
              />
            </Link>
          </div>
          <div className="dash2">
            <div className="dash4">
              <MyChart feature={8} ChartLabel="TOTAL CHOLESTEROL" />
            </div>
            <div className="dash3">
              <HowToImproveButton pagelink="/mymajorhealth/cholesterol"/>
            </div>
          </div>
        </div> */}
        <div className="dash1">
          <div>
            <Tooltip
              text="Glucose Conversion refers to the process by which the body breaks down sugar (glucose) to produce energy. Most of the disorders associated with metabolic syndrome don't have obvious signs or symptoms. One visible sign is a large waist circumference. Poor glucose conversion leads to diabetes mellitus."
            />
          </div>
          <div className="pl-5 pr-8">
            <Link to={"/mymajorhealth/glucosemetabolism"} className="">
              <HealthnessCard
                feature={17}
                reconID="SUGAR_METABOLISM"
                icon={MetaSug}
                vital="GLUCOSE CONVERSION"
                textColor="#4f9aca"
              />
            </Link>
          </div>
          <div className="dash2">
            <div className="dash4">
              <MyChart feature={17} ChartLabel="GLUCOSE CONVERSION" />
            </div>
            <div className="dash3">
              <HowToImproveButton pagelink="/mymajorhealth/glucosemetabolism" />
            </div>
          </div>
        </div>
        <div className="border-b border-blue-900 my-4"></div>
        {/* <div className="dash1">
          <div>
            <Tooltip
              text="The process of Cholesterol Conversion involves a series of biochemical reactions that take place when cholesterol is produced and broken down in the body. The liver plays a crucial role in regulating cholesterol conversion. Maintaining a healthy balance of cholesterol levels is essential for the normal functioning and survival of the organism. If the cholesterol conversion is abnormal, it may lead to hyperlipidemia, heart attacks, strokes, and other health issues that can disrupt normal physiological functions. Therefore, people may become more susceptible to certain diseases."
            />
          </div>
          <div className="pl-5 pr-8">
            <Link to={"/mymajorhealth/cholesterolmetabolism"} className="">
              <HealthnessCard
                feature={18}
                reconID="CHOL_METABOLISM"
                icon={MetaChol}
                vital="CHOLESTEROL CONVERSION"
                textColor="#4b82a6"
              />
            </Link>
          </div>
          <div className="dash2">
            <div className="dash4">
              <MyChart feature={18} ChartLabel="CHOLESTEROL CONVERSION" />
            </div>
            <div className="dash3">
              <HowToImproveButton pagelink="/mymajorhealth/cholesterolmetabolism" />
            </div>
          </div>
        </div>
        <div className="border-b border-blue-900 my-4"></div> */}
        <div className="dash1">
          <div>
            <Tooltip
              text="Oxygen Conversion is A chemical process in which oxygen makes energy from carbohydrates (sugars)—also called aerobic metabolism, aerobic respiration, and cell respiration. The common signs of metabolic acidosis are: accelerated heartbeat (tachycardia), confusion, or dizziness, feeling very tired (fatigue), loss of appetite, headache, rapid breathing or long, deep breathing, nausea and vomiting, and feeling weak."
            />
          </div>
          <div className="pl-5 pr-8">
            <Link to={"/mymajorhealth/o2metabolism"} className="">
              <HealthnessCard
                feature={19}
                reconID="OXYGEN_METABOLISM"
                icon={MetaOx}
                vital="OXYGEN CONVERSION"
                textColor="#569dc5"
              />
            </Link>
          </div>
          <div className="dash2">
            <div className="dash4">
              <MyChart feature={19} ChartLabel="OXYGEN CONVERSION" />
            </div>
            <div className="dash3">
              <HowToImproveButton pagelink="/mymajorhealth/o2metabolism" />
            </div>
          </div>
        </div>
        <BackToMenu pagelink="/myhealth" menuPage="Back to My Health" />
        <BackToPreviousPage pagelink='/dashboard' menuPage="Back to My Total Report"  />
      </div>
    </div>
  );
}

export default MyMajorHealth;
