import React from "react";
import Icon1 from "../../../assets/Skin health/Skinhealthmenurolldown1.png";
import Icon2 from "../../../assets/Skin health/Skinhealthmenurolldown2.png";
import "react-circular-progressbar/dist/styles.css";
import ScorePage from "../../common/ScorePage";
import vitalIco from '../../../assets/ICON_SENSITIVITY.png'

const SoothingSensitiveSkin = () => {
    return (
      <>
      <ScorePage
        toolTipText="Many people experience sensitive skin, which can result in reactions like redness and itching. Common symptoms include itching, burning, and stinging of specific areas of skin. However, sensitive skin is a manageable condition that can be treated with the help of a dermatologist."
        feature={52}
        reconID="SOOTHING_SENSITIVE_SKIN"
        icon={vitalIco}
        vital="SENSITIVITY"
        textColor="#7560e2"
        bgColor="#d4c5df"
        Icon2={Icon1}
        Icon3={Icon2}
        Icon4={Icon2}
        prevpagelink="/myskinhealth"
        prevpagename="Back to My Skin Health"
      />
    </>
    );
}
 
export default SoothingSensitiveSkin;