import React from "react";
import Icon2 from "../../../assets/Healthiness/01.png";
import Icon3 from '../../../assets/Healthiness/fitness.png'
import Icon4 from '../../../assets/Healthiness/supplement.png'
import "react-circular-progressbar/dist/styles.css";
import ScorePage from "../../common/ScorePage";
import CircIcon from '../../../assets/Healthiness/mymajorhealth/ICON_CIRCULATION.png'

const Circulation = () => {
  return (
    <>
      <ScorePage
        toolTipText="Circulation is blood flow through the heart, blood vessels, and lymph through the lymph vessels. Symptoms of poor circulation are numbness and tingling in extremities. One of the most common symptoms of poor circulation is numbness and tingling in the hands and feet, cold hands and feet, swelling in the lower extremities, cognitive dysfunction, digestive problems, fatigue, joint pain, and muscle cramping, skin color changes."
        feature={23}
        reconID="CIRCULATION"
        icon={CircIcon}
        vital="CIRCULATION"
        textColor="#a6e0fc"
        bgColor="#c2d9e8"
        Icon2={Icon2}
        Icon3={Icon3}
        Icon4={Icon4}
        prevpagelink="/mymajorhealth"
        prevpagename="Back to My Major Health"
      />
    </>
  );
};

export default Circulation;
