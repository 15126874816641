import React, { useEffect, useState } from "react";
import NavMobile from "./navmobile";
import Nav from "./nav";
import LeftSection from "./right-section";
export default function Layouts(props) {
  const location = window.location.pathname;
  let authurls = ["/", "/login","/login/", "/signup","/signup/", "/reset-password","/reset-password/","/affiliate/signup","/affiliate/signup/","/affiliate/login","/affiliate/login/"];
  let checkoccurance = authurls.includes(location);
  const [navbar, setNavBar] = useState(false);

  useEffect(() => {
    setUp();
  }, [location]);
  const setUp = () => {
    if (checkoccurance) {
      setNavBar(false);
    } else {
      setNavBar(true);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className=" font-avenirNex">
      {navbar ? (
        <>
          <div>
            <NavMobile />
          </div>
          <div id="hidemobile">
            <Nav />
          </div>
        </>
      ) : null}

      <div className="flex flex-col md:flex-row">
        {/* <div className="bg-red-50">
          {navbar ? (
            <>
              <LeftSection />
            </>
          ) : null}
        </div> */}
        <div className="grow">{props.children}</div>
      </div>
    </div>
  );
}
