import React from "react";
import Icon2 from "../../../assets/Healthiness/01.png";
import Icon3 from '../../../assets/Healthiness/fitness.png'
import Icon4 from '../../../assets/Healthiness/supplement.png'
import "react-circular-progressbar/dist/styles.css";
import ScorePage from "../../common/ScorePage";
import MgIco from '../../../assets/Healthiness/mynutrients/ICON_MAGNESIUM.png'


const MineralMg = () => {
  
  return (
    <>
      <ScorePage
        toolTipText="Did you know that magnesium is crucial for over 300 biochemical reactions in your body? If you don't get enough magnesium, you may experience muscle weakness, tremors, fatigue, and irritability. A magnesium deficiency can even increase your risk of developing health conditions such as hypertension, type 2 diabetes, and osteoporosis. Plus, magnesium is essential for cardiovascular health, so a deficiency can also increase your risk of heart disease."
        feature={34}
        reconID="Mg"
        icon={MgIco}
        vital="MAGNESIUM"
        textColor="#1c66bb"
        bgColor="#cbddf1"
        Icon2={Icon2}
        Icon3={Icon3}
        Icon4={Icon4}
        prevpagelink="/mynutrients"
        prevpagename="Back to My Nutrients"
      />
    </>
  );
};

export default MineralMg;
