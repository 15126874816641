import React, { useEffect } from 'react'
import bg from "../../../assets/MY_SKIN_HEALTH_BACKGROUND.png"
import icon1 from "../../../assets/ICON_HYDRATION.png";
import icon2 from '../../../assets/ICON_PROTECTION.png'
import icon3 from '../../../assets/ICON_ACNE.png'
import icon4 from '../../../assets/ICON_AGING.png'
import icon5 from '../../../assets/ICON_EXFOLIATION.png'
import icon6 from '../../../assets/ICON_CLEANLINESS.png'
import icon7 from '../../../assets/ICON_SENSITIVITY.png'
import icon8 from '../../../assets/ICON_BRIGHTNESS.png'
import { Link } from "react-router-dom";
import BackToMenu from "../../common/BackToMenu";
import backIcon from "../../../assets/Healthiness/pushbutton_come back - one page.png";

function MySkinHealth() {
    useEffect(()=>{
        window.scrollTo(0,0);
    },[])
  return (
    <div
        className="flex flex-col pl-3  px-10 min-h-screen"
        style={{
            backgroundImage: `url(${bg})`,
            backgroundSize: "cover",
            backgroundRepeat: "repeat",
        }}
        >
        <div className="flex font-semibold uppercase mx-auto pt-8 text-xl">
            My Skin Health
        </div>
        <div className="my-auto mx-auto md:h-auto">
            <div className="grid md:grid-cols-4 gap-3 mt-2 grid-cols-1">
                <Link
                to={`/myskinhealth/hydration`}
                className="shadow-2xl transition duration-200 transform hover:scale-110"
                >
                    <div className="bg-white flex flex-col md:w-64 md:h-64 p-3 rounded-lg">
                        <img
                        src={icon1}
                        className="shadow-2xl rounded-full mx-auto my-auto md:h-40 md:w-40 h-24 w-24"
                        />
                        <div className="uppercase font-bold mx-auto p-4">Hydration</div>
                    </div>
                </Link>
                <Link
                to={`/myskinhealth/protection`}
                className="shadow-2xl transition duration-200 transform hover:scale-110"
                >
                    <div className="bg-white flex flex-col md:w-64 md:h-64 p-3 rounded-lg">
                        <img
                        src={icon2}
                        className="shadow-2xl rounded-full mx-auto my-auto md:h-40 md:w-40 h-24 w-24"
                        />
                        <div className="uppercase font-bold mx-auto p-4">Protection</div>
                    </div>
                </Link>
                <Link
                to={`/myskinhealth/acne`}
                className="shadow-2xl transition duration-200 transform hover:scale-110"
                >
                    <div className="bg-white flex flex-col md:w-64 md:h-64 p-3 rounded-lg">
                        <img
                        src={icon3}
                        className="shadow-2xl rounded-full mx-auto my-auto md:h-40 md:w-40 h-24 w-24"
                        />
                        <div className="uppercase font-bold mx-auto p-4">Acne</div>
                    </div>
                </Link>
                <Link
                to={`/myskinhealth/aging`}
                className="shadow-2xl transition duration-200 transform hover:scale-110"
                >
                    <div className="bg-white flex flex-col md:w-64 md:h-64 p-3 rounded-lg">
                        <img
                        src={icon4}
                        className="shadow-2xl rounded-full mx-auto my-auto md:h-40 md:w-40 h-24 w-24"
                        />
                        <div className="uppercase font-bold mx-auto p-4">Aging</div>
                    </div>
                </Link>
                <Link
                to={`/myskinhealth/exfoliation`}
                className="shadow-2xl transition duration-200 transform hover:scale-110"
                >
                    <div className="bg-white flex flex-col md:w-64 md:h-64 p-3 rounded-lg">
                        <img
                        src={icon5}
                        className="shadow-2xl rounded-full mx-auto my-auto md:h-40 md:w-40 h-24 w-24"
                        />
                        <div className="uppercase font-bold mx-auto p-4">Exfoliation</div>
                    </div>
                </Link>
                <Link
                to={`/myskinhealth/cleanliness`}
                className="shadow-2xl transition duration-200 transform hover:scale-110"
                >
                    <div className="bg-white flex flex-col md:w-64 md:h-64 p-3 rounded-lg">
                        <img
                        src={icon6}
                        className="shadow-2xl rounded-full mx-auto my-auto md:h-40 md:w-40 h-24 w-24"
                        />
                        <div className="uppercase font-bold mx-auto p-4">Cleanliness</div>
                    </div>
                </Link>
                <Link
                to={`/myskinhealth/sensitivity`}
                className="shadow-2xl transition duration-200 transform hover:scale-110"
                >
                    <div className="bg-white flex flex-col md:w-64 md:h-64 p-3 rounded-lg">
                        <img
                        src={icon7}
                        className="shadow-2xl rounded-full mx-auto my-auto md:h-40 md:w-40 h-24 w-24"
                        />
                        <div className="uppercase font-bold mx-auto p-4">Sensitivity</div>
                    </div>
                </Link>
                {/* <div className="shadow-2xl  relative ">
                    <div className="bg-white flex flex-col md:h-64 md:w-64 p-3 rounded-lg">
                        <img src={icon7} className="shadow-2xl rounded-full mx-auto my-auto md:h-40 md:w-40 h-24 w-24" />
                        <div className="uppercase font-bold mx-auto p-4">
                            Sensitivity
                        </div>
                    </div>
                    <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center rounded-lg">
                        <span className="text-white text-lg font-bold uppercase">Coming Soon</span>
                    </div>
                </div> */}
                <Link
                to={`/myskinhealth/brightness`}
                className="shadow-2xl transition duration-200 transform hover:scale-110"
                >
                    <div className="bg-white flex flex-col md:w-64 md:h-64 p-3 rounded-lg">
                        <img
                        src={icon8}
                        className="shadow-2xl rounded-full mx-auto my-auto md:h-40 md:w-40 h-24 w-24"
                        />
                        <div className="uppercase font-bold mx-auto p-4">Brightness</div>
                    </div>
                </Link>
            </div>
            <div className="mx-auto  flex flex-col md:mt-16">
                <div className="flex flex-col items-center justify-center my-auto mx-auto">
                        <div className='flex p-4'>
                            <Link to={"/dashboard"} className="flex items-center mx-auto transition duration-200 transform hover:scale-110">
                            <img src={backIcon} className="h-9 w-9 mx-auto" />
                            <div className="mx-auto ml-3 text-sm">{"Back to My Total Report"}</div>
                            </Link>
                        </div>
                    </div>
                {/* <BackToMenu pagelink="/dashboard" menuPage="Back to My Total Report"/> */}
            </div>
        </div>
    </div>
  )
}

export default MySkinHealth