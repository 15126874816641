import React from "react";
import Icon2 from "../../../assets/Healthiness/01.png";
import Icon3 from '../../../assets/Healthiness/fitness.png'
import Icon4 from '../../../assets/Healthiness/supplement.png'
import "react-circular-progressbar/dist/styles.css";
import ScorePage from "../../common/ScorePage";
import B3Ico from '../../../assets/Healthiness/mynutrients/ICON_VITAMIN_B3.png'


const VitaminB3 = () => {
    return (
      <>
      <ScorePage
        toolTipText="Lack of Vitamin B3, known as niacin deficiency, can cause Pellagra. The symptoms of Pellagra include dermatitis (skin rashes), diarrhea, dementia, and even death if left untreated. In addition to Pellagra, niacin deficiency can lead to other health problems, such as increased risk of cardiovascular diseases, high blood pressure, and elevated cholesterol levels."
        feature={27}
        reconID="VITAMIN_B3"
        icon={B3Ico}
        vital="VITAMIN B3"
        textColor="#1f83d7"
        bgColor="#cbddf1"
        Icon2={Icon2}
        Icon3={Icon3}
        Icon4={Icon4}
        prevpagelink="/mynutrients"
        prevpagename="Back to My Nutrients"
      />
    </>
    );
}
 
export default VitaminB3;