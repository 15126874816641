import React from "react";
import styles from "./tooltip.module.css";

export function SideNavTooltip(props) {
  <div className=" mr-3">
    <div className={styles.tooltip}>
      {/* Hover over me */}
      <span>
        <i className="fa fa-info-circle text-blue-800" aria-hidden="true"></i>
      </span>
      <span className={`${styles.tooltiptext} w-64 bg-blue-400 text-white`}>
        {props.text}
      </span>
    </div>
  </div>;
}
export default function Tooltip(props) {
  return (
    <div className="text-end md:-mr-4 md:-mt-40 md:mb-0 mb-3">
      <div className={styles.tooltip}>
        {/* Hover over me */}
        <span>
          <i className="fa fa-info-circle text-blue-800" aria-hidden="true"></i>
        </span>
        <span
          className={`${styles.tooltiptext} md:w-80 w-60 bg-white text-justify `}
        >
          {props.text}
        </span>
      </div>
    </div>
  );
}
