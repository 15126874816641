import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const labels = ['','Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];

function DashChart(props) {
    const options = {
      responsive: true,
      plugins: {
        tooltip:{
          enabled:true,
        },
        legend: {
          position: 'bottom',
          labels:{
            // padding:30,
          },
        },
        title: {
          display: true,
          // text: 'Week Overview',
        },
      },
      scales:{
        y:{
          beginAtZero:true,
          min:0,
          max:110,
          ticks:{
            stepSize:10,
            callback: (value, index, values) => (index == (values.length-1)) ? undefined : value,
          },
          stackWeight: 1,
        }
      }
    };
    const data = {
      labels,
      datasets: [
        {
          label: 'Health',
          data: props.data[0],
          borderColor: 'rgb(98, 164, 212)',
          backgroundColor: 'rgba(98, 164, 212, 0.9)',
        },
        {
          label: 'Wellness',
          data: props.data[1],
          borderColor: 'rgb(132, 209, 218)',
          backgroundColor: 'rgba(132, 209, 218,0.9)',
        },
        {
          label: 'Skin Health',
          data: props.data[2],
          borderColor: 'rgb(179, 143, 227)',
          backgroundColor: 'rgba(179, 143, 227, 0.9)',
        },
      ],
    };
  return (
  <Line options={options} data={data} className="h-10" style={{hi
  :'10px'}} />
  );
}

export default DashChart
