import React from 'react'
import { Link } from "react-router-dom";
import icon4 from "../../assets/Healthiness/pushbutton_come back_2.png";

function BackToPreviousPage(props) {
  return (
  <div className="flex flex-col items-center my-auto mx-auto">
    <div className='flex p-4'>
        <Link to={props.pagelink} className="flex items-center mx-auto transition duration-200 transform hover:scale-110">
          <img src={icon4} className="h-9 w-9 mx-auto" />
          <div className="mx-auto ml-3 text-sm  w-48">{props.menuPage}</div>
        </Link>
    </div>
  </div>
  )
}

export default BackToPreviousPage