import React from "react";
import Icon1 from "../../../assets/Skin health/Skinhealthmenurolldown1.png";
import Icon2 from "../../../assets/Skin health/Skinhealthmenurolldown2.png";
import "react-circular-progressbar/dist/styles.css";
import ScorePage from "../../common/ScorePage";
import vitalIco from '../../../assets/ICON_ACNE.png'

const AcneFighting = () => {
  return (
    <>
      <ScorePage
        toolTipText="Acne is a prevalent skin condition caused by clogged hair follicles under the skin. Symptoms of acne include crusting of skin bumps, cysts, papules (small red bumps), pustules (small red bumps containing white or yellow pus), redness around the skin eruptions, scarring of the skin, whiteheads, and blackheads."
        feature={51}
        reconID="ACNE_FIGHTING"
        icon={vitalIco}
        vital="ACNE"
        textColor="#d0b4f0"
        bgColor="#d4c5df"
        Icon2={Icon1}
        Icon3={Icon2}
        Icon4={Icon2}
        prevpagelink="/myskinhealth"
        prevpagename="Back to My Skin Health"
      />
    </>
  );
};

export default AcneFighting;
