import React from "react";
import Icon2 from "../../../assets/Healthiness/01.png";
import Icon3 from '../../../assets/Healthiness/fitness.png'
import Icon4 from '../../../assets/Healthiness/supplement.png'
import "react-circular-progressbar/dist/styles.css";
import ScorePage from "../../common/ScorePage";
import DIco from '../../../assets/Healthiness/mynutrients/ICON_VITAMIN_D.png'

const VitaminD = () => {
  return (
    <>
      <ScorePage
        toolTipText="Vitamin D plays an essential role in calcium metabolism and bone health, so that a deficiency can result in weakened bones and an increased risk of fractures. A lack of Vitamin D can also cause muscle weakness, mood changes, and a weakened immune system."
        feature={31}
        reconID="VITAMIN_D"
        icon={DIco}
        vital="VITAMIN D"
        textColor="#1f83d7"
        bgColor="#cbddf1"
        Icon2={Icon2}
        Icon3={Icon3}
        Icon4={Icon4}
        prevpagelink="/mynutrients"
        prevpagename="Back to My Nutrients"
      />
    </>
  );
};

export default VitaminD;
