import React, { useEffect, useState } from "react";
import Layout from "../layout";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  // Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import First from "./first";
// import bg from "../../assets/01.jpg";
import bg from "../../../assets/MY_TOTAL_REPORT_BACKGROUND.png";
import DashChart from "./dashchart";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {
  fetchLatestMainScore,
  fetchWeeklyMainData,
} from "../../firebase/scans";
import SubModal from "../profile/submodal";
import LeftSection from "../../common/LeftSection";
import Tooltip from "../../common/Tooltip";
import RightSection from "../../layouts/right-section";
import { Link } from "react-router-dom";
import { subStateListner } from "../../firebase/auth";

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const Dashboard = () => {
  const [hRatio, setHRatio] = useState("");
  const [wRatio, setWRatio] = useState("");
  const [sRatio, setSRatio] = useState("");
  const [hPercent, setHPercent] = useState(null);
  const [wPercent, setWPercent] = useState(null);
  const [sPercent, setSPercent] = useState(null);
  const [hText, setHText] = useState("");
  const [wText, setWText] = useState("");
  const [sText, setSText] = useState("");
  const [chartData, setChartData] = useState([]);
  const [hColor, setHColor] = useState("");
  const [wColor, setWColor] = useState("");
  const [sColor, setSColor] = useState("");
  const [showSubNow,setShowSubNow]=useState(false);

  const setLatestValues = async () => {
    let resp = await fetchLatestMainScore();
    if (resp.hScore !== -1) {
      setHRatio(Math.round(resp.hScore));
      setWRatio(Math.round(resp.wScore));
      setSRatio(Math.round(resp.sScore));
      setHPercent(Math.round(resp.hPercent));
      setWPercent(Math.round(resp.wPercent));
      setSPercent(Math.round(resp.sPercent));
      setHText(resp.hText);
      setWText(resp.wText);
      setSText(resp.sText);
      setHColor(resp.hColor);
      setWColor(resp.wColor);
      setSColor(resp.sColor);
    } else {
      setHRatio("");
      setWRatio("");
      setSRatio("");
      setHPercent(0);
      setWPercent(0);
      setSPercent(0);
      setHText("");
      setWText("");
      setSText("");
      setHColor("");
      setWColor("");
      setSColor("");
    }
  };

  const setGraphData = async () => {
    let resp = await fetchWeeklyMainData();
    setChartData(resp);
  };

  const fetchSubNowStatus = async()=>{
    let resp = await subStateListner()
    if (resp){// if subscribed dont show 
      setShowSubNow(false);
    }else{
      setShowSubNow(true);
    }
  }

  useEffect(()=>{
    fetchSubNowStatus();
  },[])

  useEffect(() => {
    setLatestValues();
    setGraphData();
  }, []);

  return (
    <div
      className="h-full w-full flex flex-col md:flex-row "
      style={{ backgroundColor: "#143f6b" }}
      // style={{ backgroundImage: `url(${bg})`, backgroundSize: "cover" }}
    >
      <div className="grow pt-7 ">
        <div className="flex justify-center mb-4">
          {showSubNow ? (
            <Link to={"/subscription"}>
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-base py-2 px-4 rounded">
                SUBSCRIBE NOW
              </button>
            </Link>
          ) : null}
        </div>
        <div className="flex flex-row pt-3  mx-auto w-full">
          <div className="mx-auto flex flex-col bg-neutral-200 md:flex-row md:space-x-5 md:space-y-0 space-y-3 rounded-lg mb-2 p-2">
            <Link
              to="/myhealth"
              className="shadow-lg  transition duration-200 transform hover:scale-110 w-52"
            >
              <div className="rounded-lg  bg-white flex flex-col ">
                <div className="mx-auto uppercase font-bold p-2">
                  Health Score
                </div>
                <div style={{ width: 100, height: 100 }} className="mx-auto">
                  <CircularProgressbar
                    value={hPercent}
                    text={hText}
                    styles={{
                      path: {
                        stroke: hColor,
                      },
                      trail: {
                        stroke: "#ddd",
                      },
                      text: {
                        fontSize: "1rem",
                        fill: hColor,
                        fontWeight:"bold"
                      },
                    }}
                  />
                </div>

                <div className="mx-3 bg-[#62a4d4] text-white bottom-0 rounded-lg mt-1 ">
                  <div className="mx-auto w-full">
                    <div className="mx-auto">
                      <center>{hPercent}/100</center>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
            <Link
              to={"/mywellness"}
              className="shadow-lg  transition duration-200 transform hover:scale-110 w-52"
            >
              <div className="rounded-lg  bg-white flex flex-col">
                <div className="mx-auto uppercase font-bold p-2">
                  Wellness Score
                </div>
                <div style={{ width: 100, height: 100 }} className="mx-auto">
                  <CircularProgressbar
                    value={wPercent}
                    text={wText}
                    styles={{
                      path: {
                        stroke: wColor,
                      },
                      trail: {
                        stroke: "#ddd",
                      },
                      text: {
                        fill: wColor,
                        fontSize: "1rem",
                        fontWeight:"bold"
                      },
                    }}
                  />
                </div>
                <div className="mx-3 bg-[#84d1da] text-white bottom-0 rounded-lg mt-1">
                  <center>{wPercent}/100</center>
                </div>
              </div>
            </Link>
            <Link
              to={"/myskinhealth"}
              className="shadow-lg  transition duration-200 transform hover:scale-110 w-52"
            >
              <div className="rounded-lg  bg-white flex flex-col">
                <div className="mx-auto uppercase font-bold p-2">
                  Skin Health Score
                </div>
                <div style={{ width: 100, height: 100 }} className="mx-auto">
                  <CircularProgressbar
                    value={sPercent}
                    text={sText}
                    styles={{
                      path: {
                        stroke: sColor,
                      },
                      trail: {
                        stroke: "#ddd",
                      },
                      text: {
                        fontSize: "1rem",
                        fill: sColor,
                        fontWeight:"bold"
                      },
                    }}
                  />
                </div>
                <div className="mx-3 bg-[#b38fe3] text-white bottom-0 rounded-lg mt-1">
                  <center>{sPercent}/100</center>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div
          className="md:w-2/3 mx-auto w-full bg-white bg-opacity-90 rounded-lg"
          style={{
            pointerEvents: "auto",
            marginBottom: "50px",
            marginTop: "20px",
          }}
        >
            <DashChart data={chartData} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
