import React from "react";
import Icon2 from "../../../assets/Healthiness/01.png";
import Icon3 from '../../../assets/Healthiness/fitness.png'
import Icon4 from '../../../assets/Healthiness/supplement.png'
import "react-circular-progressbar/dist/styles.css";
import ScorePage from "../../common/ScorePage";
import CIco from '../../../assets/Healthiness/mynutrients/ICON_VITAMIN_C.png'


const VitaminC = () => {
    return (
      <>
      <ScorePage
        toolTipText="Vitamin C is an essential nutrient that plays a crucial role in collagen synthesis, immune function, and as an antioxidant. A deficiency in Vitamin C can result in the following symptoms: Weakened immune system (Vitamin C is crucial for boosting the immune system, and lack can lead to more frequent infections and illnesses). Fatigue and weakness.Poor wound healing (Vitamin C helps in the production of collagen, which is vital in wound healing). A deficiency can lead to delayed healing of wounds. Dry and scaly skin. Joint and muscle pain: Vitamin C deficiency can cause joint and muscle pain and stiffness."
        feature={30}
        reconID="VITAMIN_C"
        icon={CIco}
        vital="VITAMIN C"
        textColor="#1f83d7"
        bgColor="#cbddf1"
        Icon2={Icon2}
        Icon3={Icon3}
        Icon4={Icon4}
        prevpagelink="/mynutrients"
        prevpagename="Back to My Nutrients"
      />
    </>
    );
}
 
export default VitaminC;