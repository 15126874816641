import { Chart } from 'chart.js';
import React, { useRef, useEffect, useState } from 'react';
import { fetchWeeklyData } from '../../firebase/scans';

const MyChart = (props) => {
  const chartContainer = useRef(null);
  const [weeklyAverageData,setWeeklyAverageData]=useState([])
  const [graphData,setGraphData]=useState([])
  const [weekColor,setWeekColor]=useState("")
  const [scoreText,setScoreText]=useState("");

  const setChartData = async()=>{
    let bResp = await fetchWeeklyData(props.feature,props.ChartLabel)
    if (bResp[0] !== 0){
      setWeeklyAverageData(bResp[0])
      setGraphData(bResp[1])
      setWeekColor(bResp[2])
      setScoreText(bResp[3])
      console.log('Score Text :',bResp[3])
    }else{
      setWeeklyAverageData([])
      setGraphData([])
      setWeekColor("")
      setScoreText("")
    }
  }

  useEffect(()=>{
    setChartData()
  },[props])

  useEffect(() => {
    const DATA_COUNT = 7;
    const NUMBER_CFG = { count: DATA_COUNT, min: 0, max: 100 };
    const labels = ['','Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
    const data = {
      labels: labels,
      datasets: [
        {
          label: `${scoreText}`,
          type:'line',
          data:graphData,
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgb(255, 99, 132)",
          yAxisID:'y',
        },
        {
          label: 'Average Weekly Rating',
          type:'line',
          data:weeklyAverageData,
          borderColor: weekColor,
          backgroundColor: "rgb(255, 99, 132)",
          yAxisID:'y5',
          pointRadius: 0,
        },
      ]
    };

    const chartConfig = {
      type: 'line',
      data: data,
      options: {
        responsive: true,
        plugins: {
          tooltip:{
            enabled:false
          },
          legend:{
            display:false,
          },
          title: {
            display: false,
            text: 'Stacked scales',
          },
        },
        scales: {
          x:{
            grid:{
              display:false
            }
          },
          y1: {
            min:0,
            max:50,
            type: 'category',
            labels: [''],
            offset: true,
            position: 'left',
            stack: 'demo',
            stackWeight: 1,
            border: {
              width:10,
              color: "rgb(219, 61, 33)"
            },
            ticks:{
              stepSize:5,
            }
          },
          y2: {
            min:50,
            max:75,
            type: 'category',
            labels: [''],
            offset: true,
            position: 'left',
            stack: 'demo',
            stackWeight: 1,
            border: {
              width:10,
              color: "rgb(213, 219, 33)"
            },
            grid:{
              display:false
            },
            ticks:{
              stepSize:5,
            }
          },
          y3: {
            min:75,
            max:100,
            type: 'category',
            labels: [''],
            offset: true,
            position: 'left',
            stack: 'demo',
            stackWeight: 1,
            border: {
              color: "rgb(61,219,33)",
              width:10,
            },
            ticks:{
              stepSize:5,
            }
          },
          y:{
            min:0,
            max:100,
            display:false,
            beginAtZero: true,
            offset:true,
            position: 'left',
            stackWeight: 1,
            grid:{
              display:false
            },
            ticks:{
              stepSize:5,
            }
          },
          y5:{
            min:0,
            max:100,
            display:false,
            offset:true,
            position: 'left',
            stackWeight: 1,
            grid:{
              display:false
            },
            ticks:{
              stepSize:5,
            }
          },
        }
      }
    };

    const myChart = new Chart(chartContainer.current, chartConfig);

    return () => {
      myChart.destroy();
    };
  }, [graphData]);

  return (
    <div>
      <canvas className='bg-white rounded-md opacity-80 md:p-5' ref={chartContainer} />
    </div>
  );
};

export default MyChart;
