import React from "react";
import Icon2 from "../../../assets/Healthiness/01.png";
import Icon3 from '../../../assets/Healthiness/fitness.png'
import Icon4 from '../../../assets/Healthiness/supplement.png'
import "react-circular-progressbar/dist/styles.css";
import ScorePage from "../../common/ScorePage";
import B12Ico from '../../../assets/Healthiness/mynutrients/ICON_VITAMIN_B12.png'

const VitaminBComplex = () => {
  
  return (
    <>
      <ScorePage
        toolTipText="Vitamin B12 is a nutrient that helps keep your body's blood and nerve cells healthy and helps make DNA, the genetic material in all your cells. Usually, when your body lacks vitamin B12, it can lead to vitamin B12 deficiency anemia. Symptoms of this condition include weakness, fatigue, pale skin, sore tongue, lightheadedness, shortness of breath, diarrhea or constipation, and loss of appetite."
        feature={29}
        reconID="VITAMIN_B_COMPLEX"
        icon={B12Ico}
        vital="VITAMIN B12"
        textColor="#1f83d7"
        bgColor="#cbddf1"
        Icon2={Icon2}
        Icon3={Icon3}
        Icon4={Icon4}
        prevpagelink="/mynutrients"
        prevpagename="Back to My Nutrients"
      />
    </>
  );
};

export default VitaminBComplex;
