import React from "react";
import Icon2 from "../../../assets/Healthiness/01.png";
import Icon3 from '../../../assets/Healthiness/fitness.png'
import Icon4 from '../../../assets/Healthiness/supplement.png'
import "react-circular-progressbar/dist/styles.css";
import ScorePage from "../../common/ScorePage";
import HeartIcon from "../../../assets/Healthiness/myvital/ICONS/ICON_HEART_RATE.png";

const HeartRate = () => {
  return (
    <>
      <ScorePage
        toolTipText="Your heart rate, or pulse, is the number of times your heart beats per minute. When the heart beats too fast, it may not pump enough blood to the rest of the body. As a result, the organs and tissues may not get enough oxygen. In general, tachycardia may lead to the following signs and symptoms: a sensation of a racing, pounding heartbeat or flopping in the chest (palpitations), chest pain, fainting (syncope), lightheadedness, rapid pulse rate, and shortness of breath. Heart arrhythmias may not cause any signs or symptoms. A doctor may notice an irregular heartbeat when examining you for another health reason. In general, signs and symptoms of arrhythmias may include: a fluttering in the chest, a racing heartbeat (tachycardia), a slow heartbeat (bradycardia), chest pain, shortness of breath, anxiety, fatigue, lightheadedness or dizziness, sweating, fainting (syncope) or near fainting"
        feature={2}
        reconID="HR"
        icon={HeartIcon}
        vital="HEART RATE"
        textColor="#377ac7"
        bgColor="#d2e6f8"
        Icon2={Icon2}
        Icon3={Icon3}
        Icon4={Icon4}
        prevpagelink="/myvitals"
        prevpagename="Back to My Vitals"
      />
    </>
  );
};

export default HeartRate;
