import React, { useEffect } from 'react'
import MyChart from '../dashboard/mychart2'
import HealthnessCard from '../../common/card'
import HowToImproveButton from '../../common/HowToImproveButton'
import BackToMenu from '../../common/BackToMenu'
import { Link } from 'react-router-dom'
import B12Ico from '../../../assets/Healthiness/mynutrients/ICON_VITAMIN_B12.png'
import B9Ico from '../../../assets/Healthiness/mynutrients/ICON_VITAMIN_B9.png'
import B3Ico from '../../../assets/Healthiness/mynutrients/ICON_VITAMIN_B3.png'
import CIco from '../../../assets/Healthiness/mynutrients/ICON_VITAMIN_C.png'
import EIco from '../../../assets/Healthiness/mynutrients/ICON_VITAMIN_E.png'
import DIco from '../../../assets/Healthiness/mynutrients/ICON_VITAMIN_D.png'
import ProbioticsIco from '../../../assets/Healthiness/mynutrients/ICON_PROBIOTICS.png'
import LThIco from '../../../assets/Healthiness/mynutrients/ICON_L_THEANINE.png'
import PolicoIco from '../../../assets/Healthiness/mynutrients/ICON_POILICOSANOL.png'
import OmegaIco from '../../../assets/Healthiness/mynutrients/ICON_OMEGA3.png'
import MgIco from '../../../assets/Healthiness/mynutrients/ICON_MAGNESIUM.png'
import CaIco from '../../../assets/Healthiness/mynutrients/ICON_CALCIUM.png'
import FeIco from '../../../assets/Healthiness/mynutrients/ICON_IRON.png'
import PhIco from '../../../assets/Healthiness/mynutrients/ICON_PHOSPHORUS.png'
import BackToPreviousPage from '../../common/BackToPreviousPage'
import Tooltip from '../../common/Tooltip'

function MyNutrients() {
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
  return (
    <div
      className=" w-full flex flex-col md:flex-row pl-3" style={{backgroundColor:"#cbddf1"}}
    >
        <div className="grow mt-5">
            <div className="dash1">
                <div>
                    <Tooltip
                    text="Vitamin B12 is a nutrient that helps keep your body's blood and nerve cells healthy and helps make DNA, the genetic material in all your cells. Usually, when your body lacks vitamin B12, it can lead to vitamin B12 deficiency anemia. Symptoms of this condition include weakness, fatigue, pale skin, sore tongue, lightheadedness, shortness of breath, diarrhea or constipation, and loss of appetite."
                    />
                </div>
                <div className="pl-5 pr-8">
                    <Link to={"/mynutrients/vitaminb12"} className="">
                    <HealthnessCard
                        feature={29}
                        reconID="VITAMIN_B_COMPLEX"
                        icon={B12Ico}
                        vital="VITAMIN B12"
                        textColor="#1f83d7"
                    />
                    </Link>
                </div>
                <div className="dash2">
                    <div className="dash4">
                    <MyChart feature={29} ChartLabel="VITAMIN B12" />
                    </div>
                    <div className='dash3'>

                    <HowToImproveButton pagelink="/mynutrients/vitaminb12"/>
                    </div>
                </div>
            </div>
            {/* <div className="border-b border-blue-900 my-4"></div>
            <div className='relative'>
                <div className="dash1" style={{opacity:0.6}}>
                    <div>
                        <Tooltip
                        text="Lack of vitamin B9, or folic acid or folate deficiency, can lead to several health problems. Folate plays a vital role in the production of red blood cells and helps in proper cell growth and development. A deficiency of vitamin B9 can lead to anemia, neural tube defects in infants born to women who are deficient during pregnancy, and even an increased risk of certain cancers. Folate deficiency symptoms may include weakness, fatigue, pale skin, irritability, and difficulty concentrating."
                        />
                    </div>
                    <div className="pl-5 pr-8">
                        <Link to={"/mynutrients/vitaminb9"} className="">
                        <HealthnessCard
                            feature={28}
                            reconID="VITAMIN_B9"
                            icon={B9Ico}
                            vital="VITAMIN B9"
                            textColor="#1f83d7"
                        />
                        </Link>
                    </div>
                    <div className="dash2">
                        <div className="dash4">
                        <MyChart feature={28} ChartLabel="VITAMIN B9" />
                        </div>
                        <div className='dash3'>

                        <HowToImproveButton pagelink="/mynutrients/vitaminb9"/>
                        </div>
                    </div>
                </div>
                <div style={{position: "absolute", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(240,240,240,0.8)", display: "flex", justifyContent: "center", alignItems: "center",fontWeight:"bold"}}>
                    Update in progress
                </div>
            </div> */}
            
            <div className="border-b border-blue-900 my-4"></div>
            <div className="dash1">
                <div>
                    <Tooltip
                    text="Lack of Vitamin B3, known as niacin deficiency, can cause Pellagra. The symptoms of Pellagra include dermatitis (skin rashes), diarrhea, dementia, and even death if left untreated. In addition to Pellagra, niacin deficiency can lead to other health problems, such as increased risk of cardiovascular diseases, high blood pressure, and elevated cholesterol levels."
                    />
                </div>
                <div className="pl-5 pr-8">
                    <Link to={"/mynutrients/vitaminb3"} className="">
                    <HealthnessCard
                        feature={27}
                        reconID="VITAMIN_B3"
                        icon={B3Ico}
                        vital="VITAMIN B3"
                        textColor="#1f83d7"
                    />
                    </Link>
                </div>
                <div className="dash2">
                    <div className="dash4">
                    <MyChart feature={27} ChartLabel="VITAMIN B3" />
                    </div>
                    <div className='dash3'>

                    <HowToImproveButton pagelink="/mynutrients/vitaminb3"/>
                    </div>
                </div>
            </div>
            <div className="border-b border-blue-900 my-4"></div>
            {/* <div className="dash1">
                <div>
                    <Tooltip
                    text="Vitamin C is an essential nutrient that plays a crucial role in collagen synthesis, immune function, and as an antioxidant. A deficiency in Vitamin C can result in the following symptoms: Weakened immune system (Vitamin C is crucial for boosting the immune system, and lack can lead to more frequent infections and illnesses). Fatigue and weakness.Poor wound healing (Vitamin C helps in the production of collagen, which is vital in wound healing). A deficiency can lead to delayed healing of wounds. Dry and scaly skin. Joint and muscle pain: Vitamin C deficiency can cause joint and muscle pain and stiffness."
                    />
                </div>
                <div className="pl-5 pr-8">
                    <Link to={"/mynutrients/vitaminc"} className="">
                    <HealthnessCard
                        feature={30}
                        reconID="VITAMIN_C"
                        icon={CIco}
                        vital="VITAMIN C"
                        textColor="#1f83d7"
                    />
                    </Link>
                </div>
                <div className="dash2">
                    <div className="dash4">
                    <MyChart feature={30} ChartLabel="VITAMIN C" />
                    </div>
                    <div className='dash3'>

                    <HowToImproveButton pagelink="/mynutrients/vitaminc"/>
                    </div>
                </div>
            </div>
            <div className="border-b border-blue-900 my-4"></div> */}
            {/* <div className="dash1">
                <div>
                    <Tooltip
                    text="Vitamin D plays an essential role in calcium metabolism and bone health, so that a deficiency can result in weakened bones and an increased risk of fractures. A lack of Vitamin D can also cause muscle weakness, mood changes, and a weakened immune system."
                    />
                </div>
                <div className="pl-5 pr-8">
                    <Link to={"/mynutrients/vitamind"} className="">
                    <HealthnessCard
                        feature={31}
                        reconID="VITAMIN_D"
                        icon={DIco}
                        vital="VITAMIN D"
                        textColor="#1f83d7"
                    />
                    </Link>
                </div>
                <div className="dash2">
                    <div className="dash4">
                    <MyChart feature={31} ChartLabel="VITAMIN D" />
                    </div>
                    <div className='dash3'>

                    <HowToImproveButton pagelink="/mynutrients/vitamind"/>
                    </div>
                </div>
            </div>
            <div className="border-b border-blue-900 my-4"></div> */}
            <div className="dash1">
                <div>
                    <Tooltip
                    text="Vitamin E is an antioxidant that helps protect the body's cells from damage caused by free radicals. A deficiency in Vitamin E can result in nerve and muscle damage, a weakened immune system, vision problems, and an increased risk of infections. "
                    />
                </div>
                <div className="pl-5 pr-8">
                    <Link to={"/mynutrients/vitamine"} className="">
                    <HealthnessCard
                        feature={32}
                        reconID="VITAMIN_E"
                        icon={EIco}
                        vital="VITAMIN E"
                        textColor="#1f83d7"
                    />
                    </Link>
                </div>
                <div className="dash2">
                    <div className="dash4">
                    <MyChart feature={32} ChartLabel="VITAMIN E" />
                    </div>
                    <div className='dash3'>

                    <HowToImproveButton pagelink="/mynutrients/vitamine"/>
                    </div>
                </div>
            </div>
            <div className="border-b border-blue-900 my-4"></div>
            {/* <div className="dash1">
                <div>
                    <Tooltip
                    text="Probiotics play an essential role in maintaining the balance of microorganisms in the gut and can also affect the immune system. Low levels of probiotics, or beneficial gut bacteria, in the body, can lead to several health problems. A lack of probiotics can lead to digestive issues such as constipation, diarrhea, and Irritable Bowel Syndrome (IBS). It may also lead to symptoms of depression, anxiety, and several chronic diseases such as obesity, diabetes, and heart disease."
                    />
                </div>
                <div className="pl-5 pr-8">
                    <Link to={"/mynutrients/probiotics"} className="">
                    <HealthnessCard
                        feature={39}
                        reconID="PROBIOTICS"
                        icon={ProbioticsIco}
                        vital="PROBIOTICS"
                        textColor="#5f89d7"
                    />
                    </Link>
                </div>
                <div className="dash2">
                    <div className="dash4">
                    <MyChart feature={39} ChartLabel="PROBIOTICS" />
                    </div>
                    <div className='dash3'>

                    <HowToImproveButton pagelink="/mynutrients/probiotics"/>
                    </div>
                </div>
            </div>
            <div className="border-b border-blue-900 my-4"></div> */}
            {/* <div className="dash1">
                <div>
                    <Tooltip
                    text="L-theanine is an amino acid commonly found in tea leaves and can have several health benefits. However, low levels of theanine in the body can result in anxiety, depression, and sleep disturbances. L-theanine has a calming effect on the body and has been shown to reduce stress and anxiety by increasing alpha brain waves and dopamine levels. Additionally, theanine has antioxidant properties that can help protect the body from cellular damage caused by oxidative stress."
                    />
                </div>
                <div className="pl-5 pr-8">
                    <Link to={"/mynutrients/ltheanine"} className="">
                    <HealthnessCard
                        feature={40}
                        reconID="L_THEANINE"
                        icon={LThIco}
                        vital="L-THEANINE"
                        textColor="#5f89d7"
                    />
                    </Link>
                </div>
                <div className="dash2">
                    <div className="dash4">
                    <MyChart feature={40} ChartLabel="L-THEANINE" />
                    </div>
                    <div className='dash3'>

                    <HowToImproveButton pagelink="/mynutrients/ltheanine"/>
                    </div>
                </div>
            </div>
            <div className="border-b border-blue-900 my-4"></div> */}
            {/* <div className="dash1">
                <div>
                    <Tooltip
                    text='Policosanol is a natural supplement made from sugar cane, with potential benefits that include: Reducing "bad" cholesterol: Research indicates that policosanol can lower levels of LDL cholesterol. Preventing heart disease: By lowering cholesterol, policosanol may help reduce the risk of heart disease. Blood sugar control: Studies have suggested that policosanol can improve insulin sensitivity and lower blood sugar levels. Improving athletic performance: Some evidence suggests that policosanol can increase endurance and performance in athletes, but more research is needed to confirm these benefits and determine the appropriate dose and duration of supplementation. It is important to consult with a healthcare professional before taking any supplement.'
                    />
                </div>
                <div className="pl-5 pr-8">
                    <Link to={"/mynutrients/policosanol"} className="">
                    <HealthnessCard
                        feature={41}
                        reconID="POLICOSANOL"
                        icon={PolicoIco}
                        vital="POLICOSANOL"
                        textColor="#5f89d7"
                    />
                    </Link>
                </div>
                <div className="dash2">
                    <div className="dash4">
                    <MyChart feature={41} ChartLabel="POLICOSANOL" />
                    </div>
                    <div className='dash3'>

                    <HowToImproveButton pagelink="/mynutrients/policosanol"/>
                    </div>
                </div>
            </div> */}
            {/* <div className="border-b border-blue-900 my-4"></div>
            <div className='relative'>
                <div className="dash1">
                    <div>
                        <Tooltip
                        text='Omega-3 fatty acids are crucial for maintaining good brain health. Low levels of omega-3s have been linked to cognitive decline, memory loss, and depression. Additionally, omega-3s have been found to lower triglyceride levels, reduce blood pressure, and prevent blood clots, which can help reduce the risk of developing cardiovascular disease. For those who suffer from joint pain caused by conditions such as arthritis, omega-3s can help reduce inflammation and ease discomfort. Omega-3s are also essential for maintaining healthy skin and hair, and a lack of them can lead to dryness and other skin problems. Finally, omega-3s play a crucial role in immune function, and low levels can cause an increased risk of infections and inflammatory conditions.'
                        />
                    </div>
                    <div className="pl-5 pr-8">
                        <Link to={"/mynutrients/omega3"} className="">
                        <HealthnessCard
                            feature={42}
                            reconID="OMEGA3"
                            icon={OmegaIco}
                            vital="OMEGA 3"
                            textColor="#5f89d7"
                        />
                        </Link>
                    </div>
                    <div className="dash2">
                        <div className="dash4">
                        <MyChart feature={42} ChartLabel="OMEGA 3" />
                        </div>
                        <div className='dash3'>

                        <HowToImproveButton pagelink="/mynutrients/omega3"/>
                        </div>
                    </div>
                </div>
                <div style={{position: "absolute", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(240,240,240,0.8)", display: "flex", justifyContent: "center", alignItems: "center",fontWeight:"bold"}}>
                    Update in progress
                </div>
            </div> */}
            
            <div className="border-b border-blue-900 my-4"></div>
            <div className="dash1">
                <div>
                    <Tooltip
                    text="Did you know that magnesium is crucial for over 300 biochemical reactions in your body? If you don't get enough magnesium, you may experience muscle weakness, tremors, fatigue, and irritability. A magnesium deficiency can even increase your risk of developing health conditions such as hypertension, type 2 diabetes, and osteoporosis. Plus, magnesium is essential for cardiovascular health, so a deficiency can also increase your risk of heart disease."
                    />
                </div>
                <div className="pl-5 pr-8">
                    <Link to={"/mynutrients/magnesium"} className="">
                    <HealthnessCard
                        feature={34}
                        reconID="Mg"
                        icon={MgIco}
                        vital="MAGNESIUM"
                        textColor="#1c66bb"
                    />
                    </Link>
                </div>
                <div className="dash2">
                    <div className="dash4">
                    <MyChart feature={34} ChartLabel="MAGNESIUM" />
                    </div>
                    <div className='dash3'>

                    <HowToImproveButton pagelink="/mynutrients/magnesium"/>
                    </div>
                </div>
            </div>
            <div className="border-b border-blue-900 my-4"></div>
            <div className="dash1">
                <div>
                    <Tooltip
                    text="The body needs calcium to ensure that muscles, nerves, and cells function properly. If calcium levels are low, the body will take calcium from bones, which can result in weakened bones and an increased likelihood of fractures. A lack of calcium can cause a range of health issues, including weakened teeth and bones, muscle cramps, and irregular heart rhythms. In more severe cases, a calcium deficiency can lead to osteoporosis, which is a condition that causes bones to become brittle and fragile."
                    />
                </div>
                <div className="pl-5 pr-8">
                    <Link to={"/mynutrients/calcium"} className="">
                    <HealthnessCard
                        feature={35}
                        reconID="Ca"
                        icon={CaIco}
                        vital="CALCIUM"
                        textColor="#1c66bb"
                    />
                    </Link>
                </div>
                <div className="dash2">
                    <div className="dash4">
                    <MyChart feature={35} ChartLabel="CALCIUM" />
                    </div>
                    <div className='dash3'>

                    <HowToImproveButton pagelink="/mynutrients/calcium"/>
                    </div>
                </div>
            </div>
            {/* <div className="border-b border-blue-900 my-4"></div>
            <div className='relative'>
                <div className="dash1" style={{opacity:0.6}}>
                    <div>
                        <Tooltip
                        text="Iron deficiency can cause a condition known as iron deficiency anemia which can bring about several symptoms such as fatigue, irritability, pale skin, weakness, chest pain, dizziness, and shortness of breath. Hemoglobin, a protein present in red blood cells responsible for carrying oxygen throughout the body, requires iron for its production. Inadequate iron levels lead to insufficient hemoglobin production causing anemia and its associated symptoms. Besides anemia, iron deficiency can also weaken your immune system and affect cognitive and motor function. Therefore, maintaining appropriate iron levels through diet or supplementation is critical to prevent these negative health consequences."
                        />
                    </div>
                    <div className="pl-5 pr-8">
                        <Link to={"/mynutrients/iron"} className="">
                        <HealthnessCard
                            feature={36}
                            reconID="Fe"
                            icon={FeIco}
                            vital="IRON"
                            textColor="#1c66bb"
                        />
                        </Link>
                    </div>
                    <div className="dash2">
                        <div className="dash4">
                        <MyChart feature={36} ChartLabel="IRON" />
                        </div>
                        <div className='dash3'>

                        <HowToImproveButton pagelink="/mynutrients/iron"/>
                        </div>
                    </div>
                </div>
                <div style={{position: "absolute", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(240,240,240,0.8)", display: "flex", justifyContent: "center", alignItems: "center",fontWeight:"bold"}}>
                    Update in progress
                </div>
            </div> */}
            
            <div className="border-b border-blue-900 my-4"></div>
            <div className="dash1">
                <div>
                    <Tooltip
                    text="Phosphorus plays a crucial role in various bodily functions, such as bone formation, cell growth, metabolism, and kidney function. Insufficient phosphorus intake can cause several health issues, including weakened bones, loss of appetite, nerve damage, and anemia. In children, it can lead to rickets, while adults may experience osteomalacia, which causes softening and weakening of the bones. Additionally, low levels of phosphorus can result in muscle weakness, fatigue, and breathing difficulties."
                    />
                </div>
                <div className="pl-5 pr-8">
                    <Link to={"/mynutrients/phosphorous"} className="">
                    <HealthnessCard
                        feature={37}
                        reconID="P"
                        icon={PhIco}
                        vital="PHOSPHOROUS"
                        textColor="#1c66bb"
                    />
                    </Link>
                </div>
                <div className="dash2">
                    <div className="dash4">
                    <MyChart feature={37} ChartLabel="PHOSPHOROUS" />
                    </div>
                    <div className='dash3'>

                    <HowToImproveButton pagelink="/mynutrients/phosphorous"/>
                    </div>
                </div>
            </div>
            <BackToMenu pagelink="/myhealth" menuPage="Back to My Health"/>
            <BackToPreviousPage pagelink="/dashboard" menuPage="Back to My Total Report"/>
        </div>
    </div>
  )
}

export default MyNutrients