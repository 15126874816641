import React from "react";
import Icon1 from "../../../assets/Skin health/Skinhealthmenurolldown1.png";
import Icon2 from "../../../assets/Skin health/Skinhealthmenurolldown2.png";
import "react-circular-progressbar/dist/styles.css";
import ScorePage from "../../common/ScorePage";
import vitalIco from '../../../assets/ICON_CLEANLINESS.png'

const Cleansers = () => {
    return (
      <>
      <ScorePage
        toolTipText="To keep your skin looking healthy and radiant, it's important to regularly wash your face to remove dirt and bacteria from the surface. This process, also known as cleansing, is essential for allowing anti-aging products and treatments to work effectively. Additionally, cleansing helps to maintain proper pore size, encourages hydration, and prevents the overproduction of oils."
        feature={45}
        reconID="CLEANSERS"
        icon={vitalIco}
        vital="CLEANLINESS"
        textColor="#49197b"
        bgColor="#d4c5df"
        Icon2={Icon1}
        Icon3={Icon2}
        Icon4={Icon2}
        prevpagelink="/myskinhealth"
        prevpagename="Back to My Skin Health"
      />
    </>
    );
}
 
export default Cleansers;