import React from "react";
import Icon2 from "../../../assets/Healthiness/01.png";
import Icon3 from '../../../assets/Healthiness/fitness.png'
import Icon4 from '../../../assets/Healthiness/supplement.png'
import "react-circular-progressbar/dist/styles.css";
import ScorePage from "../../common/ScorePage";
import PolicoIco from '../../../assets/Healthiness/mynutrients/ICON_POILICOSANOL.png'

const NutriPsa = () => {
    return (
      <>
      <ScorePage
        toolTipText='Policosanol is a natural supplement made from sugar cane, with potential benefits that include: Reducing "bad" cholesterol: Research indicates that policosanol can lower levels of LDL cholesterol. Preventing heart disease: By lowering cholesterol, policosanol may help reduce the risk of heart disease. Blood sugar control: Studies have suggested that policosanol can improve insulin sensitivity and lower blood sugar levels. Improving athletic performance: Some evidence suggests that policosanol can increase endurance and performance in athletes, but more research is needed to confirm these benefits and determine the appropriate dose and duration of supplementation. It is important to consult with a healthcare professional before taking any supplement.'
        feature={41}
        reconID="POLICOSANOL"
        icon={PolicoIco}
        vital="POLICOSANOL"
        textColor="#5f89d7"
        bgColor="#cbddf1"
        Icon2={Icon2}
        Icon3={Icon3}
        Icon4={Icon4}
        prevpagelink="/mynutrients"
        prevpagename="Back to My Nutrients"
      />
    </>
    );
}
 
export default NutriPsa;