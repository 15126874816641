import React from "react";
import HealthnessCard from "./card";
import MyChart from "../main/dashboard/mychart2";
import { fetchLatestScore } from "../firebase/scans";
import { useState, useEffect } from "react";
import BackToPreviousPage from "./BackToPreviousPage";
import BackToMenu from "./BackToMenu";
import backIcon from "../../assets/Healthiness/pushbutton_come back - one page.png";
import { Link } from "react-router-dom";
import styles from "./tooltip.module.css";

function Tooltip(props) {
  return (
    <div className="md:text-end md:-mr-24 md:mt-20 md:mb-0 mb-5 text-center">
      <div className={styles.tooltip}>
        {/* Hover over me */}
        <span>
          <i className="fa fa-info-circle text-blue-800" aria-hidden="true"></i>
        </span>
        <span
          className={`${styles.tooltiptext} md:w-80 w-60 bg-white text-justify `}
        >
          {props.text}
        </span>
      </div>
    </div>
  );
}

function ScorePage(props) {
  const [lifestyle, setLifeStyle] = useState("");
  const [fitness, setFitness] = useState("");
  const [supplement, setSupplement] = useState("");
  const setLatestData = async () => {
    let resp = await fetchLatestScore(props.feature, props.reconID);
    if (resp.score !== 0) {
      setLifeStyle(resp.recommend.lifestyle);
      setFitness(resp.recommend.fitness);
      setSupplement(resp.recommend.supplement);
    } else {
      setLifeStyle("");
      setSupplement("");
      setFitness("");
    }
  };
  useEffect(() => {
    setLatestData();
  }, [props]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      style={{ backgroundColor: `${props.bgColor}` }}
      className=" flex flex-col md:flex-row"
    >
      <div className="grow md:w-3/4 p-4">
        <div className="flex md:flex-row flex-col">
          <div>
            <Tooltip
              text={props.toolTipText}
            />
          </div>
          <div className="pl-5 pr-8 mx-auto my-auto">
            
            <HealthnessCard
              feature={props.feature}
              reconID={props.reconID}
              icon={props.icon}
              vital={props.vital}
              textColor={props.textColor}
            />
          </div>
          <div className="md:w-2/3 w-full mx-auto my-auto p-4">
            <div className="mt-3  w-full ">
              <MyChart feature={props.feature} ChartLabel={props.vital} />
            </div>
          </div>
        </div>
        <div className="flex flex-row mb-1 mx-auto my-auto p-4 ">
          <div className="flex-col grow md:mx-0 px-0 mt-3 border-none rounded-lg bg-white">
            <div
              className=" text-white font-bold rounded-t-lg p-2 "
              style={{ backgroundColor: `${props.textColor}` }}
            >
              <center className="mx-auto">Considerations</center>
            </div>
            <div className="flex md:flex-row flex-col border-b ">
              <div className="md:px-10 md:py-3 md:w-1/5 p-2  mx-auto">
                <img src={props.Icon2} className="h-20" />
              </div>
              <div className="my-auto md:w-4/5 md:text-justify text-center">{lifestyle}</div>
            </div>
            <div className="flex md:flex-row flex-col border-b">
              <div className="md:px-10 md:py-3  md:w-1/5 p-2 mx-auto">
                <img src={props.Icon3} className="h-20" />
              </div>
              <div className="my-auto md:w-4/5 md:text-justify text-center">{fitness}</div>
            </div>
            <div className="flex md:flex-row flex-col border-b">
              <div className="md:px-10 md:py-3  md:w-1/5 p-2 mx-auto">
                <img src={props.Icon4} className="h-20" />
              </div>
              <div className="my-auto md:w-4/5 md:text-justify text-center">{supplement}</div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center my-auto mx-auto">
            <div className='flex p-4'>
                <Link to={props.prevpagelink} className="flex items-center mx-auto transition duration-200 transform hover:scale-110">
                  <img src={backIcon} className="h-9 w-9 mx-auto" />
                  <div className="mx-auto ml-3 text-sm">{props.prevpagename}</div>
                </Link>
            </div>
        </div>
      </div>
    </div>
  );
}

export default ScorePage;
