import React, { useRef, useState } from "react";
// import { useEffect } from "react";
// import {useNavigate} from "react-router-dom";
// import { fetchSubscriptionInfo } from "../firebase/firebase";
import "../report.css";
import Tooltip from "../common/Tooltip";
// import StressChart from "./StressChart";

export default function Vitals() {
  const hrRef = useRef(null);
  const brRef = useRef(null);
  const spo2Ref = useRef(null);
  //   const bpRef = useRef(null);
  //   const bcRef = useRef(null);
  //   const bsRef = useRef(null);

  const [hr, setHR] = useState(null);
  const [br, setBR] = useState(null);
  const [spo2, setSPO2] = useState(null);
  //   const [bp,setBP] = useState(null);
  //   const [bs,setBS] = useState(null);
  //   const [bc,setBC] = useState(null);

  //   useEffect(()=>{
  //     const intervalID = setInterval(()=>{
  //       if (hrRef.current != null){
  //         setHR(hrRef.current.value);
  //         setBR(brRef.current.value);
  //         setSPO2(spo2Ref.current.value);
  //         setBP(bpRef.current.value);
  //         setBS(bsRef.current.value);
  //         setBC(bcRef.current.value);
  //       }
  //     },1000)
  //     return ()=> clearInterval(intervalID)
  //   },[hrRef])

  //   const [subType,setSubType] = useState(true);
  //   useEffect(()=>{
  //     checkSubscriptionType();
  //   },[]);
  //   const checkSubscriptionType = async ()=>{
  //     let scanInfo = await fetchSubscriptionInfo();
  //     // console.log('Scan info : ',scanInfo)
  //     if (scanInfo.status){
  //       if (scanInfo.subscriptionType == 2){ // this is to hide the StressChartComponent if the Vital Signs Package, if others by default we show StressChartComponent
  //         setSubType(false);
  //       }else{
  //         setSubType(true);
  //       }
  //     }
  //   }
  return (
    <>
      <div className="flex md:flex-col flex-row md:space-3" style={{ pointerEvents: "auto", marginBottom: "50px" }}>
        <div className="flex flex-row flex-wrap space-y-2 md:space-x-1 mx-auto">
          <div className="group flex relative w-full justify-end my-auto">
            {/* <span className=" text-white mr-5">
              <i
                className="fa fa-info-circle text-blue-800 w-10"
                aria-hidden="true"
              ></i>
            </span>
            <div
              className="group-hover:opacity-100 transition-opacity bg-blue-400 px-1 w-full  text-gray-100 rounded-md absolute left-1/2 
    -translate-x-1/2 translate-y-1/2 opacity-0  mx-auto"
            >
              Here are the 3 major vitals including Heart rate, Breathing rate,
              and SpO2. The normal range and the bias are shown below the
              reading values.
            </div> */}
            {/* <Tooltip
              text="Here are the 3 major vitals including Heart rate, Breathing rate,
              and SpO2. The normal range and the bias are shown below the
              reading values."
            /> */}
          </div>
          <div
            href="#"
            className="md:mt-2 ml-1 md:ml-0 max-w-sm p-3 md:w-52 w-44 shadow-blue-300 bg-white border border-gray-200 rounded-lg shadow-lg flex flex-col"
          >
            <div className="text-blue-400 mx-auto font-bold">HEART RATE</div>
            <div>
              <input id="hr_index" hidden></input>
            </div>
            <input
              ref={hrRef}
              type="text"
              readOnly
              id="heart_rate"
              className="mx-auto w-14 font-black text-lg border-none hover:border-none "
            ></input>
            <div className="mx-auto text-blue-500">+/- 5 b.p.m</div>
          </div>
          <div
            href="#"
            className="max-w-sm ml-1 p-3 md:w-52 w-44 shadow-blue-300 bg-white border border-gray-200 rounded-lg shadow-lg flex flex-col"
          >
            <div className="text-blue-400 mx-auto font-bold">
              BREATHING RATE
            </div>
            <div>
              <input id="br_index" hidden></input>
            </div>
            <input
              ref={brRef}
              type="text"
              readOnly
              id="resp_rate"
              className="mx-auto w-14 font-black text-lg border-none hover:border-none "
            ></input>
            <div className="mx-auto text-blue-500">+/- 5 b.p.m</div>
          </div>
          <div
            href="#"
            className="max-w-sm p-3 md:w-52 w-44 shadow-blue-300 bg-white border border-gray-200 rounded-lg shadow-lg flex flex-col"
          >
            <div className="text-blue-400 mx-auto font-bold">SPO2 RATE</div>
            <div>
              <input id="spo2_index" hidden></input>
            </div>
            <input
              ref={spo2Ref}
              type="text"
              readOnly
              id="spo2"
              className="mx-auto w-14 font-black text-lg border-none hover:border-none "
            ></input>
            <div className="mx-auto text-blue-500">+/- 2 %</div>
          </div>
        </div>
        {/* end */}
        {/* <div className="flex flex-row flex-wrap space-y-2 space-x-1 mx-auto">
          <div
            href="#"
            className="md:mt-2 max-w-sm p-3 md:w-52 w-44 shadow-blue-300 bg-white border border-gray-200 rounded-lg shadow-lg flex flex-col"
          >
            <div className="text-blue-400 mx-auto font-bold">
              Blood Pressure
            </div>
            <div>
              <input id="bp_index" hidden></input>
            </div>
            <input
              ref={bpRef}
              type="text"
              readOnly
              id="blood_pressure"
              className="mx-auto w-24 font-black text-lg border-none hover:border-none "
            ></input>

            <div className="mx-auto text-blue-500">+/- 15 mmHg</div>
          </div>
          <div
            href="#"
            className="max-w-sm p-3 md:w-52 w-44 shadow-blue-300 bg-white border border-gray-200 rounded-lg shadow-lg flex flex-col"
          >
            <div className="text-blue-400 mx-auto font-bold">Blood Sugar</div>
            <div>
              <input id="bs_index" hidden></input>
            </div>
            <input
              ref={bsRef}
              type="text"
              readOnly
              id="bs_rate"
              className="mx-auto w-16 font-black text-lg border-none hover:border-none "
            ></input>

            <div className="mx-auto text-blue-500">+/- 1 mmol/L</div>
          </div>
          <div
            href="#"
            className="max-w-sm p-3 md:w-52 w-44 shadow-blue-300 bg-white border border-gray-200 rounded-lg shadow-lg flex flex-col"
          >
            <div className="text-blue-400 mx-auto font-bold">
              Blood Cholesterol
            </div>
            <div>
              <input id="bc_index" hidden></input>
            </div>
            <input
              ref={bcRef}
              type="text"
              readOnly
              id="chol_rate"
              className="mx-auto w-16 font-black text-lg border-none hover:border-none "
            ></input>

            <div className="mx-auto text-blue-500">+/- 18 mg/dL</div>
          </div>
        </div> */}
        {/* <input hidden id="diag_value"></input>
        <input hidden id="diagnosis"></input>
        <input hidden id="check_hr"></input>
        <input hidden id="check_br"></input>
        <input hidden id="check_spo2"></input>
        <input hidden id="check_bp"></input>
        <input hidden id="check_bs"></input>
        <input hidden id="check_bc"></input> */}
        <br></br>
      </div>
      {/* <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
      {
        subType
        ?( 
            <>
              <StressChart hr={hr} br={br} spo2={spo2} bp={bp} bs={bs} bc={bc} />
            </>
         
        )
        : <div></div>
      } */}
    </>
  );
}
