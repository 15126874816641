import React from "react";
import Icon2 from "../../../assets/Healthiness/01.png";
import Icon3 from '../../../assets/Healthiness/fitness.png'
import Icon4 from '../../../assets/Healthiness/supplement.png'
import "react-circular-progressbar/dist/styles.css";
import ScorePage from "../../common/ScorePage";
import RespIcon from "../../../assets/Healthiness/myvital/ICONS/ICON_RESPERATORY_RATE.png"

const BreathingRate = () => {
  return (
    <>
      <ScorePage
        toolTipText="The breathing rate is the number of breaths a person takes per minute. A high or low breathing rate may be a sign that an underlying issue is present. Common causes of an increased breathing rate include anxiety, fever, respiratory diseases, heart diseases, and dehydration. Common causes of a low breathing rate include drug overdoses, obstructive sleep apnea, and head injuries."
        feature={4}
        reconID="RR"
        icon={RespIcon}
        vital="BREATHING RATE"
        textColor="#5f95d7"
        bgColor="#d2e6f8"
        Icon2={Icon2}
        Icon3={Icon3}
        Icon4={Icon4}
        prevpagelink="/myvitals"
        prevpagename="Back to My Vitals"
      />
    </>
  );
};

export default BreathingRate;
