import React from "react";
import Icon2 from "../../../assets/Healthiness/01.png";
import Icon3 from '../../../assets/Healthiness/fitness.png'
import Icon4 from '../../../assets/Healthiness/supplement.png'
import "react-circular-progressbar/dist/styles.css";
import ScorePage from "../../common/ScorePage";
import FatigueIco from '../../../assets/wellness/ICON_FATIGUE_LEVEL.png'

const FitFatigue = () => {
  return (
    <>
      <ScorePage
        toolTipText="Fatigue is a persistent sensation of tiredness or weakness that can be physical, mental, or both. It can affect anyone and is a common experience for most adults. Fatigue can lead to various physical, mental, and emotional symptoms, such as chronic tiredness or sleepiness, headaches, dizziness, sore or aching muscles, muscle weakness, slowed reflexes and responses, impaired decision-making and judgement, and moodiness, including irritability."
        feature={13}
        reconID="FATIGUE"
        icon={FatigueIco}
        vital="FATIGUE LEVEL"
        textColor="#87bdc2"
        bgColor="#bcd7d3"
        Icon2={Icon2}
        Icon3={Icon3}
        Icon4={Icon4}
        prevpagelink="/myenergy"
        prevpagename="Back to My Energy"
      />
    </>
  );
};

export default FitFatigue;
