import React from "react";
import Icon2 from "../../../assets/Healthiness/01.png";
import Icon3 from '../../../assets/Healthiness/fitness.png'
import Icon4 from '../../../assets/Healthiness/supplement.png'
import "react-circular-progressbar/dist/styles.css";
import ScorePage from "../../common/ScorePage";
import StressIco from '../../../assets/wellness/ICON_STRESS-LEVEL.png'

const FitStress = () => {
    return (
      <>
      <ScorePage
        toolTipText="With the stress level feature, you can easily determine their current stress level by monitoring their heart-rate variability. Physical symptoms of stress may include aches and pains, chest pain or a feeling of a racing heart, exhaustion or difficulty sleeping, headaches, dizziness or shaking, high blood pressure, muscle tension or jaw clenching, stomach or digestive issues, and trouble with sexual activity. Stress can also lead to emotional and mental symptoms such as anxiety or irritability, depression, panic attacks, and sadness."
        feature={12}
        reconID="STRESS"
        icon={StressIco}
        vital="STRESS LEVEL"
        textColor="#afedf2"
        bgColor="#bcd7d3"
        Icon2={Icon2}
        Icon3={Icon3}
        Icon4={Icon4}
        prevpagelink="/myenergy"
        prevpagename="Back to My Energy"
      />
    </>
    );
}
 
export default FitStress;