import { auth,db } from "./main";
import { doc, setDoc, getDoc, collection, query, where, getDocs, updateDoc, addDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import moment from "moment-timezone";

const resultRef = collection(db,"p2_results");

const fetchRecommendation = async(docID)=>{
    let recommend = {
        lifestyle:"",
        fitness: "",
        supplement:""
    };
    let recommendResp = await new Promise((resolve,reject)=>{
        onAuthStateChanged(auth,async(user)=>{
            if(user){
                const recRef = doc(db,"Recommendations",docID);
                const recSnap = await getDoc(recRef);
                if (recSnap.exists()){
                    recommend = {
                        lifestyle: recSnap.data().col1,
                        fitness:recSnap.data().col2,
                        supplement:recSnap.data().col3
                    }
                }
            }
            resolve(recommend)
        })
    })
    recommend = recommendResp;
    return recommend
}

export const updateVerifyScanRecord = async()=>{
    let recordVerified = false;
    let updateScanResp = await new Promise((resolve,reject)=>{
        onAuthStateChanged(auth,async(user)=>{
            if(user){
                    const userRef = doc(db,"users",user.uid);
                    const userSnap = await getDoc(userRef);
                    if(userSnap.exists()){
                        let latestRecord = userSnap.data().resultDoc;
                        let docRef = doc(db,"p2_results",latestRecord);
                        try{
                            const snapShot = await getDoc(docRef);
                            if (snapShot.exists()){
                                await updateDoc(docRef,{
                                    verified:true,
                                })
                                await updateDoc(userRef,{
                                    prevDoc:latestRecord,
                                })
                                recordVerified = true;
                                await fetchAndUpdateDailyDataTable(snapShot.data())
                            }
                        }catch(e){
                            recordVerified = false;
                        }
                    }else{
                        recordVerified = false;
                    }
            }
            resolve(recordVerified)
        })
    })
    recordVerified = updateScanResp
    return recordVerified
}

export const fetchAndUpdateDailyDataTable = async(latestData)=>{
    let dailyRef = collection(db,"daily_data");

    let dailyQuery = query(dailyRef,where("userID","==",latestData.userID),where("date","==",latestData.date));

    let dailyDocSnapShot = await getDocs(dailyQuery);
    // console.log('Daily Doc Snapshot Len : ',dailyDocSnapShot.length)
    
    if (dailyDocSnapShot.docs.length != 0){
        let dailyDocRef = doc(db,"daily_data",dailyDocSnapShot.docs[0].id)
        let dailyDataLog = {
            hrScore:Number(averageValue([latestData.hrScore,dailyDocSnapShot.docs[0].data().hrScore]).toFixed(1)),
            rrScore:Number(averageValue([latestData.rrScore,dailyDocSnapShot.docs[0].data().rrScore]).toFixed(1)),
            spo2Score:Number(averageValue([latestData.spo2Score,dailyDocSnapShot.docs[0].data().spo2Score]).toFixed(1)),
            bpScore:Number(averageValue([latestData.bpScore,dailyDocSnapShot.docs[0].data().bpScore]).toFixed(1)),
            bsScore:Number(averageValue([latestData.bsScore,dailyDocSnapShot.docs[0].data().bsScore]).toFixed(1)),
            bcScore:Number(averageValue([latestData.bcScore,dailyDocSnapShot.docs[0].data().bcScore]).toFixed(1)),
            basicScore:Number(averageValue([latestData.basicScore,dailyDocSnapShot.docs[0].data().basicScore]).toFixed(1)),
            coreScore:Number(averageValue([latestData.coreScore,dailyDocSnapShot.docs[0].data().coreScore]).toFixed(1)),
            healthTipScore:Number(averageValue([latestData.healthTipScore,dailyDocSnapShot.docs[0].data().healthTipScore]).toFixed(1)),
            healthnessScore:Number(averageValue([latestData.healthnessScore,dailyDocSnapShot.docs[0].data().healthnessScore]).toFixed(1)),
            stressScore:Number(averageValue([latestData.stressScore,dailyDocSnapShot.docs[0].data().stressScore]).toFixed(1)),
            fatigueScore:Number(averageValue([latestData.fatigueScore,dailyDocSnapShot.docs[0].data().fatigueScore]).toFixed(1)),
            boneScore:Number(averageValue([latestData.boneScore,dailyDocSnapShot.docs[0].data().boneScore]).toFixed(1)),
            immuneScore:Number(averageValue([latestData.immuneScore,dailyDocSnapShot.docs[0].data().immuneScore]).toFixed(1)),
            glucoseMetabolismScore:Number(averageValue([latestData.glucoseMetabolismScore,dailyDocSnapShot.docs[0].data().glucoseMetabolismScore]).toFixed(1)),
            cholMetabolismScore:Number(averageValue([latestData.cholMetabolismScore,dailyDocSnapShot.docs[0].data().cholMetabolismScore]).toFixed(1)),
            o2MetabolismScore:Number(averageValue([latestData.o2MetabolismScore,dailyDocSnapShot.docs[0].data().o2MetabolismScore]).toFixed(1)),
            metabolismScore:Number(averageValue([latestData.metabolismScore,dailyDocSnapShot.docs[0].data().metabolismScore]).toFixed(1)),
            easternMedicineScore:Number(averageValue([latestData.easternMedicineScore,dailyDocSnapShot.docs[0].data().easternMedicineScore]).toFixed(1)),
            yinScore:Number(averageValue([latestData.yinScore,dailyDocSnapShot.docs[0].data().yinScore]).toFixed(1)),
            yangScore:Number(averageValue([latestData.yangScore,dailyDocSnapShot.docs[0].data().yangScore]).toFixed(1)),
            sensitivityScore:Number(averageValue([latestData.sensitivityScore,dailyDocSnapShot.docs[0].data().sensitivityScore]).toFixed(1)),
            circulationScore:Number(averageValue([latestData.circulationScore,dailyDocSnapShot.docs[0].data().circulationScore]).toFixed(1)),
            fitnessScore:Number(averageValue([latestData.fitnessScore,dailyDocSnapShot.docs[0].data().fitnessScore]).toFixed(1)),
            dietScore:Number(averageValue([latestData.dietScore,dailyDocSnapShot.docs[0].data().dietScore]).toFixed(1)),
            vitaminScore:Number(averageValue([latestData.vitaminScore,dailyDocSnapShot.docs[0].data().vitaminScore]).toFixed(1)),
            vitaBScore:Number(averageValue([latestData.vitaBScore,dailyDocSnapShot.docs[0].data().vitaBScore]).toFixed(1)),
            vitaB3Score:Number(averageValue([latestData.vitaB3Score,dailyDocSnapShot.docs[0].data().vitaB3Score]).toFixed(1)),
            vitaB9Score:Number(averageValue([latestData.vitaB9Score,dailyDocSnapShot.docs[0].data().vitaB9Score]).toFixed(1)),
            vitaB12Score:Number(averageValue([latestData.vitaB12Score,dailyDocSnapShot.docs[0].data().vitaB12Score]).toFixed(1)),
            vitaCScore:Number(averageValue([latestData.vitaCScore,dailyDocSnapShot.docs[0].data().vitaCScore]).toFixed(1)),
            vitaDScore:Number(averageValue([latestData.vitaDScore,dailyDocSnapShot.docs[0].data().vitaDScore]).toFixed(1)),
            vitaEScore:Number(averageValue([latestData.vitaEScore,dailyDocSnapShot.docs[0].data().vitaEScore]).toFixed(1)),
            mineralScore:Number(averageValue([latestData.mineralScore,dailyDocSnapShot.docs[0].data().mineralScore]).toFixed(1)),
            caScore:Number(averageValue([latestData.caScore,dailyDocSnapShot.docs[0].data().caScore]).toFixed(1)),
            mgScore:Number(averageValue([latestData.mgScore,dailyDocSnapShot.docs[0].data().mgScore]).toFixed(1)),
            feScore:Number(averageValue([latestData.feScore,dailyDocSnapShot.docs[0].data().feScore]).toFixed(1)),
            phScore:Number(averageValue([latestData.phScore,dailyDocSnapShot.docs[0].data().phScore]).toFixed(1)),
            probioticsScore:Number(averageValue([latestData.probioticsScore,dailyDocSnapShot.docs[0].data().probioticsScore]).toFixed(1)),
            lTheanineScore:Number(averageValue([latestData.lTheanineScore,dailyDocSnapShot.docs[0].data().lTheanineScore]).toFixed(1)),
            policoSanolScore:Number(averageValue([latestData.policoSanolScore,dailyDocSnapShot.docs[0].data().policoSanolScore]).toFixed(1)),
            omega3Score:Number(averageValue([latestData.omega3Score,dailyDocSnapShot.docs[0].data().omega3Score]).toFixed(1)),
            redYeastRice:Number(averageValue([latestData.redYeastRice,dailyDocSnapShot.docs[0].data().redYeastRice]).toFixed(1)),
            nutrientScore:Number(averageValue([latestData.nutrientScore,dailyDocSnapShot.docs[0].data().nutrientScore]).toFixed(1)),
            wellnessScore:Number(averageValue([latestData.wellnessScore,dailyDocSnapShot.docs[0].data().wellnessScore]).toFixed(1)),
            skinHealthScore:Number(averageValue([latestData.skinHealthScore,dailyDocSnapShot.docs[0].data().skinHealthScore]).toFixed(1)),
            cleanserScore:Number(averageValue([latestData.cleanserScore,dailyDocSnapShot.docs[0].data().cleanserScore]).toFixed(1)),
            moisturizerScore:Number(averageValue([latestData.moisturizerScore,dailyDocSnapShot.docs[0].data().moisturizerScore]).toFixed(1)),
            sunScreenScore:Number(averageValue([latestData.sunScreenScore,dailyDocSnapShot.docs[0].data().sunScreenScore]).toFixed(1)),
            exfoliantsScore:Number(averageValue([latestData.exfoliantsScore,dailyDocSnapShot.docs[0].data().exfoliantsScore]).toFixed(1)),
            antiAgingScore:Number(averageValue([latestData.antiAgingScore,dailyDocSnapShot.docs[0].data().antiAgingScore]).toFixed(1)),
            brighteningScore:Number(averageValue([latestData.brighteningScore,dailyDocSnapShot.docs[0].data().brighteningScore]).toFixed(1)),
            acneScore:Number(averageValue([latestData.acneScore,dailyDocSnapShot.docs[0].data().acneScore]).toFixed(1)),
            soothingScore:Number(averageValue([latestData.soothingScore,dailyDocSnapShot.docs[0].data().soothingScore]).toFixed(1)),
        }
        await updateDoc(dailyDocRef,dailyDataLog)
        await fetchAndUpdateWeeklyDataTable(dailyDataLog,latestData.userID,latestData.date)
    }else{
        let dailyDataLog = {
            userID:latestData.userID,
            date:latestData.date,
            hrScore:latestData.hrScore,
            rrScore:latestData.rrScore,
            spo2Score:latestData.spo2Score,
            bpScore:latestData.bpScore,
            bsScore:latestData.bsScore,
            bcScore:latestData.bcScore,
            basicScore:latestData.basicScore,
            coreScore:latestData.coreScore,
            healthTipScore:latestData.healthTipScore,
            healthnessScore:latestData.healthnessScore,
            stressScore:latestData.stressScore,
            fatigueScore:latestData.fatigueScore,
            boneScore:latestData.boneScore,
            immuneScore:latestData.immuneScore,
            glucoseMetabolismScore:latestData.glucoseMetabolismScore,
            cholMetabolismScore:latestData.cholMetabolismScore,
            o2MetabolismScore:latestData.o2MetabolismScore,
            metabolismScore:latestData.metabolismScore,
            easternMedicineScore:latestData.easternMedicineScore,
            yinScore:latestData.yinScore,
            yangScore:latestData.yangScore,
            sensitivityScore:latestData.sensitivityScore,
            circulationScore:latestData.circulationScore,
            fitnessScore:latestData.fitnessScore,
            dietScore:latestData.dietScore,
            vitaminScore:latestData.vitaminScore,
            vitaBScore:latestData.vitaBScore,
            vitaB3Score:latestData.vitaB3Score,
            vitaB9Score:latestData.vitaB9Score,
            vitaB12Score:latestData.vitaB12Score,
            vitaCScore:latestData.vitaCScore,
            vitaDScore:latestData.vitaDScore,
            vitaEScore:latestData.vitaEScore,
            mineralScore:latestData.mineralScore,
            caScore:latestData.caScore,
            mgScore:latestData.mgScore,
            feScore:latestData.feScore,
            phScore:latestData.phScore,
            probioticsScore:latestData.probioticsScore,
            lTheanineScore:latestData.lTheanineScore,
            policoSanolScore:latestData.policoSanolScore,
            omega3Score:latestData.omega3Score,
            redYeastRice:latestData.redYeastRice,
            nutrientScore:latestData.nutrientScore,
            wellnessScore:latestData.wellnessScore,
            skinHealthScore:latestData.skinHealthScore,
            cleanserScore:latestData.cleanserScore,
            moisturizerScore:latestData.moisturizerScore,
            sunScreenScore:latestData.sunScreenScore,
            exfoliantsScore:latestData.exfoliantsScore,
            antiAgingScore:latestData.antiAgingScore,
            brighteningScore:latestData.brighteningScore,
            acneScore:latestData.acneScore,
            soothingScore:latestData.soothingScore,
        }
        let dailyDocRef = await addDoc(dailyRef,dailyDataLog)
        await fetchAndUpdateWeeklyDataTable(dailyDataLog,latestData.userID,latestData.date)
    }
}
const createObjectScoreDict = (wKey,score)=>{
    let dicts = {
        0:0,
        1:0,
        2:0,
        3:0,
        4:0,
        5:0,
        6:0
        // 7:0
    }
    dicts[wKey]=score
    return dicts
}

const updateObjectScoreDict = (wKey,score,dicts)=>{
    dicts[wKey]=score
    return dicts
}


export const fetchAndUpdateWeeklyDataTable = async (latestData,userID,date)=>{
    let dateResp = dayOfWeek(date); // returns start_date and end_date 
    let weeklyRef = collection(db,"weekly_data");
    let weeklyQuery = query(weeklyRef,where("userID","==",userID),where("startDate","==",dateResp.start))
    let weeklyDocSnapShot = await getDocs(weeklyQuery);
    let weekKey = dateResp.currDay;
    if ((weeklyDocSnapShot.docs.length != 0) ){
        // updating already present record 
        let weeklyDocRef = doc(db,'weekly_data',weeklyDocSnapShot.docs[0].id)
        let weeklyCurrentData = weeklyDocSnapShot.docs[0].data()
        let weeklyLog = {
            hrScore:updateObjectScoreDict(weekKey,latestData.hrScore,weeklyCurrentData.hrScore),
            rrScore:updateObjectScoreDict(weekKey,latestData.rrScore ,weeklyCurrentData.rrScore),
            spo2Score:updateObjectScoreDict(weekKey,latestData.spo2Score ,weeklyCurrentData.spo2Score),
            bpScore:updateObjectScoreDict(weekKey,latestData.bpScore ,weeklyCurrentData.bpScore),
            bsScore:updateObjectScoreDict(weekKey,latestData.bsScore  ,weeklyCurrentData.bsScore),
            bcScore:updateObjectScoreDict(weekKey,latestData.bcScore ,weeklyCurrentData.bcScore),
            basicScore: updateObjectScoreDict(weekKey,latestData.basicScore  ,weeklyCurrentData.basicScore),
            coreScore: updateObjectScoreDict(weekKey,latestData.coreScore   ,weeklyCurrentData.coreScore),
            healthTipScore:updateObjectScoreDict(weekKey,latestData.healthTipScore ,weeklyCurrentData.healthTipScore),
            healthnessScore: updateObjectScoreDict(weekKey,latestData.healthnessScore  ,weeklyCurrentData.healthnessScore),
            stressScore: updateObjectScoreDict(weekKey,latestData.stressScore  ,weeklyCurrentData.stressScore),
            fatigueScore: updateObjectScoreDict(weekKey,latestData.fatigueScore ,weeklyCurrentData.fatigueScore),
            boneScore:  updateObjectScoreDict(weekKey,latestData.boneScore  ,weeklyCurrentData.boneScore),
            immuneScore: updateObjectScoreDict(weekKey,latestData.immuneScore  ,weeklyCurrentData.immuneScore),
            glucoseMetabolismScore: updateObjectScoreDict(weekKey,latestData.glucoseMetabolismScore ,weeklyCurrentData.glucoseMetabolismScore),
            cholMetabolismScore: updateObjectScoreDict(weekKey,latestData.cholMetabolismScore,weeklyCurrentData.cholMetabolismScore),
            o2MetabolismScore:  updateObjectScoreDict(weekKey,latestData.o2MetabolismScore   ,weeklyCurrentData.o2MetabolismScore),
            metabolismScore:   updateObjectScoreDict(weekKey,latestData.metabolismScore  ,weeklyCurrentData.metabolismScore),
            easternMedicineScore: updateObjectScoreDict(weekKey,latestData.easternMedicineScore   ,weeklyCurrentData.easternMedicineScore),
            yinScore:    updateObjectScoreDict(weekKey,latestData.yinScore  ,weeklyCurrentData.yinScore),
            yangScore:    updateObjectScoreDict(weekKey,latestData.yangScore  ,weeklyCurrentData.yangScore),
            sensitivityScore: updateObjectScoreDict(weekKey,latestData.sensitivityScore   ,weeklyCurrentData.sensitivityScore),
            circulationScore: updateObjectScoreDict(weekKey,latestData.circulationScore  ,weeklyCurrentData.circulationScore),
            fitnessScore:  updateObjectScoreDict(weekKey,latestData.fitnessScore  ,weeklyCurrentData.fitnessScore),
            dietScore:   updateObjectScoreDict( weekKey,latestData.dietScore    ,weeklyCurrentData.dietScore),
            vitaminScore: updateObjectScoreDict( weekKey,latestData.vitaminScore ,weeklyCurrentData.vitaminScore),
            vitaBScore:   updateObjectScoreDict(weekKey,latestData.vitaBScore  ,weeklyCurrentData.vitaBScore),
            vitaB3Score: updateObjectScoreDict(weekKey,latestData.vitaB3Score ,weeklyCurrentData.vitaB3Score),
            vitaB9Score:  updateObjectScoreDict(weekKey,latestData.vitaB9Score  ,weeklyCurrentData.vitaB9Score),
            vitaB12Score: updateObjectScoreDict(weekKey,latestData.vitaB12Score  ,weeklyCurrentData.vitaB12Score),
            vitaCScore:  updateObjectScoreDict(weekKey,latestData.vitaCScore  ,weeklyCurrentData.vitaCScore),
            vitaDScore:  updateObjectScoreDict(weekKey,latestData.vitaDScore ,weeklyCurrentData.vitaDScore),
            vitaEScore:  updateObjectScoreDict(weekKey,latestData.vitaEScore  ,weeklyCurrentData.vitaEScore),
            mineralScore: updateObjectScoreDict(weekKey,latestData.mineralScore  ,weeklyCurrentData.mineralScore),
            caScore:    updateObjectScoreDict(weekKey,latestData.caScore ,weeklyCurrentData.caScore),
            mgScore:   updateObjectScoreDict(weekKey,latestData.mgScore  ,weeklyCurrentData.mgScore),
            feScore:   updateObjectScoreDict(weekKey,latestData.feScore ,weeklyCurrentData.feScore),
            phScore:   updateObjectScoreDict(weekKey,latestData.phScore ,weeklyCurrentData.phScore),
            probioticsScore: updateObjectScoreDict(               weekKey,latestData.probioticsScore ,weeklyCurrentData.probioticsScore),
            lTheanineScore: updateObjectScoreDict(               weekKey,latestData.lTheanineScore  ,weeklyCurrentData.lTheanineScore),
            policoSanolScore: updateObjectScoreDict(               weekKey,latestData.policoSanolScore  ,weeklyCurrentData.policoSanolScore),
            omega3Score:   updateObjectScoreDict(             weekKey,latestData.omega3Score   ,weeklyCurrentData.omega3Score),
            redYeastRice:   updateObjectScoreDict(             weekKey,latestData.redYeastRice  ,weeklyCurrentData.redYeastRice),
            nutrientScore: updateObjectScoreDict(               weekKey,latestData.nutrientScore ,weeklyCurrentData.nutrientScore),
            wellnessScore: updateObjectScoreDict(               weekKey,latestData.wellnessScore ,weeklyCurrentData.wellnessScore),
            skinHealthScore: updateObjectScoreDict(               weekKey,latestData.skinHealthScore ,weeklyCurrentData.skinHealthScore),
            cleanserScore:   updateObjectScoreDict(             weekKey,latestData.cleanserScore ,weeklyCurrentData.cleanserScore),
            moisturizerScore:  updateObjectScoreDict(              weekKey,latestData.moisturizerScore ,weeklyCurrentData.moisturizerScore),
            sunScreenScore:  updateObjectScoreDict(              weekKey,latestData.sunScreenScore  ,weeklyCurrentData.sunScreenScore),
            exfoliantsScore: updateObjectScoreDict(               weekKey,latestData.exfoliantsScore  ,weeklyCurrentData.exfoliantsScore),
            antiAgingScore:  updateObjectScoreDict(              weekKey,latestData.antiAgingScore ,weeklyCurrentData.antiAgingScore),
            brighteningScore: updateObjectScoreDict(               weekKey,latestData.brighteningScore ,weeklyCurrentData.brighteningScore),
            acneScore:     updateObjectScoreDict(           weekKey,latestData.acneScore  ,weeklyCurrentData.acneScore),
            soothingScore:   updateObjectScoreDict(             weekKey,latestData.soothingScore  ,weeklyCurrentData.soothingScore),
        }
        await updateDoc(weeklyDocRef,weeklyLog)
    }else{
        // create a weekly record 
        let weeklyLog = {
            userID:userID,
            startDate:dateResp.start,
            endDate:dateResp.end,
            hrScore:createObjectScoreDict(weekKey,latestData.hrScore),
            rrScore:createObjectScoreDict(weekKey,latestData.rrScore            ),
            spo2Score:createObjectScoreDict(weekKey,latestData.spo2Score            ),
            bpScore:createObjectScoreDict(weekKey,latestData.bpScore            ),
            bsScore:createObjectScoreDict(weekKey,latestData.bsScore            ),
            bcScore:createObjectScoreDict(weekKey,latestData.bcScore            ),
            basicScore: createObjectScoreDict(weekKey,latestData.basicScore            ),
            coreScore: createObjectScoreDict(weekKey,latestData.coreScore            ),
            healthTipScore:createObjectScoreDict(weekKey,latestData.healthTipScore            ),
            healthnessScore: createObjectScoreDict(weekKey,latestData.healthnessScore            ),
            stressScore: createObjectScoreDict(weekKey,latestData.stressScore            ),
            fatigueScore: createObjectScoreDict(weekKey,latestData.fatigueScore            ),
            boneScore:  createObjectScoreDict(weekKey,latestData.boneScore            ),
            immuneScore: createObjectScoreDict(weekKey,latestData.immuneScore            ),
            glucoseMetabolismScore: createObjectScoreDict(weekKey,latestData.glucoseMetabolismScore            ),
            cholMetabolismScore: createObjectScoreDict(weekKey,latestData.cholMetabolismScore            ),
            o2MetabolismScore:  createObjectScoreDict(weekKey,latestData.o2MetabolismScore            ),
            metabolismScore:   createObjectScoreDict(weekKey,latestData.metabolismScore            ),
            easternMedicineScore: createObjectScoreDict(weekKey,latestData.easternMedicineScore            ),
            yinScore:    createObjectScoreDict(weekKey,latestData.yinScore            ),
            yangScore:    createObjectScoreDict(weekKey,latestData.yangScore            ),
            sensitivityScore: createObjectScoreDict(weekKey,latestData.sensitivityScore            ),
            circulationScore: createObjectScoreDict(weekKey,latestData.circulationScore            ),
            fitnessScore:  createObjectScoreDict(weekKey,latestData.fitnessScore            ),
            dietScore:   createObjectScoreDict( weekKey,latestData.dietScore            ),
            vitaminScore: createObjectScoreDict( weekKey,latestData.vitaminScore            ),
            vitaBScore:   createObjectScoreDict(weekKey,latestData.vitaBScore            ),
            vitaB3Score: createObjectScoreDict(weekKey,latestData.vitaB3Score            ),
            vitaB9Score:  createObjectScoreDict(weekKey,latestData.vitaB9Score            ),
            vitaB12Score: createObjectScoreDict(weekKey,latestData.vitaB12Score            ),
            vitaCScore:  createObjectScoreDict(weekKey,latestData.vitaCScore            ),
            vitaDScore:  createObjectScoreDict(weekKey,latestData.vitaDScore            ),
            vitaEScore:  createObjectScoreDict(weekKey,latestData.vitaEScore            ),
            mineralScore: createObjectScoreDict(weekKey,latestData.mineralScore            ),
            caScore:    createObjectScoreDict(weekKey,latestData.caScore            ),
            mgScore:   createObjectScoreDict(weekKey,latestData.mgScore            ),
            feScore:   createObjectScoreDict(weekKey,latestData.feScore            ),
            phScore:   createObjectScoreDict(weekKey,latestData.phScore            ),
            probioticsScore: createObjectScoreDict(               weekKey,latestData.probioticsScore            ),
            lTheanineScore: createObjectScoreDict(               weekKey,latestData.lTheanineScore            ),
            policoSanolScore: createObjectScoreDict(               weekKey,latestData.policoSanolScore            ),
            omega3Score:   createObjectScoreDict(             weekKey,latestData.omega3Score            ),
            redYeastRice:   createObjectScoreDict(             weekKey,latestData.redYeastRice            ),
            nutrientScore: createObjectScoreDict(               weekKey,latestData.nutrientScore            ),
            wellnessScore: createObjectScoreDict(               weekKey,latestData.wellnessScore            ),
            skinHealthScore: createObjectScoreDict(               weekKey,latestData.skinHealthScore            ),
            cleanserScore:   createObjectScoreDict(             weekKey,latestData.cleanserScore            ),
            moisturizerScore:  createObjectScoreDict(              weekKey,latestData.moisturizerScore            ),
            sunScreenScore:  createObjectScoreDict(              weekKey,latestData.sunScreenScore            ),
            exfoliantsScore: createObjectScoreDict(               weekKey,latestData.exfoliantsScore            ),
            antiAgingScore:  createObjectScoreDict(              weekKey,latestData.antiAgingScore            ),
            brighteningScore: createObjectScoreDict(               weekKey,latestData.brighteningScore            ),
            acneScore:     createObjectScoreDict(           weekKey,latestData.acneScore            ),
            soothingScore:   createObjectScoreDict(             weekKey,latestData.soothingScore            ),
        }
        let weeklyDocRef = await addDoc(weeklyRef,weeklyLog)
        const userRef = doc(db,"users",latestData.userID);
        await updateDoc(userRef,{
            weeklyDoc:weeklyDocRef.id,
        })
    }
}

export const fetchDataForNav = async()=>{
    let respObj = {
        status:false,
        data:[],
        summary:0
    }
    let navResp = await new Promise((resolve,reject)=>{
        onAuthStateChanged(auth,async(user)=>{
            if(user){
                    const userRef = doc(db,"users",user.uid);
                    const userSnap = await getDoc(userRef);
                    if(userSnap.exists()){
                        let latestRecord = userSnap.data().prevDoc;
                        if(latestRecord.length !== 0){
                            const rRef = doc(db,"p2_results",latestRecord);
                            const rSnap = await getDoc(rRef);// set verified for the record to true
                            if (rSnap.exists()){
                                let summr = Number(averageValue([rSnap.data().healthnessScore,rSnap.data().wellnessScore,rSnap.data().skinHealthScore]).toFixed(1));
                                respObj = {
                                    status:true,
                                    data:[
                                        rSnap.data()
                                    ],
                                    summary:summr
                                }
                            }else{
                                respObj = {
                                    status:false,
                                    summary:0,
                                    data:[]
                                }
                            }
                        }else{
                            respObj = {
                                status:false,
                                summary:0,
                                data:[]
                            }
                        }
                    }else{
                        respObj = {
                            status:false,
                            summary:0,
                            data:[]
                        }
                    }
            }else{
                respObj = {
                    status:false,
                    summary:0,
                    data:[]
                }
            }
            resolve(respObj)
        })
    })
    respObj = navResp;
    return respObj
}

function getRandomInt() {
    return Math.floor(Math.random() * (5 - 1 + 1)) + 1;
}

/// prevDoc will be verified records and resultDoc will be unverified records
export const fetchLatestScore = async(feature,featureText)=>{ // edits to be made for healthiness , wellness and skin health main score
    let score = 0;
    let scoreString = "";
    let latestRecord = '';
    let scorePercent = 1;
    let latestScanTime = '';
    let reconScore = 0;
    let latestResp = await new Promise((resolve,reject)=>{
        onAuthStateChanged(auth,async(user)=>{
            if(user){
                    const userRef = doc(db,"users",user.uid);
                    const userSnap = await getDoc(userRef);
                    if(userSnap.exists()){
                        latestRecord = userSnap.data().prevDoc;
                        if(latestRecord.length !== 0){
                            const rRef = doc(db,"p2_results",latestRecord);
                            const rSnap = await getDoc(rRef);// set verified for the record to true
                            if (rSnap.exists()){
                                // console.log('HR Score : ',rSnap.data().hrScore);
                                // score = rSnap.data().hrScore;
                                latestScanTime = `${rSnap.data().date} ${rSnap.data().time}`
                                // if(feature === 0){
                                //     score = rSnap.data().healthnessScore;
                                // }else 
                                if(feature === 1){
                                    score = rSnap.data().basicScore;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent <50)){
                                        scoreString="Subpar";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Fair";
                                        reconScore = 2;
                                    }else {
                                        scoreString="Good";
                                        reconScore = 1;
                                    }
                                }else if (feature ===2){
                                    score = rSnap.data().hrScore;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent <50)){
                                        scoreString="Subpar";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Fair";
                                        reconScore = 2;
                                    }else {
                                        scoreString="Good";
                                        reconScore = 1;
                                    }
                                }else if (feature === 3){
                                    score = rSnap.data().spo2Score;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent <50)){
                                        scoreString="Subpar";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Fair";
                                        reconScore = 2;
                                    }else {
                                        scoreString="Good";
                                        reconScore = 1;
                                    }
                                }else if (feature === 4){
                                    score = rSnap.data().rrScore;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent <50)){
                                        scoreString="Subpar";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Fair";
                                        reconScore = 2;
                                    }else {
                                        scoreString="Good";
                                        reconScore = 1;
                                    }
                                }else if (feature === 5){
                                    score = rSnap.data().coreScore;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent <50)){
                                        scoreString="Subpar";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Fair";
                                        reconScore = 2;
                                    }else {
                                        scoreString="Good";
                                        reconScore = 1;
                                    }
                                }else if (feature === 6){
                                    score = rSnap.data().bpScore;
                                    scorePercent = Number(((score / 4.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent <=25)){
                                        scoreString="High";
                                        reconScore = 3;
                                    }else if ((scorePercent > 25)&&(scorePercent <=50)){
                                        scoreString="Elevated";
                                        reconScore = 2;
                                    }else if ((scorePercent > 50)&&(scorePercent <=75)){
                                        scoreString="Ideal";
                                        reconScore = 2;
                                    }
                                    else if (scorePercent > 75){
                                        scoreString="Low";
                                        reconScore = 1;
                                    }
                                }else if (feature === 7){
                                    score = rSnap.data().bsScore;
                                    scorePercent = Number(((score / 4.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent <=25)){
                                        scoreString="High";
                                        reconScore = 3;
                                    }else if ((scorePercent > 25)&&(scorePercent <=50)){
                                        scoreString="Elevated";
                                        reconScore = 2;
                                    }else if ((scorePercent > 50)&&(scorePercent <=75)){
                                        scoreString="Ideal";
                                        reconScore = 2;
                                    }
                                    else if (scorePercent > 75){
                                        scoreString="Low";
                                        reconScore = 1;
                                    }
                                }else if (feature === 8){
                                    score = rSnap.data().bcScore;
                                    scorePercent = Number(((score / 4.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent <=25)){
                                        scoreString="High";
                                        reconScore = 3;
                                    }else if ((scorePercent > 25)&&(scorePercent <=50)){
                                        scoreString="Elevated";
                                        reconScore = 2;
                                    }else if ((scorePercent > 50)&&(scorePercent <=75)){
                                        scoreString="Ideal";
                                        reconScore = 2;
                                    }
                                    else if (scorePercent > 75){
                                        scoreString="Low";
                                        reconScore = 1;
                                    }
                                }else if (feature === 9){
                                    score = rSnap.data().healthTipScore;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent <50)){
                                        scoreString="Subpar";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Fair";
                                        reconScore = 2;
                                    }else {
                                        scoreString="Good";
                                        reconScore = 1;
                                    }
                                }
                                // else if (feature === 10){
                                //     score = rSnap.data().wellnessScore;
                                // }
                                else if (feature === 11){
                                    score = rSnap.data().fitnessScore;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent <50)){
                                        scoreString="Subpar";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Fair";
                                        reconScore = 2;
                                    }else {
                                        scoreString="Good";
                                        reconScore = 1;
                                    }
                                }else if (feature === 12){
                                    score = rSnap.data().stressScore;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent <50)){
                                        scoreString="Subpar";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Fair";
                                        reconScore = 2;
                                    }else {
                                        scoreString="Good";
                                        reconScore = 1;
                                    }
                                }else if (feature === 13){
                                    score = rSnap.data().fatigueScore;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent  <50)){
                                        scoreString="Depleted";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Fair";
                                        reconScore = 2;
                                    }else {
                                        scoreString="Vibrant";
                                        reconScore = 1;
                                    }
                                }else if (feature === 14){
                                    score = rSnap.data().boneScore;
                                    console.log('Bone Score : ',score)
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    console.log('Bone scorePercent : ',scorePercent)
                                    if((scorePercent  < 50)){
                                        scoreString="Weak";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Fair";
                                        reconScore = 2;
                                    }else {
                                        scoreString="Strong";
                                        reconScore = 1;
                                    }
                                }else if (feature === 15){
                                    score = rSnap.data().immuneScore;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent  < 50)){
                                        scoreString="Weak";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Fair";
                                        reconScore = 2;
                                    }else {
                                        scoreString="Strong";
                                        reconScore = 1;
                                    }
                                }else if (feature === 16){
                                    score = rSnap.data().metabolismScore;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent <50)){
                                        scoreString="Subpar";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Fair";
                                        reconScore = 2;
                                    }else {
                                        scoreString="Good";
                                        reconScore = 1;
                                    }
                                }else if (feature === 17){
                                    score = rSnap.data().glucoseMetabolismScore;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent < 50)){
                                        scoreString="Low";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Optimal";
                                        reconScore = 2;
                                    }else{
                                        scoreString="High";
                                        reconScore = 1;
                                    }
                                }else if (feature === 18){
                                    score = rSnap.data().cholMetabolismScore;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent < 50)){
                                        scoreString="Low";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Optimal";
                                        reconScore = 2;
                                    }else{
                                        scoreString="High";
                                        reconScore = 1;
                                    }
                                }else if (feature === 19){
                                    score = rSnap.data().o2MetabolismScore;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent < 50)){
                                        scoreString="Low";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Optimal";
                                        reconScore = 2;
                                    }else{
                                        scoreString="Excellent";
                                        reconScore = 1;
                                    }
                                }else if (feature === 20){
                                    score = rSnap.data().easternMedicineScore;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent <50)){
                                        scoreString="Subpar";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Fair";
                                        reconScore = 2;
                                    }else {
                                        scoreString="Good";
                                        reconScore = 1;
                                    }
                                }else if (feature === 21){
                                    score = rSnap.data().yangScore;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent  < 50)){
                                        scoreString="Low";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Fair";
                                        reconScore = 2;
                                    }else {
                                        scoreString="Good";
                                        reconScore = 1;
                                    }
                                }else if (feature === 22){
                                    score = rSnap.data().sensitivityScore;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent  < 50)){
                                        scoreString="Depleted";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Fair";
                                        reconScore = 2;
                                    }else {
                                        scoreString="Vibrant";
                                        reconScore = 1;
                                    }
                                }else if (feature === 23){
                                    score = rSnap.data().circulationScore;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent < 50)){
                                        scoreString="Poor";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent < 75)){
                                        scoreString="Good";
                                        reconScore = 2;
                                    }else {
                                        scoreString="Excellent";
                                        reconScore = 1;
                                    }
                                }else if (feature === 24){
                                    score = rSnap.data().dietScore;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent <50)){
                                        scoreString="Subpar";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Fair";
                                        reconScore = 2;
                                    }else {
                                        scoreString="Good";
                                        reconScore = 1;
                                    }
                                }else if (feature === 25){
                                    score = rSnap.data().vitaminScore;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent <50)){
                                        scoreString="Subpar";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Fair";
                                        reconScore = 2;
                                    }else {
                                        scoreString="Good";
                                        reconScore = 1;
                                    }
                                }else if (feature === 26){
                                    score = rSnap.data().vitaBScore;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent  < 50)){
                                        scoreString="Deficient";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Satisfactory";
                                        reconScore = 2;
                                    }else{
                                        scoreString="Excellent";
                                        reconScore = 1;
                                    }
                                }else if (feature === 27){
                                    score = rSnap.data().vitaB3Score;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent  < 50)){
                                        scoreString="Deficient";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Satisfactory";
                                        reconScore = 2;
                                    }else{
                                        scoreString="Excellent";
                                        reconScore = 1;
                                    }
                                }else if (feature === 28){
                                    score = rSnap.data().vitaB9Score;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent  < 50)){
                                        scoreString="Deficient";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Satisfactory";
                                        reconScore = 2;
                                    }else{
                                        scoreString="Excellent";
                                        reconScore = 1;
                                    }
                                }else if (feature === 29){
                                    score = rSnap.data().vitaB12Score;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent  < 50)){
                                        scoreString="Deficient";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Satisfactory";
                                        reconScore = 2;
                                    }else{
                                        scoreString="Excellent";
                                        reconScore = 1;
                                    }
                                }else if (feature === 30){
                                    score = rSnap.data().vitaCScore;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent  < 50)){
                                        scoreString="Deficient";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Satisfactory";
                                        reconScore = 2;
                                    }else{
                                        scoreString="Excellent";
                                        reconScore = 1;
                                    }
                                }else if (feature === 31){
                                    score = rSnap.data().vitaDScore;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent  < 50)){
                                        scoreString="Deficient";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Satisfactory";
                                        reconScore = 2;
                                    }else{
                                        scoreString="Excellent";
                                        reconScore = 1;
                                    }
                                }else if (feature === 32){
                                    score = rSnap.data().vitaEScore;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent  < 50)){
                                        scoreString="Deficient";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Satisfactory";
                                        reconScore = 2;
                                    }else{
                                        scoreString="Excellent";
                                        reconScore = 1;
                                    }
                                }else if (feature === 33){
                                    score = rSnap.data().mineralScore;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent  < 50)){
                                        scoreString="Deficient";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Satisfactory";
                                        reconScore = 2;
                                    }else{
                                        scoreString="Excellent";
                                        reconScore = 1;
                                    }
                                }else if (feature === 34){
                                    score = rSnap.data().mgScore;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent  < 50)){
                                        scoreString="Deficient";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Satisfactory";
                                        reconScore = 2;
                                    }else{
                                        scoreString="Excellent";
                                        reconScore = 1;
                                    }
                                }else if (feature === 35){
                                    score = rSnap.data().caScore;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent  < 50)){
                                        scoreString="Deficient";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Satisfactory";
                                        reconScore = 2;
                                    }else{
                                        scoreString="Excellent";
                                        reconScore = 1;
                                    }
                                }else if (feature === 36){
                                    score = rSnap.data().feScore;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent  < 50)){
                                        scoreString="Deficient";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Satisfactory";
                                        reconScore = 2;
                                    }else{
                                        scoreString="Excellent";
                                        reconScore = 1;
                                    }
                                }else if (feature === 37){
                                    score = rSnap.data().phScore;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent  < 50)){
                                        scoreString="Deficient";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Satisfactory";
                                        reconScore = 2;
                                    }else{
                                        scoreString="Excellent";
                                        reconScore = 1;
                                    }
                                }else if (feature === 38){
                                    score = rSnap.data().nutrientScore;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent  < 50)){
                                        scoreString="Deficient";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Satisfactory";
                                        reconScore = 2;
                                    }else{
                                        scoreString="Excellent";
                                        reconScore = 1;
                                    }
                                }else if (feature === 39){
                                    score = rSnap.data().probioticsScore;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent  < 50)){
                                        scoreString="Deficient";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Satisfactory";
                                        reconScore = 2;
                                    }else{
                                        scoreString="Excellent";
                                        reconScore = 1;
                                    }
                                }else if (feature === 40){
                                    score = rSnap.data().lTheanineScore;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent  < 50)){
                                        scoreString="Deficient";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Satisfactory";
                                        reconScore = 2;
                                    }else{
                                        scoreString="Excellent";
                                        reconScore = 1;
                                    }
                                }else if (feature === 41){
                                    score = rSnap.data().policoSanolScore;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent  < 50)){
                                        scoreString="Deficient";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Satisfactory";
                                        reconScore = 2;
                                    }else{
                                        scoreString="Excellent";
                                        reconScore = 1;
                                    }
                                }else if (feature === 42){
                                    score = rSnap.data().omega3Score;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent  < 50)){
                                        scoreString="Deficient";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Satisfactory";
                                        reconScore = 2;
                                    }else{
                                        scoreString="Excellent";
                                        reconScore = 1;
                                    }
                                }else if (feature === 43){
                                    score = rSnap.data().redYeastRice;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent  < 50)){
                                        scoreString="Deficient";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Satisfactory";
                                        reconScore = 2;
                                    }else{
                                        scoreString="Excellent";
                                        reconScore = 1;
                                    }
                                }
                                // else if (feature === 44){
                                //     score = rSnap.data().skinHealthScore;
                                // }
                                else if (feature === 45){
                                    score = rSnap.data().cleanserScore;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent <50)){
                                        scoreString="Subpar";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Fair";
                                        reconScore = 2;
                                    }else {
                                        scoreString="Good";
                                        reconScore = 1;
                                    }
                                }else if (feature === 46){
                                    score = rSnap.data().moisturizerScore;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent <50)){
                                        scoreString="Subpar";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Fair";
                                        reconScore = 2;
                                    }else {
                                        scoreString="Good";
                                        reconScore = 1;
                                    }
                                }else if (feature === 47){
                                    score = rSnap.data().sunScreenScore;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent <50)){
                                        scoreString="Subpar";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Fair";
                                        reconScore = 2;
                                    }else {
                                        scoreString="Good";
                                        reconScore = 1;
                                    }
                                }else if (feature === 48){
                                    score = rSnap.data().exfoliantsScore;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent <50)){
                                        scoreString="Subpar";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Fair";
                                        reconScore = 2;
                                    }else {
                                        scoreString="Good";
                                        reconScore = 1;
                                    }
                                }else if (feature === 49){
                                    score = rSnap.data().antiAgingScore;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent <50)){
                                        scoreString="Subpar";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Fair";
                                        reconScore = 2;
                                    }else {
                                        scoreString="Good";
                                        reconScore = 1;
                                    }
                                }else if (feature === 50){
                                    score = rSnap.data().brighteningScore;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent <50)){
                                        scoreString="Subpar";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Fair";
                                        reconScore = 2;
                                    }else {
                                        scoreString="Good";
                                        reconScore = 1;
                                    }
                                }else if (feature === 51){
                                    score = rSnap.data().acneScore;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent <50)){
                                        scoreString="Subpar";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Fair";
                                        reconScore = 2;
                                    }else {
                                        scoreString="Good";
                                        reconScore = 1;
                                    }
                                }else {
                                    score = rSnap.data().soothingScore;
                                    scorePercent = Number(((score / 3.0) * 100).toFixed(1)) + getRandomInt();
                                    if (scorePercent >= 100){
                                        scorePercent = 100
                                    }
                                    if((scorePercent <50)){
                                        scoreString="Subpar";
                                        reconScore = 3;
                                    }else if ((scorePercent >= 50)&&(scorePercent <75)){
                                        scoreString="Fair";
                                        reconScore = 2;
                                    }else {
                                        scoreString="Good";
                                        reconScore = 1;
                                    }
                                }
                            }
                        }
                    }
            }
            let recID = `${featureText}_${Math.round(reconScore)}`;
            let recommend = await fetchRecommendation(recID);
            let scoreColor = ''
            if ((scorePercent >= 75)){
                scoreColor = '#33e307' // green
            }else if ((scorePercent >= 50)&&(scorePercent <75)){
                scoreColor = '#d1db04'
            }else{
                scoreColor = '#db0804'
            }
            console.log('Latest Scan Time : ',latestScanTime)
            // let timeString = moment(latestScanTime)
            // let formattedScanTime = timeString.format("MM/DD/YYYY") + " " + timeString.format("HH:mm A")
            // console.log('Formatted Latest Scan Time : ',formattedScanTime)
            // let resp = {score:score,recordID:latestRecord,scoreText:scoreString,scorePercentage:scorePercent,recommend:recommend,time:latestScanTime}
            resolve({score:score,recordID:latestRecord,scoreText:scoreString,scorePercentage:Math.round(scorePercent),recommend:recommend,time:latestScanTime,scoreColor:scoreColor})
        })
    })
    return latestResp
}

const calculateHealthinessRatio = (score)=>{// values 1.0 means 100 , 3.0 means 130 - ratio out of 130 
    let ratio = Number((((score - 1) / 2) * 30 + 100).toFixed(1));
    let text = "";
    let percentValue = Number(((score / 3.0) * 100).toFixed(1));
    let scoreColor = ""
    if ((percentValue < 50)){
        scoreColor = '#db0804'
        text = "Subpar";
    }else if ((percentValue >= 50) && (percentValue < 75)){
        scoreColor = '#d1db04'
        text = "Fair";
    }else {
        scoreColor = '#33e307'
        text = "Good";
    }
    return [ratio,text,percentValue,scoreColor]
}

const calculateWellnessRatio = (score)=>{ // values 1.0 means 50 , 3.0 means 80 - ratio out of 80 
    let ratio = Number((((score - 1) / 2) * 30 + 50).toFixed(1));
    let text = "";
    let percentValue = Number(((score / 3.0) * 100).toFixed(1));
    let scoreColor = ""
    if ((percentValue < 50)){
        scoreColor = '#db0804'
        text = "Subpar";
    }else if ((percentValue >= 50) && (percentValue < 75)){
        scoreColor = '#d1db04'
        text = "Fair";
    }else {
        scoreColor = '#33e307'
        text = "Good";
    }
    return [ratio,text,percentValue,scoreColor]
}

const calculateSkinHealthRatio = (score)=>{ // values 1.0 means 0 , 3.0 means 30 - ratio out of 30 
    let ratio = Number((((score - 1) / 2) * 30).toFixed(1));
    let text = "";
    let percentValue = Number(((score / 3.0) * 100).toFixed(1));
    let scoreColor = ""
    if ((percentValue < 50)){
        scoreColor = '#db0804'
        text = "Subpar";
    }else if ((percentValue >= 50) && (percentValue < 75)){
        scoreColor = '#d1db04'
        text = "Fair";
    }else {
        scoreColor = '#33e307'
        text = "Good";
    }
    return [ratio,text,percentValue,scoreColor]
}

export const fetchLatestMainScore = async()=>{ // edits to be made for healthiness , wellness and skin health main score
    let healthnessScore = -1;
    let wellnessScore = -1;
    let skinHealthScore = -1;
    let hscoreString = "";
    let wscoreString = "";
    let sscoreString = "";
    let latestRecord = '';
    let latestScanTime = '';
    let hPercent = 0;
    let wPercent = 0;
    let sPercent = 0;
    let hColor ="";
    let wColor ="";
    let sColor ="";

    let mainResp = await new Promise((resolve,reject)=>{
        onAuthStateChanged(auth,async(user)=>{
            if(user){
                    const userRef = doc(db,"users",user.uid);
                    const userSnap = await getDoc(userRef);
                    if(userSnap.exists()){
                        latestRecord = userSnap.data().prevDoc; // latest verified record
                        if(latestRecord.length !== 0){
                            const rRef = doc(db,"p2_results",latestRecord);
                            const rSnap = await getDoc(rRef);
                            if (rSnap.exists()){
                                latestScanTime = `${rSnap.data().date} ${rSnap.data().time}`
                                healthnessScore = rSnap.data().healthnessScore; 
                                let hRatio = calculateHealthinessRatio(healthnessScore);
                                healthnessScore = hRatio[0];
                                hscoreString = hRatio[1];
                                hPercent = hRatio[2];
                                wellnessScore = rSnap.data().wellnessScore;
                                let wRatio = calculateWellnessRatio(wellnessScore);
                                wellnessScore = wRatio[0];
                                wscoreString = wRatio[1];
                                wPercent = wRatio[2];
                                skinHealthScore = rSnap.data().skinHealthScore;
                                let sRatio = calculateSkinHealthRatio(skinHealthScore);
                                skinHealthScore = sRatio[0];
                                sscoreString = sRatio[1];
                                sPercent = sRatio[2];
                                hColor = hRatio[3]
                                wColor = wRatio[3]
                                sColor = sRatio[3]
                            }
                        }
                    }
                
            }
            // console.log({hScore:healthnessScore,hColor:hColor,wColor:wColor,sColor:sColor,wScore:wellnessScore,sScore:skinHealthScore,hPercent:hPercent,wPercent:wPercent,sPercent:sPercent,recordID:latestRecord,hText:hscoreString,wText:wscoreString,sText:sscoreString,time:latestScanTime})
            // let resp = {hScore:healthnessScore,wScore:wellnessScore,sScore:skinHealthScore,hPercent:hPercent,wPercent:wPercent,sPercent:sPercent,recordID:latestRecord,hText:hscoreString,wText:wscoreString,sText:sscoreString,time:latestScanTime}
            resolve({hScore:healthnessScore,hColor:hColor,wColor:wColor,sColor:sColor,wScore:wellnessScore,sScore:skinHealthScore,hPercent:hPercent,wPercent:wPercent,sPercent:sPercent,recordID:latestRecord,hText:hscoreString,wText:wscoreString,sText:sscoreString,time:latestScanTime})
        })
    })
    return mainResp
}

const dayOfWeek = (date) => {
    // Get the date object for the specified date
    const d = new Date(date);
  
    // Get the day of the week (0 = Sunday, 1 = Monday, etc.)
    const dateDay = d.getDay();
    // Calculate the start and end dates
    const startDate = new Date(d);
    startDate.setDate(startDate.getDate() + (dateDay === 0 ? 0 : -dateDay)); // 
    // startDate.setDate(startDate.getDate() - dateDay + (dateDay === 0 ? 6 : -1)); // 26 - 0 +6 = 32
    const endDate = new Date(d);
    endDate.setDate(endDate.getDate() + (dateDay === 6 ? 0 : 6 - dateDay));
  
    // Format the start and end dates as strings
    const startYear = startDate.getFullYear();
    const startMonth = (startDate.getMonth() + 1).toString().padStart(2, '0');
    const startDay = startDate.getDate().toString().padStart(2, '0');
    const endYear = endDate.getFullYear();
    const endMonth = (endDate.getMonth() + 1).toString().padStart(2, '0');
    const endDay = endDate.getDate().toString().padStart(2, '0');
    const startDateStr = `${startYear}-${startMonth}-${startDay}`;
    const endDateStr = `${endYear}-${endMonth}-${endDay}`;
  
    // Return the start and end dates, as well as the current day of the week
    return { start: startDateStr, end: endDateStr, currDay: dateDay };
};
  

function averageValue(arr){
    var total = 0;
    var count = 0;
    arr.forEach((item, index)=>{
        total+=item;
        count++;
    });
    return total/count;
}

const getGraphDataFromScoreDict = (scoreDict,use3Scoring=true,text_li=['Subpar',"Fair",'Good'])=>{
    let graph_data = []
    let daysList = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']
    let score_data = [];
    for (let key in scoreDict){
        let dicts = {}
        if (scoreDict[key] != 0){
            // dicts[daysList[key]]=scoreDict[key]
            let yPercent=0;
            // console.log('# scoring : ',use3Scoring)
            if (use3Scoring){

                yPercent = Math.round((scoreDict[key] / 3.0) * 100)
                dicts = {
                    x:daysList[key],
                    y:yPercent
                }
            }else{
                yPercent = Math.round((scoreDict[key] / 4.0) * 100)
                dicts = {
                    x:daysList[key],
                    y:yPercent
                }
            }
            graph_data.push(dicts)
            score_data.push(yPercent)
        }
    }
    let avg_score = Math.round(averageValue(score_data))
    let weekColor='';
    let scoreText = "";
    if(use3Scoring){
        if ((avg_score <50)){
            weekColor = "rgb(219, 61, 33)";
            scoreText=text_li[0]
        }else if ((avg_score >=50 ) && (avg_score < 75)){
            weekColor = "rgb(213, 219, 33)";
            scoreText=text_li[1]
        }else{
            weekColor = "rgb(61,219,33)";
            scoreText=text_li[2]
        }
    }else{
        if ((avg_score <=25)){
            weekColor = "rgb(219, 61, 33)";
            scoreText=text_li[0]
        }else if ((avg_score > 25 ) && (avg_score <= 50)){
            weekColor = "rgb(235, 158, 52)";
            scoreText=text_li[1]
        }else if ((avg_score >50 ) && (avg_score <= 75)){
            weekColor = "rgb(213, 219, 33)";
            scoreText=text_li[2]
        }else{
            weekColor = "rgb(61,219,33)";
            scoreText=text_li[3]
        }
    }
    
    // console.log('Average Score : ',avg_score)
    // console.log('Score Text : ',scoreText)
    let weeklyAvg = [{x:'Sunday',y:avg_score},{x:'Monday',y:avg_score},{x:'Tuesday',y:avg_score},{x:'Wednesday',y:avg_score},{x:'Thursday',y:avg_score},{x:'Friday',y:avg_score},{x:'Saturday',y:avg_score}]
    return [graph_data,weeklyAvg,weekColor,scoreText]
}

const getGraphDataFromMainScoreDict = (scoreDict,mode)=>{
    let graph_data = []
    let daysList = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']
    for (let key in scoreDict){
        let dicts = {}
        if (scoreDict[key] != 0){
            let scoreVal = 0;
            if (mode == '0'){ // healthness
                scoreVal = Math.round(calculateHealthinessRatio(scoreDict[key])[2])
            }else if (mode == '1'){ // wellness
                scoreVal = Math.round(calculateWellnessRatio(scoreDict[key])[2])
            }else{
                scoreVal = Math.round(calculateSkinHealthRatio(scoreDict[key])[2])
            }
            // dicts[daysList[key]]=scoreVal
            dicts = {
                x: daysList[key],
                y: scoreVal
            }
            graph_data.push(dicts)
        }
    }
    return graph_data
}

export const fetchWeeklyData = async(feature,chartLabel)=>{ // make function general and make function accept date value for filtering as well
    let graph_resp=[]
    let graphResp = await new Promise((resolve,reject)=>{
        onAuthStateChanged(auth,async(user)=>{
            if(user){
                    let userDocRef = doc(db,'users',user.uid)
                    let userSnapShot = await getDoc(userDocRef)
                    let weekDocID = userSnapShot.data().weeklyDoc
                    let weekDocRef = doc(db,'weekly_data',weekDocID)
                    let weekSnapShot = await getDoc(weekDocRef)
                    let weeklyData = weekSnapShot.data()
                    if(feature === 0){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.healthnessScore);
                    }else if(feature === 1){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.basicScore);
                    }else if (feature ===2){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.hrScore);
                    }else if (feature === 3){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.spo2Score);
                    }else if (feature === 4){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.rrScore);
                    }else if (feature === 5){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.coreScore);
                    }else if (feature === 6){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.bpScore,false,["High","Elevated","Ideal","Low"]);
                    }else if (feature === 7){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.bsScore,false,["High","Elevated","Ideal","Low"]);
                    }else if (feature === 8){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.bcScore,false,["High","Elevated","Ideal","Low"]);
                    }else if (feature === 9){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.healthTipScore);
                    }else if (feature === 10){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.wellnessScore);
                    }else if (feature === 11){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.fitnessScore);
                    }else if (feature === 12){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.stressScore);
                    }else if (feature === 13){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.fatigueScore,true,["Depleted","Fair","Vibrant"]);
                    }else if (feature === 14){
                        // console.log('In Bone Score Weekly')
                        graph_resp = getGraphDataFromScoreDict(weeklyData.boneScore,true,["Weak","Fair","String"]);
                    }else if (feature === 15){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.immuneScore,true,["Weak","Fair","String"]);
                    }else if (feature === 16){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.metabolismScore);
                    }else if (feature === 17){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.glucoseMetabolismScore,true,["Low","Optimal","High"]);
                    }else if (feature === 18){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.cholMetabolismScore,true,["Low","Optimal","High"]);
                    }else if (feature === 19){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.o2MetabolismScore,true,["Low","Optimal","Excellent"]);
                    }else if (feature === 20){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.easternMedicineScore);
                    }else if (feature === 21){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.yangScore,true,["Low","Fair","Good"]);
                    }else if (feature === 22){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.sensitivityScore,true,["Depleted","Fair","Vibrant"]);
                    }else if (feature === 23){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.circulationScore,true,["Poor","Good","Excellent"]);
                    }else if (feature === 24){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.dietScore);
                    }else if (feature === 25){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.vitaminScore);
                    }else if (feature === 26){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.vitaBScore,true,["Deficient","Satisfactory","Excellent"]);
                    }else if (feature === 27){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.vitaB3Score,true,["Deficient","Satisfactory","Excellent"]);
                    }else if (feature === 28){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.vitaB9Score,true,["Deficient","Satisfactory","Excellent"]);
                    }else if (feature === 29){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.vitaB12Score,true,["Deficient","Satisfactory","Excellent"]);
                    }else if (feature === 30){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.vitaCScore,true,["Deficient","Satisfactory","Excellent"]);
                    }else if (feature === 31){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.vitaDScore,true,["Deficient","Satisfactory","Excellent"]);
                    }else if (feature === 32){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.vitaEScore,true,["Deficient","Satisfactory","Excellent"]);
                    }else if (feature === 33){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.mineralScore,true,["Deficient","Satisfactory","Excellent"]);
                    }else if (feature === 34){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.mgScore,true,["Deficient","Satisfactory","Excellent"]);
                    }else if (feature === 35){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.caScore,true,["Deficient","Satisfactory","Excellent"]);
                    }else if (feature === 36){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.feScore,true,["Deficient","Satisfactory","Excellent"]);
                    }else if (feature === 37){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.phScore,true,["Deficient","Satisfactory","Excellent"]);
                    }else if (feature === 38){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.nutrientScore,true,["Deficient","Satisfactory","Excellent"]);
                    }else if (feature === 39){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.probioticsScore,true,["Deficient","Satisfactory","Excellent"]);
                    }else if (feature === 40){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.lTheanineScore,true,["Deficient","Satisfactory","Excellent"]);
                    }else if (feature === 41){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.policoSanolScore,true,["Deficient","Satisfactory","Excellent"]);
                    }else if (feature === 42){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.omega3Score,true,["Deficient","Satisfactory","Excellent"]);
                    }else if (feature === 43){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.redYeastRice,true,["Deficient","Satisfactory","Excellent"]);
                    }else if (feature === 44){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.skinHealthScore);
                    }else if (feature === 45){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.cleanserScore);
                    }else if (feature === 46){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.moisturizerScore);
                    }else if (feature === 47){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.sunScreenScore);
                    }else if (feature === 48){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.exfoliantsScore);
                    }else if (feature === 49){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.antiAgingScore);
                    }else if (feature === 50){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.brighteningScore);
                    }else if (feature === 51){
                        graph_resp = getGraphDataFromScoreDict(weeklyData.acneScore);
                    }else{
                        graph_resp = getGraphDataFromScoreDict(weeklyData.soothingScore);
                    }
            }
            resolve(graph_resp)
        })
    })
    graph_resp=graphResp
    let weeklyAvg = graph_resp[1]
    let graphData = graph_resp[0]
    let weekColor = graph_resp[2]
    return [weeklyAvg,graphData,weekColor,graph_resp[3]]
}

export const fetchWeeklyMainData = async()=>{ 
    let graph_resp=[]
    let mainGraphResp = await new Promise((resolve,reject)=>{
        onAuthStateChanged(auth,async(user)=>{
            if(user){
                    let userDocRef = doc(db,'users',user.uid)
                    let userSnapShot = await getDoc(userDocRef)
                    let weekDocID = userSnapShot.data().weeklyDoc
                    let weekDocRef = doc(db,'weekly_data',weekDocID)
                    let weekSnapShot = await getDoc(weekDocRef)
                    let weeklyData = weekSnapShot.data()
                    let hgraphData = getGraphDataFromMainScoreDict(weeklyData.healthnessScore,'0')
                    let wgraphData = getGraphDataFromMainScoreDict(weeklyData.wellnessScore,'1')
                    let sgraphData = getGraphDataFromMainScoreDict(weeklyData.skinHealthScore,'2')
                    graph_resp = [hgraphData,wgraphData,sgraphData]
                    // console.log(graph_resp)
                }
            resolve(graph_resp)
        })
    })
    graph_resp=mainGraphResp
    let hgraphData = graph_resp[0]
    let wgraphData = graph_resp[1]
    let sgraphData = graph_resp[2]
    return [hgraphData,wgraphData,sgraphData]
}
