import React from "react";
import Icon2 from "../../../assets/Healthiness/01.png";
import Icon3 from '../../../assets/Healthiness/fitness.png'
import Icon4 from '../../../assets/Healthiness/supplement.png'
import "react-circular-progressbar/dist/styles.css";
import ScorePage from "../../common/ScorePage";
import ImmuneIcon from '../../../assets/Healthiness/mymajorhealth/ICON_IMMUNE.png'

const FitImmune = () => {
  return (
    <>
      <ScorePage
        toolTipText="The immune system is a complex network of organs, cells, and proteins that defends the body against infection while protecting the body's cells. Primary immunodeficiency Signs and symptoms can include Frequent and recurrent pneumonia, bronchitis, sinus infections, ear infections, meningitis, or skin infections. Inflammation and infection of internal organs. Blood disorders, such as low platelet count or anemia."
        feature={15}
        reconID="IMMUNE_FUNCTION"
        icon={ImmuneIcon}
        vital="IMMUNE"
        textColor="#70bfe5"
        bgColor="#c2d9e8"
        Icon2={Icon2}
        Icon3={Icon3}
        Icon4={Icon4}
        prevpagelink="/mymajorhealth"
        prevpagename="Back to My Major Health"
      />
    </>
  );
};

export default FitImmune;
