import React from "react";
import Icon2 from "../../../assets/Healthiness/01.png";
import Icon3 from '../../../assets/Healthiness/fitness.png'
import Icon4 from '../../../assets/Healthiness/supplement.png'
import "react-circular-progressbar/dist/styles.css";
import ScorePage from "../../common/ScorePage";
import CaIco from '../../../assets/Healthiness/mynutrients/ICON_CALCIUM.png'


const MineralCa = () => {
  
  return (
    <>
      <ScorePage
        toolTipText="The body needs calcium to ensure that muscles, nerves, and cells function properly. If calcium levels are low, the body will take calcium from bones, which can result in weakened bones and an increased likelihood of fractures. A lack of calcium can cause a range of health issues, including weakened teeth and bones, muscle cramps, and irregular heart rhythms. In more severe cases, a calcium deficiency can lead to osteoporosis, which is a condition that causes bones to become brittle and fragile."
        feature={35}
        reconID="Ca"
        icon={CaIco}
        vital="CALCIUM"
        textColor="#1c66bb"
        bgColor="#cbddf1"
        Icon2={Icon2}
        Icon3={Icon3}
        Icon4={Icon4}
        prevpagelink="/mynutrients"
        prevpagename="Back to My Nutrients"
      />
    </>
  );
};

export default MineralCa;
