import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo3.png";
import { fetchUserInfo, logOutWithFirebase } from "../firebase/auth";
import ModalCtx2 from "../store/modalcontext2";


function Nav() {
  const ctnx = useContext(ModalCtx2);

  const [fullName, setFullName] = useState("");
  const [profileName, setProfileName] = useState("");
  const [checkAffiliate, setAffiliate] = useState(false);
  // const [mobileView, setMobileView] = useState(false);
  // let [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const main = async () => {
    const res = await fetchUserInfo();
    setFullName(`${res.firstName} ${res.lastName}`);
    let profileName = `${res.firstName[0]} ${res.lastName[0]}`;
    setProfileName(profileName);
    setAffiliate(res.approvedAffiliate);
  };

  // useEffect(()=>{
  //   setWindowWidth(window.innerWidth)
  //   if (windowWidth >= 800){
  //     setMobileView(false)
  //   }else{
  //     setMobileView(true)
  //   }
  // },[windowWidth])

  useEffect(() => {
    main();
  }, []);
  const handleLogOut = async () => {
    await logOutWithFirebase();
    sessionStorage.clear();
    // navigate("/");
    window.location.href = "/login";
  };
  return (
    <>
      <nav className="bg-white shadow-lg text-black hidden ">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-5">
          <div className="md:mt-5">
            <a href="/dashboard" className="flex items-center">
              <img src={logo} className=" md:h-16 h-16 mx-auto" alt="MWR Logo" />
              {/* <span className="self-center text-2xl font-semibold whitespace-nowrap ">
                My Wellness Reader
              </span> */}
            </a>
          </div>
          <div className="flex items-center md:order-5 md:ml-20 md:mt-5">
            <button
              type="button"
              className="flex mr-3 text-sm bg-gray-800 rounded-full  md:mr-0 focus:ring-4 focus:ring-gray-300  transition duration-200 transform hover:scale-110"
              id="user-menu-button"
              aria-expanded="false"
              data-dropdown-toggle="user-dropdown"
              data-dropdown-placement="bottom"
            >
              <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full ">
                <span className="font-medium text-gray-600 ">
                  {profileName}
                </span>
              </div>
            </button>
            <div
              className="z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow "
              id="user-dropdown"
            >
              <div className="px-4 py-3">
                <span className="block text-sm text-gray-900 ">{fullName}</span>
                {/* <span className="block text-sm  text-gray-500 truncate ">
                  name@flowbite.com
                </span> */}
              </div>
              <ul className="py-2" aria-labelledby="user-menu-button">
                <li>
                  <a
                    href="/dashboard"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100  "
                    role="menuitem"
                  >
                    Dashboard
                  </a>
                </li>
                <li>
                  <a
                    href="/profile"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100  "
                    role="menuitem"
                  >
                    Profile
                  </a>
                </li>
                <li>
                  <a
                    href="/subscription"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100  "
                    role="menuitem"
                  >
                    Subscription Plans
                  </a>
                </li>
                {checkAffiliate ? (
                  <li>
                    <a
                      href="https://mywellnessreader.postaffiliatepro.com/"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100  "
                      role="menuitem"
                    >
                      Affiliate Signup
                    </a>
                  </li>
                ) : null}
                <li>
                  <a
                    // href="/"
                    onClick={handleLogOut}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100  "
                    role="menuitem"
                  >
                    Sign out
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
        {/* {mobileView ? (
          <nav className="bg-navColor border-gray-300 shadow-lg text-white hidden">
            <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto px-4 py-3" 
            >
              <button
                data-collapse-toggle="navbar-multi-level"
                className=" hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200   "
                type="button"
                aria-controls="navbar-multi-level"
                aria-expanded="false"
              >
                <svg
                  className="w-6 h-6 md:hidden"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Open main menu</span>
              </button>
              <div className="grid grid-cols-2 items-center md:visible md:w-auto md:order-2" id="navbar-multi-level">
                <ul className="flex justify-center mr-14 text-lg p-4 md:p-0 mt-4 border-0 md:flex-row md:space-x-10 md:mt-0 md:border-0 border-transparent bg-transparent text-white justify-self-center">
                <li>
                <a
                  href={"/scan"}
                  className=" font-semibold uppercase block py-2 pl-3 pr-4 md:bg-transparent md:p-0  text-white border-b border-gray-100 transition duration-200 transform hover:scale-110 md:border-0   md:w-auto   "
                  aria-current="page"
                >
                  SCAN
                </a>
                </li>
                <li>
                  <Link
                    onClick={() => ctnx.toggleIsOpen()}
                    to={"#"}
                    className="block py-2 pl-3 pr-4 md:bg-transparent md:p-0  text-white border-b border-gray-100   transition duration-200 transform hover:scale-110 md:border-0   md:w-auto   "
                    aria-current="page"
                  >
                    How to use
                  </Link>
                </li>
                <li>
                  <a
                    href="mailto:fnb-support@futurenowbiotech.com"
                    className="block py-2 pl-3 pr-4 md:bg-transparent md:p-0  text-white border-b border-gray-100   transition duration-200 transform hover:scale-110 md:border-0   md:w-auto   "
                  >
                    Support
                  </a>
                </li>
                <li>
                  <Link
                    to={"/faq"}
                    className="block py-2 pl-3 pr-4 md:bg-transparent md:p-0  text-white border-b border-gray-100   transition duration-200 transform hover:scale-110 md:border-0   md:w-auto   "
                    aria-current="page"
                  >
                    FAQ
                  </Link>
                </li>
                </ul>
                <div className="justify-self-end -mr-14">
                  <Link to={"/dashboard"} aria-current="page"
                    className="block uppercase py-2 pl-3 pr-4 md:bg-transparent md:p-0  text-white border-b border-gray-100   transition duration-200 transform hover:scale-110 md:border-0 font-bold md:w-auto   "
                  >
                    MY TOTAL REPORT
                  </Link>
                </div>
              </div>
            </div>
          </nav>
        ): */}
        {/* ( */}
          <nav className="bg-navColor border-gray-300 shadow-lg text-white">
            <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto my-auto p-3" 
            >
              <button
                data-collapse-toggle="navbar-multi-level"
                className=" hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200  "
                type="button"
                aria-controls="navbar-multi-level"
                aria-expanded="false"
              >
                <svg
                  className="w-6 h-6 md:hidden cursor-pointer "
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Open main menu</span>
              </button>
              <div className="grid grid-cols-2 items-center md:visible md:w-auto md:order-2" id="navbar-multi-level">
                <ul className="flex justify-center mr-14 text-lg p-4 md:p-0 mt-4 border-0 md:flex-row md:space-x-10 md:mt-0 md:border-0 border-transparent bg-transparent text-white justify-self-center">
                <li>
                <a
                  href={"/scan"}
                  className=" font-semibold uppercase block py-2 pl-3 pr-4 md:bg-transparent md:p-0  text-white border-b border-gray-100 transition duration-200 transform hover:scale-110 md:border-0   md:w-auto   "
                  aria-current="page"
                >
                  SCAN
                </a>
                </li>
                <li>
                  <Link
                    onClick={() => ctnx.toggleIsOpen()}
                    to={"#"}
                    className="block py-2 pl-3 pr-4 md:bg-transparent md:p-0  text-white border-b border-gray-100   transition duration-200 transform hover:scale-110 md:border-0   md:w-auto   "
                    aria-current="page"
                  >
                    How to use
                  </Link>
                </li>
                <li>
                  <a
                    href="mailto:fnb-support@futurenowbiotech.com"
                    className="block py-2 pl-3 pr-4 md:bg-transparent md:p-0  text-white border-b border-gray-100   transition duration-200 transform hover:scale-110 md:border-0   md:w-auto   "
                  >
                    Support
                  </a>
                </li>
                <li>
                  <Link
                    to={"/faq"}
                    className="block py-2 pl-3 pr-4 md:bg-transparent md:p-0  text-white border-b border-gray-100   transition duration-200 transform hover:scale-110 md:border-0   md:w-auto   "
                    aria-current="page"
                  >
                    FAQ
                  </Link>
                </li>
                </ul>
                <div className="justify-self-end -mr-14">
                  <a href={"/dashboard"} aria-current="page"
                    className="block uppercase py-2 pl-3 pr-4 md:bg-transparent md:p-0  text-white border-b border-gray-100   transition duration-200 transform hover:scale-110 md:border-0 font-bold md:w-auto   "
                  >
                    MY TOTAL REPORT
                  </a>
                </div>
              </div>
            </div>
          </nav>
        {/* )} */}
    </>
  );
}

export default Nav;
