import React from "react";
import Icon2 from "../../../assets/Healthiness/01.png";
import Icon3 from '../../../assets/Healthiness/fitness.png'
import Icon4 from '../../../assets/Healthiness/supplement.png'
import "react-circular-progressbar/dist/styles.css";
import ScorePage from "../../common/ScorePage";
import BoneIcon from '../../../assets/Healthiness/mymajorhealth/ICON_BONE_HEALTH.png'

const FitBone = () => {
  return (
    <>
      <ScorePage
        toolTipText="Bone health is essential as your bones support your body, help you to move around, and protect sensitive organs like your heart and lungs. Bone health is intrinsically linked to calcium (and phosphorus) metabolism. There typically are no symptoms in the early stages of bone loss. But once your bones have been weakened by osteoporosis, you might have signs and symptoms: back pain caused by a fractured or collapsed vertebra, loss of height over time, a stooped posture, and a bone that breaks much more quickly than expected. "
        feature={14}
        reconID="BONE_HEALTH"
        icon={BoneIcon}
        vital="BONE HEALTH"
        textColor="#92c8e7"
        bgColor="#c2d9e8"
        Icon2={Icon2}
        Icon3={Icon3}
        Icon4={Icon4}
        prevpagelink="/mymajorhealth"
        prevpagename="Back to My Major Health"
      />
    </>
  );
};

export default FitBone;
