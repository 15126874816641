import React from "react";
import Icon1 from "../../../assets/Skin health/Skinhealthmenurolldown1.png";
import Icon2 from "../../../assets/Skin health/Skinhealthmenurolldown2.png";
import "react-circular-progressbar/dist/styles.css";
import ScorePage from "../../common/ScorePage";
import vitalIco from '../../../assets/ICON_PROTECTION.png'

const Sunscreen = () => {
  return (
    <>
      <ScorePage
        toolTipText="To maintain long-term skin health, it is essential to use skin protection or sunscreen. This product helps prevent signs of aging, such as fine lines, wrinkles, and sunspots, from appearing on the skin. Proper application of sunscreen is crucial to its effectiveness."
        feature={47}
        reconID="SUNSCREEN"
        icon={vitalIco}
        vital="PROTECTION"
        textColor="#bb8cea"
        bgColor="#d4c5df"
        Icon2={Icon1}
        Icon3={Icon2}
        Icon4={Icon2}
        prevpagelink="/myskinhealth"
        prevpagename="Back to My Skin Health"
      />
    </>
  );
};

export default Sunscreen;
