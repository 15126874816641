import React from "react";
import Icon2 from "../../../assets/Healthiness/01.png";
import Icon3 from '../../../assets/Healthiness/fitness.png'
import Icon4 from '../../../assets/Healthiness/supplement.png'
import "react-circular-progressbar/dist/styles.css";
import ScorePage from "../../common/ScorePage";
import MetaSug from '../../../assets/Healthiness/mymajorhealth/ICON_SUGAR_METABOLISM.png'

const GlucoseMeta = () => {
    return (
    <>
      <ScorePage
        toolTipText="Glucose Conversion refers to the process by which the body breaks down sugar (glucose) to produce energy. Most of the disorders associated with metabolic syndrome don't have obvious signs or symptoms. One visible sign is a large waist circumference. Poor glucose conversion leads to diabetes mellitus."
        feature={17}
        reconID="SUGAR_METABOLISM"
        icon={MetaSug}
        vital="GLUCOSE CONVERSION"
        textColor="#4f9aca"
        bgColor="#c2d9e8"
        Icon2={Icon2}
        Icon3={Icon3}
        Icon4={Icon4}
        prevpagelink="/mymajorhealth"
        prevpagename="Back to My Major Health"
      />
    </>  
    );
}
 
export default GlucoseMeta;