import { useEffect } from "react";
import {
	PayPalScriptProvider,
	PayPalButtons,
	usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import { updateSubscriptionInfo } from "../../firebase/auth";
import { PAYPAL_LIVE_CREDS as PAYPAL_CONFIG } from "../../firebase/main";

const ButtonWrapper = ({ type }) => {
	const [{ options }, dispatch] = usePayPalScriptReducer();

	// useEffect(() => {
    //     dispatch({
    //         type: "resetOptions",
    //         value: {
    //             ...options,
    //             intent: "subscription",
    //         },
    //     });
    // }, [type]);

	const updateSubInfo  = async(subscriptionID)=>{
		let status = await updateSubscriptionInfo(subscriptionID);
		if(status){
			sessionStorage.setItem('cost',32);
			sessionStorage.setItem('orderID',subscriptionID);
			sessionStorage.setItem('productID',PAYPAL_CONFIG.userDiscountPlanID)
			window.location.href="/thankyou.html";
		}
	}

	return (<PayPalButtons
		createSubscription={async(data, actions) => {
			return actions.subscription
				.create({
					plan_id:PAYPAL_CONFIG.userDiscountPlanID,
				})
				.then((orderId) => {
					// Your code here after create the order
					return orderId;
				});
		}}
		onApprove={(data,actions)=>{
			updateSubInfo(data.subscriptionID);
			
		}}
		style={{
			label: "",
		}}
	/>);
}

export default function UserDiscountPlanPaypalButton() {
	return (
		<PayPalScriptProvider
			options={{
				"client-id":PAYPAL_CONFIG.clientID,
				components: "buttons",
				intent: "subscription",
				vault: true,
			}}
		>
			<ButtonWrapper type="subscription" />
		</PayPalScriptProvider>
	);
}