// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import {
  getAuth
} from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAez5ItK45rKIBq4n2hqAyfgiFKxO3_cQM",
  authDomain: "futurenowbiotech.firebaseapp.com",
  projectId: "futurenowbiotech",
  storageBucket: "futurenowbiotech.appspot.com",
  messagingSenderId: "123400250546",
  appId: "1:123400250546:web:a962dbd98e3af9e77415ab",
  measurementId: "G-JNENE2T3Q8"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const appURL = 'https://mywellnessreader.com/'
export const homeURL = 'https://home.mywellnessreader.com'//'https://home.mywellnessreader.com'
export const AffiliateURL = 'https://mywellnessreader.postaffiliatepro.com/'

export const POSTAFF_PRO_CRED = {
  username:"gary.kiss@futurenowbiotech.com",
  password:"S9vaw3dL",
  apiVersion:"c278cce45ba296bc421269bfb3ddff74",
  apiURL: "https://mywellnessreader.postaffiliatepro.com/scripts/server.php"
}

export const PAYPAL_SANDBOX_CREDS = {
  clientID:"AYqncjSdkmxR0zvjaqtxBsWZfecNHPoMiZLJpqnYVoWjBLf64YcfJwabc8ksr_kSGK0wbRvjB9GLTORJ",
  clientSecret:"EG_LlfTrmfHKrGKp2IwUgq3Lg1JoQQHOQ5gz0ckm8vurNXmHEhSDfdiAHnqRjodB3_0i3MnInCQsW7Fz",
  apiURL:"https://api-m.sandbox.paypal.com",
  userPlanID:"P-6DD542995U3572630MRE7ZTI",
  userNormalAnnualPlanID:"P-97319032WY036184VMRFMAOA",
  userDiscountPlanID:"P-8T913736LJ907561LMRFL6PA",
  userAnnualPlanID:"P-97319032WY036184VMRFMAOA",
}

export const PAYPAL_LIVE_CREDS = {// App Name : My wellness Reader
  clientID:"AYMKMtQ9SZY4G4JQi3CErB31vzHS1bcBG-9bQhwgi7Pbt_xHRGyc56Oa6KATjtC96DxQJqvOV0kSRfcv",
  clientSecret:"EPnHHDKckK9k0i6JdHEBJLDYZ9bpxEaCKfMTrq0VYlx0-RNOdYrT4_OODen8Jhc8poYB-hGqUB_pJary",
  apiURL:"https://api-m.paypal.com",
  userPlanID:"P-82D98156PT559372KMRGLHLA",
  userDiscountPlanID:"P-0X376777MV532020EMRGLIAI",
  userAnnualPlanID:"P-48J702927S143680DMRGLIVQ", //  P-6FD8035921290232YMR3C3HA
  userNormalAnnualPlanID:"P-9FC23705VY2550823MRPYMTI",//  P-3F232390ND576172TMR3C5EQ
}