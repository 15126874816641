import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
// import SignIn from "./components/auth/SignIn";
// import SignUP from "./components/auth/SignUp";
// import ResetPassword from "./components/auth/ResetPassword";
import Dashboard from "./components/main/dashboard/dashboard";
import Dashboard2 from "./components/main/dashboard/dashboard";
import SkinHealthDash from "./components/main/skin_health/skin_health_dash";
import Cleansers from "./components/main/skin_health/cleansers";
import Moisturizers from "./components/main/skin_health/moisturizers";
import Sunscreen from "./components/main/skin_health/sunscreen";
import Scan from "./components/main/scan";
import HeartRate from "./components/main/healthness/heartrate";
import Sidebar from "./components/main/resnav/Sidebar";

const root = ReactDOM.createRoot(document.getElementById("root"));
const router = createBrowserRouter([
  {
    path: "/scan",
    element: <Scan />,
  },
]);
root.render(
  <React.StrictMode>
    {/* <RouterProvider router={router} /> */}
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
