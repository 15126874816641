import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useEffect, useState } from "react";
import { fetchLatestScore } from "../firebase/scans";

const HealthnessCard = (props) => { 
  const [score, setScore] = useState("");
  const [percentValue,setPercentValue]=useState(0);
  const [scanTime,setScanTime]=useState("");
  const [strokeColor,setStrokeColor]=useState("");
  const setLatestData = async()=>{
    let resp = await fetchLatestScore(props.feature,props.reconID);
    if (resp.score != 0){
      setPercentValue(resp.scorePercentage)
      setScore(resp.scoreText);
      setScanTime(resp.time);
      setStrokeColor(resp.scoreColor)
    }else{
      setPercentValue(0);
      setScore("")
      setScanTime("")
      setStrokeColor("")
    }
  }
  useEffect(()=>{
    setLatestData();
  },[])

  return (
    <div className="mt-10 hover:bg-slate-100 shadow-lg  transition duration-200 transform hover:scale-110">
      {/* <div class="bg-white relative shadow rounded-lg w-64  lg:w-4/6 xl:w-3/6 mx-auto"> */}
      <div class="bg-white relative shadow rounded-lg w-64  mx-auto">
        <div class="flex justify-center p-2">
          <img
            src={props.icon}
            alt=""
            class="rounded-full mx-auto absolute -top-10 md:w-24 h-24 shadow-lg transition duration-200 transform hover:scale-110"
          />
        </div>
        <div class="flex mt-16  p-4 flex-col">
          <div className="flex mx-auto font-bold text-xl mb-2 text-center">{props.vital}</div>
          <center>
            {/* <Circle score="1" /> */}
            <div style={{ width: 100, height: 100 }} className="mx-auto mb-2">
              <CircularProgressbar value={percentValue} text={score} styles={{
                path: {
                  stroke: strokeColor,
                },
                trail: {
                  stroke: "#ffffff",
                },
                text: {
                  fill: strokeColor,
                  fontSize:"1rem",
                  fontWeight:"normal"
                }
              }}/>
            </div>
          </center>

          <div className="flex mx-auto text-3xl mb-2 font-semibold text-center" style={{color:`${props.textColor}`}}>{percentValue}%</div>
          <div className="flex mx-auto ">
            <time>{scanTime}</time>
          </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default HealthnessCard;
